import React from 'react';
import HeaderLogo from '@components/HeaderLogo';
import { BVHeaderLinks, StyledLink, StyledNavBar, StyledText } from '@components/NavBar/style';
import { generatePath, useNavigate } from 'react-router-dom';
import { PATHS } from '@routes';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { useSidePanel } from '@components/panels/SidePanel/SidePanelContext';

export const BVDesktopNavBar: React.FC = () => {
  const navigate = useNavigate();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  const { setContactSidePanelOpen } = useSidePanel();

  return (
    <StyledNavBar id="header-desktop" theme={brandConfiguration}>
      <HeaderLogo />

      <BVHeaderLinks>
        <StyledLink
          onClick={() =>
            navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId: caRecommendation?.recommendationId }))
          }
        >
          <StyledText theme={brandConfiguration}>
            <FormattedMessage id="bv.nav-bar.favorites" />
          </StyledText>
        </StyledLink>

        <StyledLink onClick={() => setContactSidePanelOpen(true)}>
          <StyledText theme={brandConfiguration}>
            <FormattedMessage id="bv.nav-bar.contact-advisor" />
          </StyledText>
        </StyledLink>
      </BVHeaderLinks>
    </StyledNavBar>
  );
};
