import styled, { css } from 'styled-components';
import { colorUsage, fontSize, fontWeight, getSpacing, onSmallScreen } from '@stylesheet';
import { ReactComponent as DesktopWhatsAppIcon } from '@assets/whatApp/whatsapp.svg';
import { ReactComponent as DesktopNumberIcon } from '@assets/number/phone.svg';
import { ReactComponent as DesktopMailIcon } from '@assets/mail/mail.svg';
import { ReactComponent as DesktopSmsIcon } from '@assets/sms/sms.svg';
import { ReactComponent as DesktopLineIcon } from '@assets/line/line.svg';
import { ReactComponent as DesktopKakaoTalkIcon } from '@assets/kakaoTalk/kakaotalk.svg';
import theme from 'styled-theming';

export const ModalContainer = styled.div`
  position: ${(props) => props.theme.design.businessCard.desktop.position};
  top: ${(props) => props.theme.design.businessCard.desktop.top};
  left: ${(props) => props.theme.design.businessCard.desktop.left};
  bottom: ${(props) => props.theme.design.businessCard.desktop.bottom};
  right: ${(props) => props.theme.design.businessCard.desktop.right};
  min-width: ${(props) => props.theme.design.businessCard.desktop.minWidth};
  width: ${(props) => props.theme.design.businessCard.desktop.width};
  height: ${(props) => props.theme.design.businessCard.desktop.height};
  background: ${(props) => props.theme.design.businessCard.desktop.background};
  border-radius: ${(props) => props.theme.design.businessCard.desktop.borderRadius};
  z-index: ${(props) => props.theme.design.businessCard.desktop.zIndex};
  opacity: ${(props) => props.theme.design.businessCard.desktop.opacity};
  padding: ${(props) => props.theme.design.businessCard.desktop.padding};
  box-shadow: ${(props) => props.theme.design.businessCard.desktop.boxShadow};
  ${onSmallScreen(css`
    position: ${(props) => props.theme.design.businessCard.mobile.position};
    top: 0;
    bottom: ${(props) => props.theme.design.businessCard.mobile.bottom};
    right: ${(props) => props.theme.design.businessCard.mobile.right};
    width: ${(props) => props.theme.design.businessCard.mobile.width};
    min-width: ${(props) => props.theme.design.businessCard.mobile.minWidth};
    height: ${(props) => props.theme.design.businessCard.mobile.height};
    background: ${(props) => props.theme.design.businessCard.mobile.background};
    border-radius: ${(props) => props.theme.design.businessCard.mobile.borderRadius};
    z-index: ${(props) => props.theme.design.businessCard.mobile.zIndex};
    opacity: ${(props) => props.theme.design.businessCard.mobile.opacity};
    padding: ${(props) => props.theme.design.businessCard.mobile.padding};
    box-shadow: ${(props) => props.theme.design.businessCard.mobile.boxShadow};
  `)}
`;

export const ModalContent = styled.div`
  width: 100%;
  margin-top: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
`;

export const AdvisorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdvisorInformations = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const AdvisorName = styled.h1`
  font-size: 18px;
`;

export const AdvisorImageContainer = styled.div`
  margin: auto;
  justify-content: center;
  display: flex;
  width: 48px;
  height: 48px;
  padding-bottom: ${getSpacing(1.5)};
`;

export const AdvisorImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  object-fit: cover;
`;

export const AdvisorFunction = styled.h2`
  padding-top: ${getSpacing(0.5)};
  font-size: 14px;
  justify-content: center;
  color: ${colorUsage.textGrey};
`;

export const AdvisorContactsContainer = styled.div`
  margin-top: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
`;

export const AdvisorContact = styled.div`
  display: flex;
  margin-top: ${getSpacing(1)};
  margin-bottom: ${getSpacing(1)};
  ${theme('language', {
    ar: css`
      justify-content: end;
      flex-direction: row-reverse;
    `,
  })}
`;

export const ContactIcon = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
`;

export const AdvisorContactText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  margin-left: ${getSpacing(1)};
  color: ${colorUsage.textGrey};
`;

export const AdvisorContactLink = styled.a`
  &:hover {
    cursor: pointer;
  }

  color: ${colorUsage.textGrey};
`;

export const StyledDesktopNumberIcon = styled(DesktopNumberIcon)`
  margin: auto;
  stroke: ${colorUsage.textGrey};
`;

export const SlpModalContent = styled.div`
  width: 100%;
  background-color: white;
  opacity: 1;
  position: fixed;
  bottom: 0;
  right: 0;
  padding-bottom: 60px;
  font-family: ${(props) => props.theme.design.fonts.main};
`;

export const SlpModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 60px;

  svg {
    margin-right: 12px;
    margin-top: 12px;
  }
`;

export const SlpModalTitle = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
`;

export const SlpModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

export const SlpModalItems = styled.a`
  width: 90%;
  height: 56px;
  border-bottom: solid 1px #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.XSmall};
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  ${theme('language', {
    ar: css`
      flex-direction: row-reverse;
    `,
  })}
`;

export const SlpModalItemsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${getSpacing(3)};
  ${theme('language', {
    ar: css`
      flex-direction: row-reverse;
    `,
  })}
`;

export const StyledDesktopWhatsAppIcon = styled(DesktopWhatsAppIcon)`
  margin: auto;
  fill: ${colorUsage.textGrey};
`;

export const StyledDesktopMailIcon = styled(DesktopMailIcon)`
  margin: auto;
  stroke: ${colorUsage.textGrey};
`;

export const StyledDesktopSmsIcon = styled(DesktopSmsIcon)`
  margin: auto;
  fill: ${colorUsage.textGrey};
`;

export const StyledDesktopLineIcon = styled(DesktopLineIcon)`
  margin: auto;
`;

export const StyledDesktopKakaoTalkIcon = styled(DesktopKakaoTalkIcon)`
  margin: auto;
`;
