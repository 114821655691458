import styled, { css } from 'styled-components';
import { colorUsage, fontSize, fontWeight, onLargeScreen, onSmallScreen } from '@stylesheet';
import theme from 'styled-theming';

export const FooterContainer = styled.div`
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.design.colors.footerBackground};
  display: flex;
`;

export const FooterBrandLogo = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  ${onSmallScreen(css`
    margin: auto;
    height: auto;
    width: 90%;
  `)}
  ${onLargeScreen(css`
    padding-left: 50px;
    width: 350px;
  `)}
  fill: ${(props) => props.theme.design.colors.brandLogoFullBackgroundPage};
`;

export const FooterLinksContainer = styled.div`
  padding-top: 150px;
  display: flex;
  justify-content: space-around;
  ${onSmallScreen(css`
    flex-direction: column;
    gap: 8px;
    text-align: center;
  `)}
`;
export const FooterSocialsSection = styled.div`
  padding-top: 88px;
  padding-bottom: 88px;
  display: flex;
`;

export const FooterSocialsContainer = styled.div`
  margin: auto;
  width: 200px;
  display: flex;
  justify-content: space-around;
`;

export const FooterSocialsItem = styled.a`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const FooterLinksSection = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: ${(props) => props.theme.design.colors.text};
  font-family: ${(props) => props.theme.design.fonts.main};
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  text-transform: uppercase;
  cursor: pointer;

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  ${onSmallScreen(css`
    padding-top: 8px;
    padding-bottom: 8px;
  `)}
`;

export const FooterDownloadAppSection = styled(FooterLinksSection)`
  display: none;
  ${onSmallScreen(css`
    display: flex;
  `)}
`;

export const FooterDisclaimerSection = styled.div`
  color: ${(props) => props.theme.design.colors.lightGrey};
  font-family: ${(props) => props.theme.design.fonts.main};
  font-size: ${fontSize.XXXSmall};
  line-height: ${fontSize.XXXSmall};
  text-align: center;
  text-transform: uppercase;
`;

export const SaintLaurentFooterContainer = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  width: 800px;
  padding-bottom: 24px;
  ${onSmallScreen(css`
    width: 80%;
  `)}
`;

export const BVFooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 24px;
  border-top: 1px solid ${colorUsage.black};
  gap: 24px;
  color: ${(props) => props.theme.design.colors.text};
  font-family: ${(props) => props.theme.design.fonts.main};

  ${onLargeScreen(css`
    flex-direction: row;
  `)}
`;

export const BVFooterLinkSection = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  text-transform: uppercase;
  width: 100%;
  justify-content: center;
  text-align: center;

  a {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })};
`;
