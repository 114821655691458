import { BRAND, BrandConfiguration, PRODUCT_LIST_SIZE } from '@redux/brandConfiguration/types';
import { colorUsage, fontSize, fontWeight, getSpacing } from '@stylesheet';
import GGMobileMainBackground from '@assets/mobileBackground.jpg';
import GGDesktopMainBackground from '@assets/reco_desk.jpg';
import BVDesktopMainBackground from '@assets/pr2.jpg';
import { ReactComponent as LogoGucci } from '@assets/gucci_logo.svg';
import { ReactComponent as SocialInstagramSlp } from '@assets/socials/instagram_slp.svg';
import { ReactComponent as SocialFacebookSlp } from '@assets/socials/facebook_slp.svg';
import { ReactComponent as SocialXSlp } from '@assets/socials/x_slp.svg';
import { ReactComponent as SocialTiktokSlp } from '@assets/socials/tiktok_slp.svg';
import { ReactComponent as SocialYoutubeSlp } from '@assets/socials/youtube_slp.svg';
import { ReactComponent as EmptyHeartSlp } from '@assets/icons/heart/slp_empty_heart.svg';
import { ReactComponent as FullHeartSlp } from '@assets/icons/heart/slp_full_heart.svg';
import { ReactComponent as EmptyHeartGucci } from '@assets/icons/heart/gucci_empty_heart.svg';
import { ReactComponent as FullHeartGucci } from '@assets/icons/heart/gucci_full_heart.svg';
import { ReactComponent as BusinessCardKakaoSlp } from '@assets/icons/socials/slp_kakao.svg';
import { ReactComponent as BusinessCardLineSlp } from '@assets/icons/socials/slp_line.svg';
import { ReactComponent as BusinessCardMailSlp } from '@assets/icons/socials/slp_mail.svg';
import { ReactComponent as BusinessCardPhoneSlp } from '@assets/icons/socials/slp_phone.svg';
import { ReactComponent as BusinessCardSmsSlp } from '@assets/icons/socials/slp_sms.svg';
import { ReactComponent as BusinessCardWechatSlp } from '@assets/icons/socials/slp_wechat.svg';
import { ReactComponent as BusinessCardWeiboSlp } from '@assets/icons/socials/slp_weibo.svg';
import { ReactComponent as BusinessCardWhatsappSlp } from '@assets/icons/socials/slp_whatsapp.svg';
import { ReactComponent as BusinessCardFacebookSlp } from '@assets/icons/socials/spl_facebook.svg';
import { ReactComponent as ArrowSlp } from '@assets/icons/arrow/slp_arrow.svg';
import { ReactComponent as ContactAdvisorSlp } from '@assets/icons/contactAdvisor/slp_contact_advisor.svg';
import { ReactComponent as MinusSlp } from '@assets/icons/slp_minus.svg';
import { ReactComponent as PlusSlp } from '@assets/icons/slp_plus.svg';

import { ReactComponent as LogoSaintLaurent } from '@assets/saintlaurent.svg';
import { ReactComponent as LogoBottegaVeneta } from '@assets/logos/BV.svg';

import { ReactComponent as EmptyBookmarkBv } from '@assets/icons/bookmark/empty-bv.svg';
import { ReactComponent as FilledBookmarkBv } from '@assets/icons/bookmark/filled-bv.svg';
import { ReactComponent as BusinessCardMailBv } from '@assets/mail/mail-bv.svg';
import { ReactComponent as BusinessCardPhoneBv } from '@assets/number/phone-bv.svg';
import { ReactComponent as BusinessCardWhatsappBv } from '@assets/whatApp/whatsapp-bv.svg';

export const defaultConfiguration: BrandConfiguration = {
  constants: {
    name: 'default',
    url: '',
  },
  features: {
    favorite: true,
    productDetail: true,
    showSocialsMediaInBusinessCard: true,
  },
  design: {
    fonts: {
      main: "'guccisans', 'Helvetica', 'Arial', sans-serif",
      introduction: "'guccisansprolight','Helvetica', 'Arial', sans-serif",
    },
    colors: {
      background: `${colorUsage.black}`,
      text: `${colorUsage.black}`,
      footerText: `${colorUsage.black}`,
      homePageProductText: `${colorUsage.black}`,
      productDetailsText: `${colorUsage.black}`,
      brandLogo: `${colorUsage.black}`,
      brandLogoFullBackgroundPage: `${colorUsage.white}`,
      textFullBackgroundPage: `${colorUsage.white}`,
      lightGrey: `${colorUsage.textGrey}`,
      footerBackground: `${colorUsage.black}`,
      productCarousselBackground: `${colorUsage.background}`,
    },
    logos: {
      brand: LogoGucci,
      facebook: SocialFacebookSlp,
      instagram: SocialInstagramSlp,
      tiktok: SocialTiktokSlp,
      x: SocialXSlp,
      youtube: SocialYoutubeSlp,
      emptyHeart: EmptyHeartGucci,
      fullHeart: FullHeartGucci,
    },
    background: {
      small: `${GGMobileMainBackground}`,
      large: `${GGDesktopMainBackground}`,
    },
    pages: {
      productsList: {
        introduction: {
          container: {
            minHeight: '600px',
          },
          message: {
            mobile: {
              minHeight: '300px',
              padding: '20px 16px 16px 20px',
            },
            backgroundColor: `${colorUsage.burgundy}`,
            padding: '100px 90px 100px 90px',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'start',
          },
          hello: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            fontStyle: 'italic',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
          selection: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
          advisor: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            marginTop: `${getSpacing(0)}`,
            fontStyle: 'italic',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
        },
        list: {
          desktop: {
            favorite: {
              height: `${getSpacing(4)}`,
              width: `${getSpacing(4)}`,
            },
          },
          mobile: {
            favorite: {
              height: `${getSpacing(3)}`,
              width: `${getSpacing(3)}`,
            },
          },
        },
      },
      productDetails: {
        backgroundColor: `${colorUsage.white}`,
        mobile: {
          description: {
            padding: `${getSpacing(3)} ${getSpacing(4)}`,
          },
        },
      },
      errorPage: {
        logo: {
          marginTop: '50px',
          marginBottom: '0px',
        },
        textContainer: {
          maxWidth: '100%',
          desktop: {
            marginTop: `${getSpacing(18)}`,
            marginBottom: `${getSpacing(0)}`,
          },
          mobile: {
            marginTop: `${getSpacing(8)}`,
            marginBottom: `${getSpacing(0)}`,
          },
        },
        container: {
          desktop: {
            paddingTop: `${getSpacing(9)}`,
            paddingX: `${getSpacing(14)}`,
            paddingBottom: `${getSpacing(11)}`,
          },
          mobile: {
            paddingTop: `${getSpacing(10)}`,
            paddingX: `${getSpacing(4)}`,
            paddingBottom: `${getSpacing(4)}`,
          },
        },
        text: {
          part1: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.XLarge}`,
            marginTop: '16px',
            color: `${colorUsage.white}`,
            fontStyle: '',
            textTransform: '',
          },
          part2: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.XLarge}`,
            marginTop: '',
            color: `${colorUsage.white}`,
            fontStyle: '',
            textTransform: '',
          },
        },
      },
    },
    navbar: {
      height: '70px',
      borderBottom: `1px solid ${colorUsage.black}`,
      textTransform: 'uppercase',
      backgroundColor: `${colorUsage.white}`,
      padding: '0px',
      mobile: {
        height: '50px',
        padding: '0px',
      },
      logo: {
        minHeight: `${getSpacing(3)}`,
        width: `${getSpacing(20)}`,
        paddingLeft: '70px',
        mobile: {
          width: `${getSpacing(20)}`,
          paddingLeft: '70px',
          margin: '',
        },
      },
      link: {
        color: `${colorUsage.black}`,
        fontSize: `${fontSize.medium}`,
        marginRight: `${getSpacing(1)}`,
        fontWeight: `${fontWeight.bold}`,
        mobile: {
          fontSize: '7pt',
        },
      },
    },
    businessCard: {
      mobile: {
        width: '80%',
        position: 'fixed',
        top: '',
        left: '',
        bottom: `${getSpacing(12)}`,
        right: `${getSpacing(2)}`,
        minWidth: '300px',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
      desktop: {
        width: 'auto',
        position: 'fixed',
        bottom: `${getSpacing(13)}`,
        right: '2%',
        minWidth: '300px',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    panel: {
      favorites: {
        width: '',
      },
      businessCard: {
        width: '',
      },
      productMoreDetails: {
        width: '',
      },
    },
    columns: {
      small: '[first] 50% [middle] 50% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]',
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(60)} [second] ${getSpacing(60)} [third] ${getSpacing(60)} [fourth] ${getSpacing(60)} [end]`,
    },
  },
};

export const ggConfiguration: BrandConfiguration = {
  constants: {
    name: BRAND.GUCCI,
    url: 'https://www.gucci.com',
  },
  features: {
    favorite: true,
    productDetail: true,
    showSocialsMediaInBusinessCard: true,
  },
  design: {
    fonts: {
      main: "'guccisans', 'Helvetica', 'Arial', sans-serif",
      introduction: "'guccisansprolight','Helvetica', 'Arial', sans-serif",
    },
    colors: {
      background: `${colorUsage.burgundy}`,
      text: `${colorUsage.black}`,
      footerText: `${colorUsage.white}`,
      homePageProductText: `${colorUsage.black}`,
      productDetailsText: `${colorUsage.black}`,
      brandLogo: `${colorUsage.black}`,
      brandLogoFullBackgroundPage: `${colorUsage.white}`,
      textFullBackgroundPage: `${colorUsage.white}`,
      lightGrey: `${colorUsage.textGrey}`,
      footerBackground: `${colorUsage.black}`,
      productCarousselBackground: `${colorUsage.background}`,
    },
    logos: {
      brand: LogoGucci,
      facebook: SocialFacebookSlp,
      instagram: SocialInstagramSlp,
      tiktok: SocialTiktokSlp,
      x: SocialXSlp,
      youtube: SocialYoutubeSlp,
      emptyHeart: EmptyHeartGucci,
      fullHeart: FullHeartGucci,
    },
    background: {
      small: `${GGMobileMainBackground}`,
      large: `${GGDesktopMainBackground}`,
    },
    pages: {
      productsList: {
        introduction: {
          container: {
            minHeight: '600px',
          },
          message: {
            mobile: {
              minHeight: '300px',
              padding: '20px 16px 16px 20px',
            },
            backgroundColor: `${colorUsage.burgundy}`,
            padding: '100px 90px 100px 90px',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'start',
          },
          hello: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            fontStyle: 'italic',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
          selection: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
          advisor: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            marginTop: `${getSpacing(0)}`,
            fontStyle: 'italic',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
        },
        list: {
          desktop: {
            favorite: {
              height: `${getSpacing(4)}`,
              width: `${getSpacing(4)}`,
            },
          },
          mobile: {
            favorite: {
              height: `${getSpacing(3)}`,
              width: `${getSpacing(3)}`,
            },
          },
        },
      },
      productDetails: {
        backgroundColor: `${colorUsage.white}`,
        mobile: {
          description: {
            padding: `${getSpacing(3)} ${getSpacing(4)}`,
          },
        },
      },
      errorPage: {
        logo: {
          marginTop: '50px',
          marginBottom: '0px',
        },
        textContainer: {
          maxWidth: '100%',
          desktop: {
            marginTop: `${getSpacing(18)}`,
            marginBottom: `${getSpacing(0)}`,
          },
          mobile: {
            marginTop: `${getSpacing(8)}`,
            marginBottom: `${getSpacing(0)}`,
          },
        },
        container: {
          desktop: {
            paddingTop: `${getSpacing(9)}`,
            paddingX: `${getSpacing(14)}`,
            paddingBottom: `${getSpacing(11)}`,
          },
          mobile: {
            paddingTop: `${getSpacing(10)}`,
            paddingX: `${getSpacing(4)}`,
            paddingBottom: `${getSpacing(4)}`,
          },
        },
        text: {
          part1: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.XLarge}`,
            marginTop: '16px',
            color: `${colorUsage.white}`,
            fontStyle: '',
            textTransform: '',
          },
          part2: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.XLarge}`,
            marginTop: '',
            color: `${colorUsage.white}`,
            fontStyle: '',
            textTransform: '',
          },
        },
      },
    },
    navbar: {
      height: '70px',
      borderBottom: `1px solid ${colorUsage.black}`,
      textTransform: 'uppercase',
      backgroundColor: `${colorUsage.white}`,
      padding: '0px',
      mobile: {
        height: '50px',
        padding: '0px',
      },
      logo: {
        minHeight: `${getSpacing(3)}`,
        width: `${getSpacing(20)}`,
        paddingLeft: '70px',
        mobile: {
          width: `${getSpacing(17)}`,
          paddingLeft: '20px',
          margin: '',
        },
      },
      link: {
        color: `${colorUsage.black}`,
        fontSize: `${fontSize.medium}`,
        marginRight: `${getSpacing(1)}`,
        fontWeight: `${fontWeight.bold}`,
        mobile: {
          fontSize: '7pt',
        },
      },
    },
    businessCard: {
      mobile: {
        width: '80%',
        position: 'fixed',
        top: '',
        left: '',
        bottom: `${getSpacing(12)}`,
        right: `${getSpacing(2)}`,
        minWidth: '300px',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
      desktop: {
        width: 'auto',
        position: 'fixed',
        bottom: `${getSpacing(13)}`,
        right: '2%',
        minWidth: '300px',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    panel: {
      favorites: {
        width: '',
      },
      businessCard: {
        width: '',
      },
      productMoreDetails: {
        width: '',
      },
    },
    columns: {
      small: '[first] 50% [middle] 50% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]',
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(60)} [second] ${getSpacing(60)} [third] ${getSpacing(60)} [fourth] ${getSpacing(60)} [end]`,
    },
  },
};

export const slpConfiguration: BrandConfiguration = {
  constants: {
    name: BRAND.SLP,
    url: 'https://www.ysl.com/',
  },
  features: {
    favorite: true,
    productDetail: true,
    showSocialsMediaInBusinessCard: false,
  },
  design: {
    fonts: {
      main: "'Helvetica', 'Arial', sans-serif",
      introduction: "'HelveticaNeue','Helvetica', 'Arial', sans-serif",
    },
    colors: {
      background: `${colorUsage.white}`,
      text: `${colorUsage.black}`,
      footerText: `${colorUsage.black}`,
      homePageProductText: `${colorUsage.black}`,
      productDetailsText: `${colorUsage.black}`,
      brandLogo: `${colorUsage.black}`,
      brandLogoFullBackgroundPage: `${colorUsage.black}`,
      textFullBackgroundPage: `${colorUsage.black}`,
      lightGrey: `${colorUsage.slpLightGrey}`,
      footerBackground: 'transparent',
      productCarousselBackground: `${colorUsage.slpProductBackground}`,
    },
    logos: {
      brand: LogoSaintLaurent,
      facebook: SocialFacebookSlp,
      instagram: SocialInstagramSlp,
      tiktok: SocialTiktokSlp,
      x: SocialXSlp,
      youtube: SocialYoutubeSlp,
      emptyHeart: EmptyHeartSlp,
      fullHeart: FullHeartSlp,
      arrow: ArrowSlp,
      contactAdvisor: ContactAdvisorSlp,
      minus: MinusSlp,
      plus: PlusSlp,
    },
    background: {
      small: `${BVDesktopMainBackground}`,
      large: `${BVDesktopMainBackground}`,
    },
    pages: {
      productsList: {
        introduction: {
          container: {
            minHeight: '430px',
          },
          message: {
            mobile: {
              minHeight: '500px',
              padding: '20px 16px 16px 20px',
            },
            backgroundColor: `${colorUsage.white}`,
            padding: '100px 90px 100px 90px',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          },
          hello: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.bold}`,
            lineHeight: `${fontSize.large}`,
            color: `${colorUsage.black}`,
            fontStyle: '',
            textTransform: 'uppercase',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '',
            },
          },
          selection: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.bold}`,
            lineHeight: `${fontSize.large}`,
            color: `${colorUsage.black}`,
            textTransform: 'uppercase',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '',
            },
          },
          advisor: {
            fontSize: `${fontSize.XSmall}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.medium}`,
            marginTop: `${getSpacing(2)}`,
            color: `${colorUsage.black}`,
            fontStyle: '',
            textTransform: 'uppercase',
            mobile: {
              fontSize: `${fontSize.XSmall}`,
              lineHeight: '',
            },
          },
        },
        list: {
          desktop: {
            favorite: {
              height: `${getSpacing(4)}`,
              width: `${getSpacing(4)}`,
            },
            pattern: [
              PRODUCT_LIST_SIZE.BIG_CENTER,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.MEDIUM_LEFT,
              PRODUCT_LIST_SIZE.BIG_RIGHT,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.BIG_LEFT,
              PRODUCT_LIST_SIZE.MEDIUM_RIGHT,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
            ],
          },
          mobile: {
            favorite: {
              height: `${getSpacing(4)}`,
              width: `${getSpacing(4)}`,
            },
            pattern: [
              PRODUCT_LIST_SIZE.BIG_CENTER,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.BIG_LEFT,
              PRODUCT_LIST_SIZE.MEDIUM_RIGHT,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.BIG_RIGHT,
              PRODUCT_LIST_SIZE.MEDIUM_LEFT,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
              PRODUCT_LIST_SIZE.SMALL,
            ],
          },
        },
      },
      productDetails: {
        backgroundColor: `${colorUsage.slpProductBackground}`,
        mobile: {
          description: {
            padding: '20px',
          },
        },
      },
      errorPage: {
        logo: {
          marginTop: '24px',
          marginBottom: '24px',
        },
        textContainer: {
          maxWidth: '310px',
          desktop: {
            marginTop: 'auto',
            marginBottom: 'auto',
          },
          mobile: {
            marginTop: 'auto',
            marginBottom: 'auto',
          },
        },
        container: {
          desktop: {
            paddingTop: `${getSpacing(0)}`,
            paddingX: `${getSpacing(14)}`,
            paddingBottom: `${getSpacing(0)}`,
          },
          mobile: {
            paddingTop: `${getSpacing(0)}`,
            paddingX: `${getSpacing(4)}`,
            paddingBottom: `${getSpacing(0)}`,
          },
        },
        text: {
          part1: {
            fontSize: `${fontSize.XSmall}`,
            fontWeight: `${fontWeight.bold}`,
            lineHeight: `${fontSize.medium}`,
            marginTop: '-74px',
            color: `${colorUsage.black}`,
            fontStyle: '',
            textTransform: 'uppercase',
          },
          part2: {
            fontSize: `${fontSize.XSmall}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.medium}`,
            marginTop: '20px',
            color: `${colorUsage.black}`,
            fontStyle: '',
            textTransform: 'uppercase',
          },
        },
      },
    },
    navbar: {
      height: '80px',
      borderBottom: '',
      textTransform: 'uppercase',
      backgroundColor: `${colorUsage.white}`,
      padding: '0px',
      mobile: {
        height: '80px',
        padding: '0px',
      },
      logo: {
        minHeight: `${getSpacing(3)}`,
        width: '140px',
        paddingLeft: '0',
        mobile: {
          width: '140px',
          paddingLeft: '0',
          margin: 'auto',
        },
      },
      link: {
        color: `${colorUsage.black}`,
        fontSize: `${fontSize.XSmall}`,
        marginRight: '0',
        fontWeight: `${fontWeight.normal}`,
        mobile: {
          fontSize: '7pt',
        },
      },
    },
    businessCard: {
      icons: {
        facebook: BusinessCardFacebookSlp,
        kakao: BusinessCardKakaoSlp,
        line: BusinessCardLineSlp,
        mail: BusinessCardMailSlp,
        phone: BusinessCardPhoneSlp,
        sms: BusinessCardSmsSlp,
        wechat: BusinessCardWechatSlp,
        weibo: BusinessCardWeiboSlp,
        whatsapp: BusinessCardWhatsappSlp,
      },
      mobile: {
        width: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        bottom: 'auto',
        right: 'auto',
        minWidth: '300px',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '0',
        zIndex: '1000',
        opacity: '1',
        padding: '0',
        boxShadow: '',
      },
      desktop: {
        width: 'auto',
        position: 'fixed',
        bottom: `${getSpacing(10)}`,
        right: '4.5%',
        minWidth: '0',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    panel: {
      favorites: {
        width: '680px',
      },
      businessCard: {
        width: '680px',
      },
      productMoreDetails: {
        width: '680px',
      },
    },
    columns: {
      small: '[first] 33% [second] 33% [third] 33% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]',
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
    },
  },
};

export const bvConfiguration: BrandConfiguration = {
  constants: {
    name: BRAND.BV,
    url: 'https://www.bottegaveneta.com/',
  },
  features: {
    favorite: true,
    productDetail: true,
    showSocialsMediaInBusinessCard: true,
  },
  design: {
    fonts: {
      main: "'Bottega Veneta', 'Arial', sans-serif",
      introduction: "'Bottega Veneta', 'Arial', sans-serif",
    },
    colors: {
      background: `${colorUsage.black}`,
      text: `${colorUsage.black}`,
      footerText: `${colorUsage.black}`,
      homePageProductText: `${colorUsage.black}`,
      productDetailsText: `${colorUsage.black}`,
      brandLogo: `${colorUsage.black}`,
      brandLogoFullBackgroundPage: `${colorUsage.white}`,
      textFullBackgroundPage: `${colorUsage.white}`,
      lightGrey: `${colorUsage.textGrey}`,
      footerBackground: `${colorUsage.white}`,
      productCarousselBackground: `${colorUsage.background}`,
    },
    logos: {
      brand: LogoBottegaVeneta,
      emptyHeart: EmptyBookmarkBv,
      fullHeart: FilledBookmarkBv,
    },
    background: {
      small: `${GGMobileMainBackground}`,
      large: `${GGDesktopMainBackground}`,
    },
    pages: {
      productsList: {
        introduction: {
          container: {
            minHeight: '600px',
          },
          message: {
            mobile: {
              minHeight: '300px',
              padding: '20px 16px 16px 20px',
            },
            backgroundColor: `${colorUsage.burgundy}`,
            padding: '100px 90px 100px 90px',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'start',
          },
          hello: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            fontStyle: 'italic',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
          selection: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
          advisor: {
            fontSize: '27pt',
            fontWeight: `${fontWeight.medium}`,
            lineHeight: '1.7',
            marginTop: `${getSpacing(0)}`,
            fontStyle: 'italic',
            color: `${colorUsage.white}`,
            textTransform: '',
            mobile: {
              fontSize: `${fontSize.large}`,
              lineHeight: '1.5',
            },
          },
        },
        list: {
          desktop: {
            favorite: {
              height: `17px`,
              width: `12px`,
            },
          },
          mobile: {
            favorite: {
              height: `17px`,
              width: `12px`,
            },
          },
        },
      },
      productDetails: {
        backgroundColor: `${colorUsage.white}`,
        mobile: {
          description: {
            padding: `${getSpacing(3)} ${getSpacing(4)}`,
          },
        },
      },
      errorPage: {
        logo: {
          marginTop: '50px',
          marginBottom: '0px',
        },
        textContainer: {
          maxWidth: '100%',
          desktop: {
            marginTop: `${getSpacing(18)}`,
            marginBottom: `${getSpacing(0)}`,
          },
          mobile: {
            marginTop: `${getSpacing(8)}`,
            marginBottom: `${getSpacing(0)}`,
          },
        },
        container: {
          desktop: {
            paddingTop: `${getSpacing(9)}`,
            paddingX: `${getSpacing(14)}`,
            paddingBottom: `${getSpacing(11)}`,
          },
          mobile: {
            paddingTop: `${getSpacing(10)}`,
            paddingX: `${getSpacing(4)}`,
            paddingBottom: `${getSpacing(4)}`,
          },
        },
        text: {
          part1: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.XLarge}`,
            marginTop: '16px',
            color: `${colorUsage.white}`,
            fontStyle: '',
            textTransform: '',
          },
          part2: {
            fontSize: `${fontSize.large}`,
            fontWeight: `${fontWeight.normal}`,
            lineHeight: `${fontSize.XLarge}`,
            marginTop: '',
            color: `${colorUsage.white}`,
            fontStyle: '',
            textTransform: '',
          },
        },
      },
    },
    navbar: {
      height: '64px',
      borderBottom: '',
      textTransform: '',
      backgroundColor: `${colorUsage.white}`,
      padding: '20px 24px',
      mobile: {
        height: '60px',
        padding: '21px 16px',
      },
      logo: {
        minHeight: `19px`,
        width: `209px`,
        paddingLeft: '0px',
        mobile: {
          width: `209px`,
          paddingLeft: '0px',
          margin: '',
        },
      },
      link: {
        color: `${colorUsage.black}`,
        fontSize: `${fontSize.medium}`,
        marginRight: `${getSpacing(0)}`,
        fontWeight: `${fontWeight.normal}`,
        mobile: {
          fontSize: `${fontSize.medium}`,
        },
      },
    },
    businessCard: {
      icons: {
        mail: BusinessCardMailBv,
        phone: BusinessCardPhoneBv,
        whatsapp: BusinessCardWhatsappBv,
      },
      mobile: {
        width: '80%',
        position: 'fixed',
        top: '',
        left: '',
        bottom: `${getSpacing(12)}`,
        right: `${getSpacing(2)}`,
        minWidth: '300px',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
      desktop: {
        width: 'auto',
        position: 'fixed',
        bottom: `${getSpacing(13)}`,
        right: '2%',
        minWidth: '300px',
        height: 'auto',
        background: 'white',
        borderRadius: '40px',
        zIndex: '1000',
        opacity: '1',
        padding: `${getSpacing(3)}`,
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    panel: {
      favorites: {
        width: '',
      },
      businessCard: {
        width: '475px',
      },
      productMoreDetails: {
        width: '',
      },
    },
    columns: {
      small: '[first] 50% [middle] 50% [end]',
      large: '[first] 33% [second] 33% [third] 33% [end]',
    },
    rows: {
      small: `[first] ${getSpacing(33)} [second] ${getSpacing(33)} [third] ${getSpacing(33)} [fourth] ${getSpacing(33)} [end]`,
      large: `[first] ${getSpacing(60)} [second] ${getSpacing(60)} [third] ${getSpacing(60)} [fourth] ${getSpacing(60)} [end]`,
    },
  },
};
