import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { onLargeScreen } from '@stylesheet';

const CommonProductListContainer = styled.div`
  height: auto;
  width: 100%;
`;

const BVProductListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 40px 16px 64px 16px;

  ${onLargeScreen(css`
    padding: 32px 24px 80px 24px;
  `)}
`;

interface Props {
  children: ReactNode;
}

export const ProductListContainer: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const ProductListContainer = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return BVProductListContainer;
      default:
        return CommonProductListContainer;
    }
  })();

  return <ProductListContainer>{children}</ProductListContainer>;
};
