import styled, { css } from 'styled-components';
import { colorUsage, fontSize, fontWeight, onLargeScreen, onSmallScreen } from '@stylesheet';
import { ReactComponent as BVLeftChevronIcon } from '@assets/icons/arrow/left/arrow-bv.svg';
import theme from 'styled-theming';

export const Container = styled.div`
  position: relative;

  ${onSmallScreen(css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 64px;
  `)}

  ${onLargeScreen(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 24px 80px 24px;
  `)}
`;

export const BackButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;

  ${onLargeScreen(css`
    top: 24px;
    left: 48px;
  `)}
`;

export const BackText = styled.p`
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal};
  line-height: ${fontSize.large};
`;

export const BackIcon = styled(BVLeftChevronIcon)`
  width: 8px;
  height: 11px;
`;

export const DesktopLeftContainer = styled.div`
  display: none;

  ${onLargeScreen(css`
    display: flex;
    flex-direction: column;
    gap: 24px;
  `)}
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Dots = styled.ul`
  list-style-type: none;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: fit-content;
`;

export const DotPointContainer = styled.li`
  height: 24px;
  width: 35px;
  align-items: center;
  cursor: pointer;
  align-content: center;

  &.active {
    cursor: default;
  }
`;

export const DotPoint = styled.div`
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 50%;
  opacity: 0.5;
  justify-self: center;

  .active & {
    width: 6px;
    height: 6px;
    opacity: 1;
  }
`;

export const MobileLeftContainer = styled.div`
  ${onLargeScreen(css`
    display: none;
  `)};
`;

export const ProductDetailImage = styled.img`
  height: 487px;
  width: 100%;
  object-fit: scale-down;
  background-color: #f1f3f2;

  ${onLargeScreen(css`
    height: auto;
  `)};
`;

export const RightContainer = styled.div`
  position: sticky;
  top: 144px;
  height: fit-content;
  overflow-y: auto;
  display: flex;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 16px;
  width: 100%;

  ${onLargeScreen(css`
    padding: 0;
    width: 60%;
  `)}
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${onLargeScreen(css`
    gap: 20px;
  `)}
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FirstLineHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
`;

export const ProductDetailName = styled.h2`
  font-size: ${fontSize.mediumLarge};
  line-height: 27px;
  font-weight: ${fontWeight.normal};
`;

export const ProductDetailIcon = styled.div`
  width: 12px;
  height: 17px;

  svg {
    --fill: ${colorUsage.black};
    --stroke: ${colorUsage.black};
  }
`;

export const ProductDetailPrice = styled.p`
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BuyLink = styled.a`
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 30px 5px 30px;
  border: 2px solid ${colorUsage.black};
  background-color: ${colorUsage.black};
  text-align: center;
  color: ${colorUsage.white};
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  cursor: pointer;
`;

export const AdvisorButton = styled.button`
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 30px 5px 30px;
  border: 2px solid ${colorUsage.black};
  text-align: center;
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  cursor: pointer;
`;

export const ContentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Description = styled.p`
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  white-space: pre-wrap;
`;
