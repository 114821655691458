import React from 'react';
import { PublicAdvisor } from '@redux/advisors/types';
import { ModalContainer } from '@components/ContactModal/style';
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import GucciContact from '@components/ContactModal/Content/GucciContact';
import { BRAND } from '@redux/brandConfiguration/types';
import SlpContact from '@components/ContactModal/Content/SlpContact';

type Props = {
  open: boolean;
  advisor: PublicAdvisor;
  action: () => void;
};

const ContactModal: React.FC<Props> = ({ open, advisor, action }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const contactModal = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return <GucciContact advisor={advisor} />;
      case BRAND.SLP:
        return <SlpContact advisor={advisor} action={action} />;
      default:
        return null;
    }
  })();

  return (
    <>
      {open && (
        <ThemeProvider theme={{ language }}>
          <ModalContainer theme={brandConfiguration}>{contactModal}</ModalContainer>
        </ThemeProvider>
      )}
    </>
  );
};

export default ContactModal;
