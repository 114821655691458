import styled, { css } from 'styled-components';
import { onLargeScreen } from '@stylesheet';

export const Container = styled.div`
  min-height: 100%;
  height: auto;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.design.fonts.introduction};
  ${onLargeScreen(css`
    overflow: hidden;
    overflow: -moz-scrollbars-vertical;
  `)}
`;
