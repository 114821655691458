import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchCaRecommendation } from '@redux/caRecommendation/hooks';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';

import Loader from '@components/Loader';
import ProductList from '@pages/Suggestion/components/ProductList';
import { Container } from '@pages/Suggestion/Suggestion.style';
import { PATHS } from '@routes';
import { DesktopNavBar, MobileNavBar } from '@components/NavBar';
import { Footer } from '@components/Footer';
import { Product, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import ContactBubble from '@components/ContactBubble';
import ContactModal from '@components/ContactModal';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import SlpFavoritesContent from '@components/SidePanel/Content/Favorites/SlpFavoritesContent';
import SidePanel from '@components/SidePanel';
import { useIntl } from 'react-intl';
import { SidePanels } from '@components/panels/SidePanel/SidePanels';

type ISuggestion = {
  caRecommendationId: string;
};

const Suggestion: React.FC = () => {
  const { caRecommendationId } = useParams<ISuggestion>();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [fetchCaRecommendationResponse, fetchCaRecommendation] = useFetchCaRecommendation();
  const caRecommendation = useSelector(getCaRecommendation);
  const caRecommendationAssociated =
    caRecommendation?.recommendationId === caRecommendationId ? caRecommendation : null;
  const isLookbook = caRecommendation?.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK;
  const [openContactModal, setOpenContactModal] = useState(false);
  const [openFavoritesModal, setOpenFavoritesModal] = useState(false);
  const [likedProducts, setLikedProducts] = useState(0);
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const brand = brandConfiguration.constants.name;
  const { width: favoritesPanelWidth } = brandConfiguration.design.panel.favorites;

  useEffect(() => {
    (async function fetch() {
      if (!caRecommendationAssociated && caRecommendationId) {
        await fetchCaRecommendation(caRecommendationId);
      }
    })();
  }, [caRecommendationId, dispatch, fetchCaRecommendation, caRecommendationAssociated]);

  useEffect(() => {
    if (brand === BRAND.SLP) {
      const products = caRecommendation?.products as Product[];
      setLikedProducts(products.filter((p) => p.feedback && p.feedback >= 4).length);
    }
  }, [caRecommendation]);

  if (!caRecommendationAssociated) {
    if (fetchCaRecommendationResponse.error) {
      return <Navigate to={PATHS.ERROR} />;
    }

    return <Loader />;
  }

  const handleOpenContactModal = () => {
    setOpenFavoritesModal(false);
    setOpenContactModal(true);
  };

  return (
    <Container theme={brandConfiguration}>
      <div>
        <SidePanels />

        <DesktopNavBar />

        <MobileNavBar />

        <ProductList caRecommendation={caRecommendationAssociated} />

        {isLookbook && brand === BRAND.GUCCI && (
          <ContactBubble
            openContact={() => setOpenContactModal(!openContactModal)}
            isContactOpen={openContactModal}
            brand={brand}
          />
        )}

        {brand === BRAND.SLP && (
          <ContactBubble
            openContact={() => setOpenContactModal(!openContactModal)}
            isContactOpen={openContactModal}
            openFavorites={() => setOpenFavoritesModal(!openFavoritesModal)}
            brand={brand}
          />
        )}
      </div>

      <Footer />

      {caRecommendation?.advisor && (
        <ContactModal
          open={openContactModal}
          advisor={caRecommendation.advisor}
          action={() => setOpenContactModal(!openContactModal)}
        />
      )}

      {brand === BRAND.SLP && (
        <SidePanel
          action={() => setOpenFavoritesModal(false)}
          isOpen={openFavoritesModal}
          width={favoritesPanelWidth}
          className="panel-favorites"
          title={intl.formatMessage({ id: 'slp.nav-bar.favorites' }, { nb: likedProducts })}
        >
          {caRecommendation?.id && (
            <SlpFavoritesContent
              caRecommendationId={caRecommendation?.id}
              setOpenContactModal={handleOpenContactModal}
            />
          )}
        </SidePanel>
      )}
    </Container>
  );
};

export default Suggestion;
