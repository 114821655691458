import React from 'react';
import HeaderLogo from '@components/HeaderLogo';
import HeaderLinksGroup from '@components/HeaderLinksGroup';
import { HeaderFavoriteContainer, StyledLink, StyledMyFavorites, StyledNavBar } from '@components/NavBar/style';
import { generatePath, useNavigate } from 'react-router-dom';
import { PATHS } from '@routes';
import WhiteHeart from '@assets/like.svg';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import Functions from '@utils/Functions';

export const GGMobileNavBar: React.FC = () => {
  const navigate = useNavigate();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  return (
    <StyledNavBar id="header-mobile" theme={brandConfiguration}>
      <HeaderLogo />

      <HeaderLinksGroup>
        <HeaderFavoriteContainer>
          <StyledLink
            theme={brandConfiguration}
            onClick={() =>
              navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId: caRecommendation?.recommendationId }))
            }
          >
            <StyledMyFavorites src={WhiteHeart} alt="My Favorites" />
          </StyledLink>
        </HeaderFavoriteContainer>
      </HeaderLinksGroup>
    </StyledNavBar>
  );
};
