import React, { useEffect, useState } from 'react';
import useSelector from '@redux/useSelector';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { LookbookProduct, Product as ProductType, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import Loader from '@components/Loader';

import { Container } from '@pages/LikedProducts/LikedProducts.style';
import { PATHS } from '@routes';
import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';
import { DesktopNavBar, MobileNavBar } from '@components/NavBar';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { useFetchCaRecommendation } from '@redux/caRecommendation/hooks';
import { Footer } from '@components/Footer';
import ContactBubble from '@components/ContactBubble';
import ContactModal from '@components/ContactModal';
import { LikedProductsContainer } from '@pages/LikedProducts/components/LikedProductsContainer';
import { LikedProductsMessage } from '@pages/LikedProducts/components/LikedProductsMessage';
import { GoBackContainer } from '@pages/LikedProducts/components/GoBackContainer';
import { GoBackIcon } from '@pages/LikedProducts/components/GoBackIcon';
import { GoBackText } from '@pages/LikedProducts/components/GoBackText';
import { LikedProductsBackground } from '@pages/LikedProducts/components/LikedProductsBackground';
import { Title } from '@pages/LikedProducts/components/Title';
import { ProductsContainer } from '@pages/LikedProducts/components/ProductsContainer';
import ProductDisplay from '@pages/Suggestion/components/ProductList/components/ProductDisplay';
import { NoProducts } from '@pages/LikedProducts/components/NoProducts';
import { SidePanels } from '@components/panels/SidePanel/SidePanels';
import Functions from '@utils/Functions';

type ILikedProducts = {
  caRecommendationId: string;
};

const LikedProducts: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { caRecommendationId } = useParams<ILikedProducts>();
  const [, fetchCaRecommendation] = useFetchCaRecommendation();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const { name: brand } = brandConfiguration.constants;
  const caRecommendation = useSelector(getCaRecommendation);
  const language = useSelector(getLanguage)?.language.locale;
  const [likedProducts, setLikedProducts] = useState<ProductType[]>([]);
  const [openContactModal, setOpenContactModal] = useState(false);

  useEffect(() => {
    if (caRecommendation) {
      let likeProducts: ProductType[] = [];
      if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
        const products = caRecommendation.products as ProductType[];
        likeProducts = products.filter((product) => Functions.isLikedProduct(product));
      }
      if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
        const products = caRecommendation.products as LookbookProduct[];
        products.forEach((product) => {
          likeProducts = [...likeProducts, ...product.products.filter((p) => Functions.isLikedProduct(p))];
        });
      }

      setLikedProducts(likeProducts);
    }
  }, [caRecommendation]);

  useEffect(() => {
    (async function fetch() {
      if (caRecommendationId) {
        await fetchCaRecommendation(caRecommendationId);
      }
    })();
  }, [caRecommendationId, fetchCaRecommendation]);

  if (!caRecommendation) {
    return <Loader />;
  }

  /* todo refacto to use only recommendationId or caRecommendationId everywhere */
  const goToProductDetail = (product: ProductType) => {
    navigate(
      generatePath(PATHS.PRODUCT_DETAIL, {
        caRecommendationId: caRecommendation.id,
        recommendationId: caRecommendation.id,
        productStyleColorCode: product.styleColorCode,
      }),
    );
  };

  return (
    <ThemeProvider theme={{ language }}>
      <Container theme={brandConfiguration}>
        <div>
          <SidePanels />

          <DesktopNavBar />

          <MobileNavBar />

          <LikedProductsBackground>
            <LikedProductsContainer>
              <LikedProductsMessage>
                <GoBackContainer onClick={() => navigate(`/suggestion/${caRecommendation.id}`)}>
                  <GoBackIcon />

                  <GoBackText />
                </GoBackContainer>

                <Title nbProducts={likedProducts.length} />

                {!likedProducts.length && <NoProducts caRecommendationId={caRecommendation.id} />}
              </LikedProductsMessage>

              {!!likedProducts.length && (
                <ProductsContainer>
                  {likedProducts.map((product, index) => (
                    <ProductDisplay
                      key={product.id}
                      caRecommendation={caRecommendation}
                      product={product}
                      index={index}
                      goToProductDetail={() => goToProductDetail(product)}
                      brand={brand}
                    />
                  ))}
                </ProductsContainer>
              )}
            </LikedProductsContainer>
          </LikedProductsBackground>
        </div>

        <ContactBubble
          openContact={() => setOpenContactModal(!openContactModal)}
          isContactOpen={openContactModal}
          brand={brand}
        />

        <Footer />

        {caRecommendation?.advisor && (
          <ContactModal
            open={openContactModal}
            advisor={caRecommendation.advisor}
            action={() => setOpenContactModal(!openContactModal)}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default LikedProducts;
