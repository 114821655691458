import styled, { css } from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { fontSize, fontWeight, getSpacing, onLargeScreen } from '@stylesheet';
import theme from 'styled-theming';

const CommonAdvisorName = styled.p`
  text-align: start;
  color: ${(props) => props.theme.design.colors.textFullBackgroundPage};
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};

  ${onLargeScreen(css`
    line-height: 1.3;
  `)}
`;

const BVAdvisorName = styled.p`
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal};
  line-height: ${fontSize.large};
`;

type Props = {
  children: React.ReactNode;
};

export const AdvisorName: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const AdvisorName = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return CommonAdvisorName;
      case BRAND.BV:
        return BVAdvisorName;
      default:
        return null;
    }
  })();

  return AdvisorName && <AdvisorName theme={brandConfiguration}>{children}</AdvisorName>;
};
