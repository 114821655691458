import React from 'react';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { SlpModalContainer, SlpModalItems, SlpModalItemsIcon } from '@components/ContactModal/style';
import { FormattedMessage } from 'react-intl';
import { PublicAdvisor } from '@redux/advisors/types';

type Props = {
  advisor: PublicAdvisor;
};

const SlpContactContent: React.FC<Props> = ({ advisor }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const LineIcon = brandConfiguration.design.businessCard.icons?.line;
  const KakaoIcon = brandConfiguration.design.businessCard.icons?.kakao;
  const SmsIcon = brandConfiguration.design.businessCard.icons?.sms;
  const FacebookIcon = brandConfiguration.design.businessCard.icons?.facebook;
  const MailIcon = brandConfiguration.design.businessCard.icons?.mail;
  const PhoneIcon = brandConfiguration.design.businessCard.icons?.phone;
  const WechatIcon = brandConfiguration.design.businessCard.icons?.wechat;
  const WeiboIcon = brandConfiguration.design.businessCard.icons?.weibo;
  const WhatsappIcon = brandConfiguration.design.businessCard.icons?.whatsapp;
  const { arrow: ArrowIcon } = brandConfiguration.design.logos;

  return (
    <SlpModalContainer>
      {advisor.sms && (
        <SlpModalItems href={`sms:${advisor.sms}`} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {SmsIcon && <SmsIcon />}
            <FormattedMessage id="slp.socials.sms" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.workPhoneNumber && (
        <SlpModalItems href={`tel:${advisor.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {PhoneIcon && <PhoneIcon />}
            <FormattedMessage id="slp.socials.phone" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.email && (
        <SlpModalItems href={`mailto:${advisor.email}`} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {MailIcon && <MailIcon />}
            <FormattedMessage id="slp.socials.mail" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.whatsApp && (
        <SlpModalItems href={advisor.whatsApp} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {WhatsappIcon && <WhatsappIcon />}
            <FormattedMessage id="slp.socials.whatsapp" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.facebook && (
        <SlpModalItems href={advisor.facebook} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {FacebookIcon && <FacebookIcon />}
            <FormattedMessage id="slp.socials.facebook" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.line && (
        <SlpModalItems href={advisor.line} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {LineIcon && <LineIcon />}
            <FormattedMessage id="slp.socials.line" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.kakao && (
        <SlpModalItems href={advisor.kakao} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {KakaoIcon && <KakaoIcon />}
            <FormattedMessage id="slp.socials.kakao" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.wechat && (
        <SlpModalItems href={advisor.wechat} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {WechatIcon && <WechatIcon />}
            <FormattedMessage id="slp.socials.wechat" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}

      {advisor.weibo && (
        <SlpModalItems href={advisor.weibo} rel="noopener noreferrer" target="_blank">
          <SlpModalItemsIcon>
            {WeiboIcon && <WeiboIcon />}
            <FormattedMessage id="slp.socials.weibo" />
          </SlpModalItemsIcon>
          {ArrowIcon && <ArrowIcon />}
        </SlpModalItems>
      )}
    </SlpModalContainer>
  );
};

export default SlpContactContent;
