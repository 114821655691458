import getEnv from '@services/envs/getEnv';
import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: getEnv('REACT_APP_API_BASE_URL'),
  withCredentials: true,
});

/* todo type axios response (to check with MSXChat) */
const api = {
  getActivateCARecommendation: (linkId: string = '', isAdvisor: string = 'false'): Promise<AxiosResponse<any>> =>
    instance.get(`/recommendation/activate/${linkId}?isAdvisor=${isAdvisor}`),
  getCARecommendation: (id: string = ''): Promise<AxiosResponse<any>> => instance.get(`/recommendation/${id}`),
  updateFeedback: (
    feedback: any,
    recommendationId: string = '',
    productStyleColorCode: string = '',
  ): Promise<AxiosResponse<any>> =>
    instance.post(`/recommendation/${recommendationId}/products/${productStyleColorCode}/feedback`, { feedback }),
};

export default api;
