import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { ReactComponent as GGLeftChevronIcon } from '@assets/chevron_left.svg';
import { ReactComponent as BVLeftChevronIcon } from '@assets/icons/arrow/left/arrow-bv.svg';
import { colorUsage } from '@stylesheet';

const GGGoBackIcon = styled(GGLeftChevronIcon)`
  margin-top: auto;
  margin-bottom: auto;
  fill: ${colorUsage.black};
`;

const BVGoBackIcon = styled(BVLeftChevronIcon)`
  height: 11px;
  width: 8px;
`;

export const GoBackIcon: React.FC = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const GoBackIcon = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return GGGoBackIcon;
      case BRAND.BV:
        return BVGoBackIcon;
      default:
        return null;
    }
  })();

  return GoBackIcon && <GoBackIcon />;
};
