import styled, { css } from 'styled-components';
import { colorUsage, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';
import { ReactComponent as DesktopArrowDownIcon } from '@assets/arrow_down.svg';
import { ReactComponent as BubbleContainer } from '@assets/bubble.svg';

export const ContactButton = styled.div`
  width: 67px;
  height: 67px;
  border-radius: 80px;
  position: fixed;
  display: flex;
  background-color: ${colorUsage.black};
  bottom: ${getSpacing(4)};
  right: ${getSpacing(4)};
  z-index: 10;
  box-shadow: 0 3.15px 7.87px 0 rgba(0, 0, 0, 0.25);
  &:hover {
    cursor: pointer;
  }
  ${onSmallScreen(css`
    bottom: ${getSpacing(2)};
    right: ${getSpacing(2)};
  `)}
`;

export const ContactImage = styled.img`
  position: static;
  top: 0;
  left: 0;
  width: 67px;
  height: 67px;
  border-radius: 48px;
  object-fit: cover;
  background-color: white;
  z-index: 1;
`;

export const StyledArrowDownIcon = styled(DesktopArrowDownIcon)`
  margin: auto;
  z-index: 1;
  fill: ${colorUsage.white};
`;

export const SlpButtonContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: ${getSpacing(2)};
  right: 20px;
  opacity: 1;
  gap: 16px;
  z-index: 10;
  ${onLargeScreen(css`
    display: none;
  `)}
`;

export const SlpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 36px;
  height: 36px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;

export const StyledBubbleContainer = styled(BubbleContainer)`
  position: absolute;
`;
