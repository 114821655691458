import styled, { css } from 'styled-components';
import { colorUsage, font, fontWeight, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';

export const Background = styled.div`
  margin-top: ${(props) => props.theme.design.navbar.mobile.height};
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  background-color: ${colorUsage.white};

  ${onLargeScreen(css`
    margin-top: ${(props) => props.theme.design.navbar.height};
  `)}
`;

export const ProductsContainer = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  color: white;
  ${onSmallScreen(css`
    border-top: 1px solid black;
    grid-template-columns: ${(props) => props.theme.design.columns.small};
    overflow-x: hidden;
    grid-gap: 1px;
  `)}

  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}
`;

export const ProductsSLPContainer = styled.div`
  width: 100%;
  max-width: 844px;
  display: flex;
  margin: auto;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  ${onSmallScreen(css`
    width: auto;
    margin-left: 17px;
    margin-right: 17px;
  `)}
`;

export const BVProductsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;

  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `)}
`;

export const ProductsLookbookChapter = styled.div`
  width: 100%;
  ${font.Large};
  text-transform: uppercase;
  background-color: ${(props) => props.theme.design.colors.background};
  color: ${colorUsage.white};
  font-weight: ${fontWeight.bold};
  display: flex;
  justify-content: center;
  padding-top: ${getSpacing(5)};
  padding-bottom: ${getSpacing(5)};
  ${onSmallScreen(css`
    padding-top: ${getSpacing(4)};
    padding-bottom: ${getSpacing(4)};
  `)}
`;
