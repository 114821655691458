import React from 'react';
import HeaderLogo from '@components/HeaderLogo';
import { ArrowContainer, SLPContainer, StyledLink, StyledNavBar } from '@components/NavBar/style';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { PATHS } from '@routes';

interface Props {
  isProductDetailPage?: boolean;
}

export const SLPMobileNavBar: React.FC<Props> = ({ isProductDetailPage }) => {
  const navigate = useNavigate();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  const { arrow: ArrowIcon } = brandConfiguration.design.logos;

  return (
    <StyledNavBar id="header-mobile" theme={brandConfiguration}>
      <SLPContainer justifyContent="flex-start">
        {isProductDetailPage && (
          <StyledLink
            onClick={() =>
              navigate(generatePath(PATHS.SUGGESTION, { caRecommendationId: caRecommendation?.recommendationId }))
            }
          >
            {ArrowIcon && (
              <ArrowContainer>
                <ArrowIcon />
              </ArrowContainer>
            )}
          </StyledLink>
        )}
      </SLPContainer>

      <HeaderLogo />

      <SLPContainer justifyContent="flex-end" />
    </StyledNavBar>
  );
};
