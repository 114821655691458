import React from 'react';
import { CARecommendation, Product as ProductType } from '@redux/caRecommendation/types';
import Product from '@components/Product';
import { BRAND, PRODUCT_LIST_SIZE } from '@redux/brandConfiguration/types';
import SlpProduct from '@components/SlpProduct';
import BvProduct from '@components/BvProduct';

interface Props {
  caRecommendation: CARecommendation;
  product: ProductType;
  index: number;
  goToProductDetail: (arg0: ProductType) => void;
  isLookbook?: boolean;
  brand?: string;
  size?: PRODUCT_LIST_SIZE;
  isDesktopVersion?: boolean;
  nbProducts?: number;
}

type ProductDisplayType = React.FC<Props>;

export const ProductDisplay: ProductDisplayType = ({
  caRecommendation,
  product,
  index,
  goToProductDetail,
  isLookbook = false,
  brand,
  size,
  isDesktopVersion,
  nbProducts,
}) => {
  switch (brand) {
    case BRAND.SLP:
      return (
        <SlpProductDisplay
          caRecommendation={caRecommendation}
          product={product}
          index={index}
          goToProductDetail={goToProductDetail}
          isLookbook={isLookbook}
          isDesktopVersion={isDesktopVersion}
          size={size}
          nbProducts={nbProducts}
        />
      );
    case BRAND.BV:
      return (
        <BVProductDisplay
          caRecommendation={caRecommendation}
          product={product}
          index={index}
          goToProductDetail={goToProductDetail}
          isLookbook={isLookbook}
        />
      );
    default:
      return (
        <GGProductDisplay
          caRecommendation={caRecommendation}
          product={product}
          index={index}
          goToProductDetail={goToProductDetail}
          isLookbook={isLookbook}
        />
      );
  }
};

const GGProductDisplay: ProductDisplayType = ({
  caRecommendation,
  product,
  index,
  goToProductDetail,
  isLookbook = false,
}) => (
  <Product
    key={product.id}
    caRecommendation={caRecommendation}
    product={product}
    goToProductDetail={() => goToProductDetail(product)}
    isLookbook={isLookbook}
    index={index}
  />
);

const SlpProductDisplay: ProductDisplayType = ({
  caRecommendation,
  product,
  goToProductDetail,
  isDesktopVersion,
  size,
  nbProducts,
  index,
}) => (
  <SlpProduct
    key={product.id}
    caRecommendation={caRecommendation}
    product={product}
    goToProductDetail={() => goToProductDetail(product)}
    size={size ?? PRODUCT_LIST_SIZE.SMALL}
    isDesktopVersion={isDesktopVersion ?? true}
    nbProducts={nbProducts}
    index={index}
  />
);

const BVProductDisplay: ProductDisplayType = ({
  caRecommendation,
  product,
  index,
  goToProductDetail,
  isLookbook = false,
}) => (
  <BvProduct
    key={product.id}
    caRecommendation={caRecommendation}
    product={product}
    goToProductDetail={() => goToProductDetail(product)}
    isLookbook={isLookbook}
    index={index}
  />
);

export default ProductDisplay;
