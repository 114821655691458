import React from 'react';
import { CARecommendation, Product } from '@redux/caRecommendation/types';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import GucciMobileContent from '@pages/ProductDetailPage/components/MobileProductDetailPage/content/GucciMobileContent';
import SlpMobileContent from '@pages/ProductDetailPage/components/MobileProductDetailPage/content/SlpMobileContent';
import Functions from '@utils/Functions';

interface Props {
  product: Product;
  recommendation: CARecommendation;
  brand: string;
}

const MobileProductDetailPage: React.FunctionComponent<Props> = ({ product, recommendation, brand }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const initialLiked = Functions.isLikedProduct(product);

  const text: string = product.description ?? '';

  const indexFirstPart: number = text.indexOf(';');

  const part1: string = text.substring(0, indexFirstPart);
  const part2: string = text.substring(indexFirstPart + 1);

  const bulletPoints = part2.split(';').map((point, index) => <li key={index}>{point.trim()}</li>);

  switch (brand) {
    case BRAND.GUCCI:
      return (
        <GucciMobileContent
          product={product}
          recommendation={recommendation}
          brandConfiguration={brandConfiguration}
          initialLiked={initialLiked}
          part1={part1}
          bulletPoints={bulletPoints}
        />
      );

    case BRAND.SLP:
      return (
        <SlpMobileContent
          product={product}
          recommendation={recommendation}
          brandConfiguration={brandConfiguration}
          initialLiked={initialLiked}
          part1={part1}
          bulletPoints={bulletPoints}
        />
      );

    default:
      return null;
  }
};

export default MobileProductDetailPage;
