import React from 'react';
import styled, { css } from 'styled-components';
import { fontSize, fontWeight, onSmallScreen } from '@stylesheet';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const CommonStyledMessages = styled.div`
  width: ${(props) => props.theme.design.pages.productsList.introduction.message.width};
  display: ${(props) => props.theme.design.pages.productsList.introduction.message.display};
  flex-direction: ${(props) => props.theme.design.pages.productsList.introduction.message.flexDirection};
  justify-content: ${(props) => props.theme.design.pages.productsList.introduction.message.justifyContent};
  background-color: ${(props) => props.theme.design.pages.productsList.introduction.message.backgroundColor};
  padding: ${(props) => props.theme.design.pages.productsList.introduction.message.padding};
  text-align: ${(props) => props.theme.design.pages.productsList.introduction.message.textAlign};
  ${onSmallScreen(css`
    min-height: ${(props) => props.theme.design.pages.productsList.introduction.message.mobile.minHeight};
    padding: ${(props) => props.theme.design.pages.productsList.introduction.message.mobile.padding};
  `)}
`;

const BVStyledMessages = styled.div`
  line-height: 24px;
  font-family: ${(props) => props.theme.design.fonts.introduction};
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal};
`;

type Props = {
  children: React.ReactNode;
};

export const StyledMessages: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const StyledMessages = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return BVStyledMessages;
      default:
        return CommonStyledMessages;
    }
  })();

  return <StyledMessages theme={brandConfiguration}>{children}</StyledMessages>;
};
