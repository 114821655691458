import React, { useEffect, useState } from 'react';

import { useUpdateFeedback } from '@redux/caRecommendation/hooks';
import { Favorite } from '@components/LikeButton/LikeButton.style';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';

interface Props {
  caRecommendationId: string;
  styleColorCode: string;
  initialIsLiked: boolean;
  className?: string;
  setLiked?: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicIsLiked?: boolean;
}

const LikeButton: React.FC<Props> = ({
  caRecommendationId,
  styleColorCode,
  initialIsLiked,
  className,
  setLiked,
  dynamicIsLiked,
}) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const { fullHeart: FullHeart, emptyHeart: EmptyHeart } = brandConfiguration.design.logos;

  const [, updateFeedback] = useUpdateFeedback();

  const [isLiked, setIsLiked] = useState<boolean>(initialIsLiked);

  const giveFeedback = async (feedback: number | null) => {
    await updateFeedback(caRecommendationId, styleColorCode, feedback);
  };

  const like = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(true);
    if (setLiked) {
      setLiked(true);
    }
    await giveFeedback(4);
  };
  const unLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(false);
    if (setLiked) {
      setLiked(false);
    }
    await giveFeedback(null);
  };

  useEffect(() => {
    setIsLiked(dynamicIsLiked ?? initialIsLiked);
  }, [dynamicIsLiked]);

  return isLiked ? (
    <Favorite onClick={unLike} className={`product-favorite-fill ${className ?? ''}`} theme={brandConfiguration}>
      {FullHeart && <FullHeart />}
    </Favorite>
  ) : (
    <Favorite onClick={like} className={`product-favorite-empty ${className ?? ''}`} theme={brandConfiguration}>
      {EmptyHeart && <EmptyHeart />}
    </Favorite>
  );
};

export default LikeButton;
