import React from 'react';
import styled, { css } from 'styled-components';
import { onSmallScreen } from '@stylesheet';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { getLanguage } from '@redux/language/selectors';

const DefaultAdvisorName = styled.p<{ language: string }>`
  direction: ${(props) => (props.language === 'ar' ? 'rtl' : 'ltr')};
`;

const SLPAdvisorName = styled(DefaultAdvisorName)`
  font-size: ${(props) => props.theme.design.pages.productsList.introduction.advisor.fontSize};
  font-weight: ${(props) => props.theme.design.pages.productsList.introduction.advisor.fontWeight};
  line-height: ${(props) => props.theme.design.pages.productsList.introduction.advisor.lineHeight};
  margin-top: ${(props) => props.theme.design.pages.productsList.introduction.advisor.marginTop};
  font-style: ${(props) => props.theme.design.pages.productsList.introduction.advisor.fontStyle};
  color: ${(props) => props.theme.design.pages.productsList.introduction.advisor.color};
  text-transform: ${(props) => props.theme.design.pages.productsList.introduction.advisor.textTransform};
  ${onSmallScreen(css`
    line-height: ${(props) => props.theme.design.pages.productsList.introduction.advisor.mobile.lineHeight};
    font-size: ${(props) => props.theme.design.pages.productsList.introduction.advisor.mobile.fontSize};
  `)}
`;

type Props = {
  children: React.ReactNode;
};

export const AdvisorName: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const AdvisorName = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.SLP:
        return SLPAdvisorName;
      default:
        return null;
    }
  })();

  return (
    AdvisorName && (
      <AdvisorName theme={brandConfiguration} language={language}>
        {children}
      </AdvisorName>
    )
  );
};
