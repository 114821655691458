import React from 'react';

import { CARecommendation, Product as ProductType } from '@redux/caRecommendation/types';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import useSelector from '@redux/useSelector';

import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';
import {
  Container,
  Favorite,
  Image,
  LookbookNumericOrder,
  LookbookNumericOrderText,
  Name,
  NameContainer,
} from '@components/Product/Product.style';
import Functions from '@utils/Functions';

interface Props {
  caRecommendation: CARecommendation;
  product: ProductType;
  goToProductDetail: () => void;
  isLookbook: boolean;
  index?: number;
}

const Product: React.FC<Props> = ({ caRecommendation, product, goToProductDetail, isLookbook, index }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const isLiked = Functions.isLikedProduct(product);

  return (
    <ThemeProvider theme={{ language }}>
      <Container theme={brandConfiguration} onClick={goToProductDetail}>
        <Image src={product.thumbnail} alt="" className="product-img" />
        <NameContainer>
          <Name className="product-title">{product.name}</Name>
        </NameContainer>
        {brandConfiguration.features.favorite && (
          <Favorite
            caRecommendationId={caRecommendation.id}
            styleColorCode={product.styleColorCode}
            initialIsLiked={isLiked}
          />
        )}
        {isLookbook && index !== undefined && (
          <LookbookNumericOrder className="product-lookbook-numeric-order" theme={brandConfiguration}>
            <LookbookNumericOrderText>{index + 1}</LookbookNumericOrderText>
          </LookbookNumericOrder>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Product;
