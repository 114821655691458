import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const CommonAdvisorImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  object-fit: cover;
`;

type Props = {
  src: string;
};

export const AdvisorImage: React.FC<Props> = ({ src }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const AdvisorImage = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return CommonAdvisorImage;
      default:
        return null;
    }
  })();

  return AdvisorImage && <AdvisorImage src={src} />;
};
