import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colorUsage, onLargeScreen } from '@stylesheet';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const BVLikedProductsBackground = styled.div`
  margin-top: ${(props) => props.theme.design.navbar.mobile.height};

  ${onLargeScreen(css`
    margin-top: ${(props) => props.theme.design.navbar.height};
  `)}
`;

interface Props {
  children: ReactNode;
}

export const LikedProductsBackground: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const LikedProductsBackground = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return BVLikedProductsBackground;
      default:
        return null;
    }
  })();

  return LikedProductsBackground ? (
    <LikedProductsBackground theme={brandConfiguration}>{children}</LikedProductsBackground>
  ) : (
    <>{children}</>
  );
};
