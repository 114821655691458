import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import styled, { css } from 'styled-components';
import { colorUsage, onSmallScreen } from '@stylesheet';

const DefaultPanel = styled.div<{ width: string }>`
  background-color: ${colorUsage.white};
  width: ${(props) => props.width};
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;

  &.panel-contact-enter {
    transform: translateX(100%);
  }

  &.panel-contact-enter-active {
    transform: translateX(0);
    transition: transform 300ms ease-in;
  }

  &.panel-contact-exit {
    transform: translateX(0);
  }

  &.panel-contact-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in;
  }

  &.panel-favorites-enter {
    transform: translateX(100%);
  }

  &.panel-favorites-enter-active {
    transform: translateX(0);
    transition: transform 300ms ease-in;
  }

  &.panel-favorites-exit {
    transform: translateX(0);
  }

  &.panel-favorites-exit-active {
    transform: translateX(100%);
    transition: transform 300ms ease-in;
  }

  ${onSmallScreen(css`
    width: 100vw;
    max-width: 100vw;

    &.panel-favorites-enter {
      transform: translateY(100%);
    }

    &.panel-favorites-enter-active {
      transform: translateY(0%);
    }

    &.panel-favorites-enter-active-done {
      transform: translateY(100%);
      transition: transform 300ms ease-in;
    }

    &.panel-favorites-exit {
      transform: translateY(0);
    }

    &.panel-favorites-exit-active {
      transform: translateY(100%);
      transition: transform 300ms ease-in;
    }
  `)}
`;

interface Props {
  width: string;
  children: React.ReactNode;
}

export const Panel: React.FC<Props> = ({ width, children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const Panel = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.SLP:
      case BRAND.BV:
        return DefaultPanel;
      default:
        return null;
    }
  })();

  return Panel && <Panel width={width}>{children}</Panel>;
};
