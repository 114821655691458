import styled from 'styled-components';
import { colorUsage } from '@stylesheet';

export const StyledCarousel = styled.div`
  .dots-button {
    visibility: hidden;
  }

  .slick-next {
    right: 10px;
    z-index: 1;
    scale: 2;
  }

  .slick-prev {
    left: 10px;
    z-index: 1;
    scale: 2;
  }

  .slick-slider-gg,
  .slick-slider-slp {
    .slick-dots {
      margin-top: 32px;

      li {
        div {
          background-color: ${colorUsage.slpSliderDot};
          height: 2px;
          width: 20px;
          margin-top: 24px;
        }
      }

      li.slick-active {
        div {
          background-color: ${colorUsage.black};
        }
      }
    }
  }

  .slick-slider-bv {
    .slick-dots {
      display: flex !important;
      width: fit-content;
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;

      li {
        all: unset;

        div {
          width: 24px;
          height: 35px;
          align-content: center;
          cursor: pointer;

          div {
            width: 4px;
            height: 4px;
            background-color: black;
            border-radius: 50%;
            opacity: 0.5;
            justify-self: center;
          }
        }
      }

      li.slick-active {
        div {
          cursor: default;

          div {
            width: 6px;
            height: 6px;
            opacity: 1;
          }
        }
      }
    }
  }
`;
