import React from 'react';
import { Greetings as GreetingsType } from '@redux/caRecommendation/types';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { getLanguage } from '@redux/language/selectors';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import Functions from '@utils/Functions';
import {
  AdvisorName,
  Greeting,
  Hello,
  MySelection,
} from '@pages/Suggestion/components/ProductList/Introduction/Greetings/components';
import { FormattedMessage } from 'react-intl';

export const Greetings: React.FC = () => {
  const recommendation = useSelector(getCaRecommendation);
  const {
    brandConfiguration: {
      constants: { name: brand },
    },
  } = useSelector(getBrandConfiguration);

  const CustomGreetings: React.FC<{ greetings: GreetingsType }> = ({ greetings }) => {
    return (
      <>
        <Hello>{greetings.salutation}</Hello>

        <MySelection>{greetings.body}</MySelection>

        <Greeting>
          {greetings.signature}
          <br />
          {brand === BRAND.SLP && Functions.displayAdvisorName(recommendation?.advisor)}
        </Greeting>
      </>
    );
  };

  const DefaultGreetings: React.FC = () => {
    const language = useSelector(getLanguage)?.language.locale;
    const customerGenderTranslationKey =
      recommendation?.clientGender === 'MALE'
        ? Functions.getGenderTranslation(brand.toLowerCase(), 'MR')
        : Functions.getGenderTranslation(brand.toLowerCase(), recommendation?.clientTitle);
    const customerName = recommendation?.clientDisplayName || recommendation?.clientLocalFirstName;

    return (
      <>
        <Hello>
          {customerGenderTranslationKey && brand !== BRAND.BV && !['kr', 'jp'].includes(language) && (
            <FormattedMessage
              id={customerGenderTranslationKey}
              values={{
                space: '\u00a0',
              }}
            />
          )}

          {customerName && (
            <FormattedMessage
              id={`${brand.toLowerCase()}.product-list.hello`}
              values={{
                clientFirstName:
                  language === 'kr'
                    ? Functions.toTitleCase(recommendation?.clientLocalFirstName)
                    : Functions.toTitleCase(customerName),
                clientLastName: Functions.toTitleCase(recommendation?.clientDisplayName),
                clientMiddleName: `${recommendation?.clientLocalMiddleName && recommendation?.clientLocalFirstName ? ' ' : ''}${Functions.toTitleCase(recommendation?.clientLocalMiddleName)}`,
                linebreak: <br />,
                space: '\u00a0',
              }}
            />
          )}
        </Hello>

        <MySelection>
          {brand === BRAND.GUCCI && language === 'ar' ? (
            <FormattedMessage
              id={
                recommendation?.clientGender !== undefined && recommendation?.clientGender !== 'MALE'
                  ? `${brand.toLowerCase()}.product-list.my-selection.female`
                  : `${brand.toLowerCase()}.product-list.my-selection.male`
              }
              values={{
                linebreak: <br />,
                space: '\u00a0',
              }}
            />
          ) : (
            <FormattedMessage
              id={`${brand.toLowerCase()}.product-list.my-selection`}
              values={{
                linebreak: <br />,
              }}
            />
          )}
        </MySelection>

        {(brand === BRAND.SLP || language !== 'jp') && (
          <Greeting>
            <FormattedMessage
              id={`${brand.toLowerCase()}.product-list.greeting`}
              values={{
                advisorName: Functions.displayAdvisorName(recommendation?.advisor),
                linebreak: <br />,
                space: '\u00a0',
              }}
            />
          </Greeting>
        )}

        {(brand === BRAND.SLP || language !== 'kr') && (
          <AdvisorName>{Functions.displayAdvisorName(recommendation?.advisor)}</AdvisorName>
        )}
      </>
    );
  };

  const greetings = recommendation?.greetings ? (
    <CustomGreetings greetings={recommendation.greetings} />
  ) : (
    <DefaultGreetings />
  );

  // For BV, need to get greetings as a unique block in the rendering
  switch (brand) {
    case BRAND.BV:
      return <div>{greetings}</div>;
    default:
      return greetings;
  }
};
