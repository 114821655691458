import React, { useState } from 'react';
import HeaderLogo from '@components/HeaderLogo';
import { BVHeaderMobileLinks, StyledLink, StyledNavBar, StyledText } from '@components/NavBar/style';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { PATHS } from '@routes';
import CloseButton from '@components/CloseButton';
import BurgerMenu from '@components/icons/BurgerMenu';
import HeaderPanel from '@components/panels/HeaderPanel';
import { FormattedMessage } from 'react-intl';
import { useSidePanel } from '@components/panels/SidePanel/SidePanelContext';

interface Props {
  isProductDetailPage?: boolean;
}

export const BVMobileNavBar: React.FC<Props> = ({ isProductDetailPage }) => {
  const navigate = useNavigate();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  const [isHeaderPanelOpen, setIsHeaderPanelOpen] = useState(false);

  const { setContactSidePanelOpen } = useSidePanel();

  return (
    <>
      <StyledNavBar id="header-mobile" theme={brandConfiguration} zIndex={101}>
        <HeaderLogo />

        {isHeaderPanelOpen ? (
          <CloseButton mobileSize="22px" onClick={() => setIsHeaderPanelOpen(false)} />
        ) : (
          <BurgerMenu onClick={() => setIsHeaderPanelOpen(true)} />
        )}
      </StyledNavBar>

      <HeaderPanel action={() => setIsHeaderPanelOpen(false)} isOpen={isHeaderPanelOpen} height="128px" type="default">
        <BVHeaderMobileLinks>
          <StyledLink
            onClick={() => {
              navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId: caRecommendation?.recommendationId }));
              setIsHeaderPanelOpen(false);
            }}
          >
            <StyledText theme={brandConfiguration}>
              <FormattedMessage id="bv.nav-bar.favorites" />
            </StyledText>
          </StyledLink>

          <StyledLink onClick={() => setContactSidePanelOpen(true)}>
            <StyledText theme={brandConfiguration}>
              <FormattedMessage id="bv.nav-bar.contact-advisor" />
            </StyledText>
          </StyledLink>
        </BVHeaderMobileLinks>
      </HeaderPanel>
    </>
  );
};
