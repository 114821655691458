import React from 'react';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { FormattedMessage } from 'react-intl';
import { PublicAdvisor } from '@redux/advisors/types';
import {
  ContactContentContainer,
  ContactContentIcon,
  ContactContentItem,
  ContactContentLink,
} from '@components/SidePanel/Content/Contact/BvContactContent.style';

type Props = {
  advisor: PublicAdvisor;
};

const BvContactContent: React.FC<Props> = ({ advisor }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const MailIcon = brandConfiguration.design.businessCard.icons?.mail;
  const PhoneIcon = brandConfiguration.design.businessCard.icons?.phone;
  const WhatsappIcon = brandConfiguration.design.businessCard.icons?.whatsapp;

  return (
    <ContactContentContainer>
      {advisor.email && (
        <ContactContentItem>
          <ContactContentIcon>{MailIcon && <MailIcon />}</ContactContentIcon>

          <ContactContentLink href={`mailto:${advisor.email}`} rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="bv.socials.mail" />
          </ContactContentLink>
        </ContactContentItem>
      )}

      {advisor.whatsApp && (
        <ContactContentItem>
          <ContactContentIcon>{WhatsappIcon && <WhatsappIcon />}</ContactContentIcon>

          <ContactContentLink href={advisor.whatsApp} rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="bv.socials.whatsapp" />
          </ContactContentLink>
        </ContactContentItem>
      )}

      {advisor.workPhoneNumber && (
        <ContactContentItem>
          <ContactContentIcon>{PhoneIcon && <PhoneIcon />}</ContactContentIcon>

          <ContactContentLink href={`tel:${advisor.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">
            <FormattedMessage id="bv.socials.phone" />
          </ContactContentLink>
        </ContactContentItem>
      )}
    </ContactContentContainer>
  );
};

export default BvContactContent;
