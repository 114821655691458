import styled, { css } from 'styled-components';
import { colorUsage, fontSize, fontWeight, onSmallScreen } from '@stylesheet';
import Button from '@components/Button';

export const PanelFavoritesContent = styled.div`
  width: 390px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${onSmallScreen(css`
    width: 90%;
  `)}
`;

export const PanelFavoritesProducts = styled.div`
  height: calc(100vh - 198px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  margin-top: 48px;
`;

export const PanelFavoritesProduct = styled.div`
  height: 158px;
  min-height: 158px;

  @media (max-width: 1023px) {
    height: 138px;
    min-height: 138px;
  }

  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

export const PanelFavoritesProductImage = styled.div`
  width: 127px;
  min-width: 127px;

  @media (max-width: 1023px) {
    width: 110px;
    min-width: 110px;
  }

  height: auto;
  background-color: ${colorUsage.slpProductBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  margin: auto;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: auto;
  height: 100%;
`;

export const PanelFavoritesProductContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${(props) => props.theme.design.fonts.main};
  font-size: ${fontSize.XSmall};
  text-transform: uppercase;
  line-height: ${fontSize.medium};
  ${onSmallScreen(css`
    margin-left: 16px;
  `)}
`;

export const PanelFavoritesProductTitle = styled.div`
  font-weight: ${fontWeight.bold};
`;

export const PanelFavoritesProductQuantity = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const PanelFavoritesProductQuantityButton = styled.div`
  &:hover {
    cursor: pointer;
  }

  font-size: ${fontSize.medium};
`;

export const PanelFavoritesProductRemove = styled.p`
  text-decoration: black underline;

  &:hover {
    cursor: pointer;
  }
`;

export const PanelFavoritesButton = styled(Button)`
  width: 100%;
  height: 36px;
  max-width: 100%;
  margin-top: 64px;
  text-transform: uppercase;
  font-size: ${fontSize.XSmall};
  font-weight: ${fontWeight.normal};
`;

export const PanelBottomBlur = styled.div`
  position: fixed;
  bottom: 0;
  width: 390px;
  height: 172px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  ${onSmallScreen(css`
    width: 90%;
  `)}
`;

export const PanelFavoritesEmpty = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.XSmall};
  text-align: center;
  text-transform: uppercase;
  margin: auto;
`;
