import styled, { css } from 'styled-components';
import { colorUsage, onSmallScreen } from '@stylesheet';

export const Panel = styled.div<{ height: string }>`
  background-color: ${colorUsage.white};
  height: ${(props) => props.height};
  width: 100vw;
  position: fixed;
  top: ${(props) => props.theme.design.navbar.mobile.height};
  left: 0;
  z-index: 100;

  &.header-panel-default-enter {
    transform: translateY(-100%);
  }

  &.header-panel-default-enter-active {
    transform: translateY(0);
    transition: transform 300ms ease-in;
  }

  &.header-panel-default-exit {
    transform: translateY(0);
  }

  &.header-panel-default-exit-active {
    transform: translateY(-100%);
    transition: transform 300ms ease-in;
  }
`;

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  &.header-panel-default-enter {
    opacity: 0;
  }

  &.header-panel-default-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &.header-panel-default-exit {
    opacity: 1;
  }

  &.header-panel-default-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
`;
