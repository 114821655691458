import React, { useState } from 'react';
import { CARecommendation, Product } from '@redux/caRecommendation/types';

import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import {
  ParallaxAddToFavoritesButton,
  ParallaxButtons,
  ParallaxInformationsContainer,
  ParallaxInformationsContent,
  ParallaxInformationsLink,
  ParallaxInformationsPrice,
  ParallaxInformationsText,
  ParallaxInformationsTitle,
  ParallaxInformationsTitleAndPrice,
  ParallaxVisitWebsiteButton,
} from '@pages/ProductDetailPage/components/DesktopProductDetailPage/DesktopProductDetailPage.style';
import { getPriceWithCurrency } from '@services/product/productService';
import { FormattedMessage } from 'react-intl';
import { useUpdateFeedback } from '@redux/caRecommendation/hooks';
import Functions from '@utils/Functions';

interface Props {
  product: Product;
  recommendation: CARecommendation;
  part1: string;
  setOpenMoreDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SlpDesktopProductInformations: React.FunctionComponent<Props> = ({
  product,
  recommendation,
  part1,
  setOpenMoreDetailsModal,
}) => {
  const initialLiked: boolean = Functions.isLikedProduct(product);
  const [isLiked, setIsLiked] = useState<boolean>(initialLiked);
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const { arrow: ArrowIcon } = brandConfiguration.design.logos;

  const [, updateFeedback] = useUpdateFeedback();

  const giveFeedback = async (feedback: number | null) => {
    await updateFeedback(recommendation.id, product.styleColorCode, feedback);
  };

  const like = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(true);
    await giveFeedback(4);
  };
  const unLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(false);
    await giveFeedback(null);
  };

  return (
    <ParallaxInformationsContainer>
      <ParallaxInformationsContent>
        <ParallaxInformationsTitleAndPrice>
          <ParallaxInformationsTitle>{product.name}</ParallaxInformationsTitle>
          <ParallaxInformationsPrice>
            {getPriceWithCurrency(
              product.priceAmount,
              product.priceCurrency,
              brandConfiguration.constants.name,
              recommendation.country,
            )}
          </ParallaxInformationsPrice>
        </ParallaxInformationsTitleAndPrice>
        <ParallaxInformationsText>{product.color}</ParallaxInformationsText>
        <ParallaxInformationsText>{part1}</ParallaxInformationsText>
        <ParallaxButtons>
          <ParallaxAddToFavoritesButton theme={brandConfiguration} onClick={isLiked ? unLike : like}>
            <FormattedMessage
              id={isLiked ? 'slp.product-details.remove-from-favorites' : 'slp.product-details.add-to-favorites'}
            />
          </ParallaxAddToFavoritesButton>
          <a href={product.url} target="_blank" rel="noreferrer">
            <ParallaxVisitWebsiteButton theme={brandConfiguration}>
              <FormattedMessage id="slp.product-details.visit-website" />
            </ParallaxVisitWebsiteButton>
          </a>
        </ParallaxButtons>
        <ParallaxInformationsLink onClick={() => setOpenMoreDetailsModal(true)}>
          <p>
            <FormattedMessage id="slp.product-details.more-details" />
          </p>
          {ArrowIcon && <ArrowIcon />}
        </ParallaxInformationsLink>
      </ParallaxInformationsContent>
    </ParallaxInformationsContainer>
  );
};
export default SlpDesktopProductInformations;
