import styled, { css } from 'styled-components';
import { colorUsage, fontSize, getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';
import { ReactComponent as DesktopWhatsAppIcon } from '@assets/whatApp/whatsapp_white.svg';
import { ReactComponent as DesktopNumberIcon } from '@assets/number/phone.svg';
import { ReactComponent as DesktopMailIcon } from '@assets/mail/mail.svg';
import { ReactComponent as DesktopSmsIcon } from '@assets/sms/sms.svg';
import { ReactComponent as DesktopLineIcon } from '@assets/line/line_white.svg';
import { ReactComponent as DesktopKakaoTalkIcon } from '@assets/kakaoTalk/kakaotalk_white.svg';
import { ReactComponent as ChevronLeft } from '@assets/chevron_left.svg';
import theme from 'styled-theming';

export const SocialContactRow = styled.div`
  display: flex;
  ${theme('language', {
    ar: css`
      justify-content: flex-end;
    `,
  })}
`;

export const SocialContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(2)};
`;

export const SocialContactContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${getSpacing(2)};
  width: auto;
  font-size: ${fontSize.small};
`;

export const SocialContactContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorUsage.contactGreetingsBackground};
  border-radius: ${getSpacing(3)};
  width: auto;
`;

export const SocialContactContentMobile = styled.div`
  display: flex;
  background-color: ${colorUsage.contactGreetingsBackground};
  border-radius: ${getSpacing(3)};
  width: auto;
`;

export const AdvisorContainer = styled.div`
  display: flex;
  padding: ${getSpacing(2)};
  padding-bottom: 0;
  font-size: 20px;

  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const TextMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  text-wrap: nowrap;
  color: ${(props) => props.theme.design.colors.textFullBackgroundPage};
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const SocialContactIconsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: ${getSpacing(1)};
  ${onLargeScreen(css`
    padding-top: ${getSpacing(2)};
    padding-bottom: ${getSpacing(2)};
  `)}
  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export const StyledLink = styled.a<{
  backgroundColor?: string;
  marginLeft?: string;
}>`
  color: white;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 10pt;
  padding-right: 8px;
  cursor: pointer;
  margin-left: ${(props) => props.marginLeft};
  background-color: ${(props) => props.backgroundColor};

  ${onSmallScreen(css`
    font-size: 7pt;
  `)}
`;

export const PhoneNumber = styled.div`
  display: flex;
  ${theme('language', {
    ar: css`
      direction: ltr;
    `,
  })}
`;

export const ExpandIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AdvisorImageMobile = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
`;

export const StyledDesktopNumberIcon = styled(DesktopNumberIcon)`
  stroke: #ffffff;
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
    height: ${getSpacing(3)};
  `)}
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
`;

export const StyledDesktopWhatsAppIcon = styled(DesktopWhatsAppIcon)`
  fill: #ffffff;
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
    height: ${getSpacing(3)};
  `)}
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
`;

export const StyledDesktopMailIcon = styled(DesktopMailIcon)`
  stroke: #ffffff;
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
    height: ${getSpacing(3)};
  `)}
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
`;

export const StyledDesktopSmsIcon = styled(DesktopSmsIcon)`
  fill: #ffffff;
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
    height: ${getSpacing(3)};
  `)}
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
`;

export const StyledDesktopLineIcon = styled(DesktopLineIcon)`
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
    height: ${getSpacing(3)};
  `)}
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
`;

export const StyledDesktopKakaoTalkIcon = styled(DesktopKakaoTalkIcon)`
  ${onLargeScreen(css`
    width: ${getSpacing(3)};
    height: ${getSpacing(3)};
  `)}
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
`;

export const ChevronIcon = styled(ChevronLeft)<{ displayAllIcons: boolean }>`
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(1)};
  transform: ${(props) => (props.displayAllIcons ? 'rotate(0deg)' : 'rotate(180deg)')};
  fill: white;
`;
