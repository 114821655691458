import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Background, Panel } from '@components/panels/HeaderPanel/style';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';

type Props = {
  action: () => void;
  isOpen: boolean;
  height: string;
  type: string;
  children: ReactNode;
};

const HeaderPanel: React.FC<Props> = ({ action, isOpen, height, type, children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const classNames = `header-panel-${type}`;

  return (
    <>
      <CSSTransition in={isOpen} timeout={600} classNames={classNames} unmountOnExit>
        <Background onClick={action} />
      </CSSTransition>

      <CSSTransition in={isOpen} timeout={600} classNames={classNames} unmountOnExit>
        <Panel theme={brandConfiguration} height={height}>
          {children}
        </Panel>
      </CSSTransition>
    </>
  );
};

export default HeaderPanel;
