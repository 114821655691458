import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { useFetchCaRecommendation } from '@redux/caRecommendation/hooks';
import Loader from '@components/Loader';
import { useParams } from 'react-router-dom';
import { Container, Content } from '@pages/ProductDetailPage/ProductDetailPage.style';
import { DesktopNavBar, MobileNavBar } from '@components/NavBar';
import { Footer } from '@components/Footer';
import { LookbookProduct, Product, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import ContactModal from '@components/ContactModal';
import ContactBubble from '@components/ContactBubble';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import SidePanel from '@components/SidePanel';
import SlpFavoritesContent from '@components/SidePanel/Content/Favorites/SlpFavoritesContent';
import { useIntl } from 'react-intl';
import GucciDesktopProductDetailPage from '@pages/ProductDetailPage/components/DesktopProductDetailPage/GucciDesktopProductDetailPage';
import SlpDesktopProductDetailPage from '@pages/ProductDetailPage/components/DesktopProductDetailPage/SlpDesktopProductDetailPage';
import MobileProductDetailPage from '@pages/ProductDetailPage/components/MobileProductDetailPage';
import { SidePanels } from '@components/panels/SidePanel/SidePanels';
import { BvProductDetailPage } from '@pages/ProductDetailPage/components/BvProductDetailPage';
import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';

type IProductDetailPage = {
  recommendationId: string;
  productStyleColorCode: string;
};

const ProductDetailPage: React.FunctionComponent = () => {
  const intl = useIntl();
  const { recommendationId, productStyleColorCode } = useParams<IProductDetailPage>();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;
  const { name: brand } = brandConfiguration.constants;
  const { width: favoritesPanelWidth } = brandConfiguration.design.panel.favorites;

  const [openContactModal, setOpenContactModal] = useState(false);
  const [openFavoritesModal, setOpenFavoritesModal] = useState(false);
  const [likedProducts, setLikedProducts] = useState(0);

  const [, fetchCaRecommendation] = useFetchCaRecommendation();

  const recommendation = useSelector(getCaRecommendation);

  let selectedProduct: Product | undefined;
  if (recommendation?.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
    const products = recommendation?.products as Product[];
    selectedProduct = products.find((product) => product.styleColorCode === productStyleColorCode);
  } else if (recommendation?.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
    const products = recommendation?.products as LookbookProduct[];
    products.forEach((product) => {
      if (!selectedProduct) {
        selectedProduct = product.products.find((p) => p.styleColorCode === productStyleColorCode);
      }
    });
  }

  useEffect(() => {
    (async function fetch() {
      if (!recommendation && recommendationId) {
        await fetchCaRecommendation(recommendationId);
      }
    })();
  }, [recommendation, recommendationId, fetchCaRecommendation]);

  useEffect(() => {
    if (brand === BRAND.SLP) {
      const products = recommendation?.products as Product[];
      setLikedProducts(products.filter((p) => p.feedback && p.feedback >= 4).length);
    }
  }, [recommendation]);

  if (!recommendationId || !recommendation || !productStyleColorCode || !selectedProduct) {
    return <Loader />;
  }

  const handleOpenContactModal = () => {
    setOpenFavoritesModal(false);
    setOpenContactModal(true);
  };

  return (
    <ThemeProvider theme={{ language }}>
      <Container theme={brandConfiguration} brand={brand}>
        <SidePanels />

        <DesktopNavBar isProductDetailPage />

        <MobileNavBar isProductDetailPage />

        <Content theme={brandConfiguration}>
          <MobileProductDetailPage product={selectedProduct} recommendation={recommendation} brand={brand} />

          {brand === BRAND.GUCCI && (
            <GucciDesktopProductDetailPage product={selectedProduct} recommendation={recommendation} />
          )}

          {brand === BRAND.SLP && (
            <SlpDesktopProductDetailPage product={selectedProduct} recommendation={recommendation} />
          )}

          {brand === BRAND.BV && <BvProductDetailPage recommendation={recommendation} product={selectedProduct} />}
        </Content>

        {/* TODO link SA picture */}
        <ContactBubble
          openContact={() => setOpenContactModal(!openContactModal)}
          isContactOpen={openContactModal}
          openFavorites={() => setOpenFavoritesModal(!openFavoritesModal)}
          brand={brand}
        />

        <Footer />

        {recommendation?.advisor && (
          <ContactModal
            open={openContactModal}
            advisor={recommendation.advisor}
            action={() => setOpenContactModal(!openContactModal)}
          />
        )}

        {brand === BRAND.SLP && (
          <SidePanel
            action={() => setOpenFavoritesModal(false)}
            isOpen={openFavoritesModal}
            width={favoritesPanelWidth}
            className="panel-favorites"
            title={intl.formatMessage({ id: 'slp.nav-bar.favorites' }, { nb: likedProducts })}
          >
            {recommendation?.id && (
              <SlpFavoritesContent
                caRecommendationId={recommendation?.id}
                setOpenContactModal={handleOpenContactModal}
              />
            )}
          </SidePanel>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ProductDetailPage;
