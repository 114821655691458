import { Currency } from '@constants/currency';
import { BRAND } from '@redux/brandConfiguration/types';

const getCurrency = (currency?: string, brand?: string, country?: string): string | null => {
  switch (currency) {
    case Currency.AED: {
      switch (brand) {
        case BRAND.BV: {
          switch (country) {
            case 'KUWAIT':
            case 'SAUDI ARABIA':
              return 'د.إ';
            default:
              return 'AED';
          }
        }
        default:
          return 'AED';
      }
    }
    case Currency.AUD: {
      switch (brand) {
        case BRAND.BV:
          return 'A$';
        default:
          return 'AU$';
      }
    }
    case Currency.BRL:
      return 'R$';
    case Currency.CAD: {
      switch (brand) {
        case BRAND.BV:
          return 'CAD$';
        default:
          return 'C$';
      }
    }
    case Currency.CHF:
      return 'CHF';
    case Currency.CNY:
    case Currency.JPY:
      return '¥';
    case Currency.CZK:
      return 'Kč';
    case Currency.DKK: {
      switch (brand) {
        case BRAND.GUCCI:
          return 'kr.';
        default:
          return 'DKK';
      }
    }
    case Currency.EUR:
      return '€';
    case Currency.GBP:
      return '£';
    case Currency.HKD:
      return 'HK$';
    case Currency.HUF:
      return 'Ft';
    case Currency.INR:
      return 'INR';
    case Currency.KRW:
      return '₩';
    case Currency.KWD:
      return 'د.ك';
    case Currency.MOP:
      return 'MOP$';
    case Currency.MXN:
      return 'MXN';
    case Currency.MYR:
      return 'RM';
    case Currency.NOK:
      return 'kr';
    case Currency.NZD:
      return 'NZ$';
    case Currency.QAR:
      return 'ر.ق';
    case Currency.SAR:
      return 'SAR';
    case Currency.SEK:
      return 'kr';
    case Currency.SGD:
      return 'S$';
    case Currency.THB: {
      switch (brand) {
        case BRAND.BV:
          return '฿';
        default:
          return 'THB';
      }
    }
    case Currency.TRY:
      return '₺';
    case Currency.TWD:
      return 'NT$';
    case Currency.USD:
      return '$';
    case Currency.ZAR:
      return 'R';
    default:
      return null;
  }
};

export const getPriceWithCurrency = (
  amount: number,
  currency?: string,
  brand?: string,
  country?: string,
): string | null => {
  if (!amount) {
    return null;
  }

  const symbol = getCurrency(currency, brand, country);
  if (!symbol) {
    return `${amount}`;
  }

  switch (brand) {
    case BRAND.BV:
      return `${amount} ${symbol}`;
    default:
      return `${symbol} ${amount}`;
  }
};
