import React, { useEffect, useState } from 'react';
import HeaderLogo from '@components/HeaderLogo';
import { SLPContainer, StyledLink, StyledNavBar, StyledText } from '@components/NavBar/style';
import { generatePath, useNavigate } from 'react-router-dom';
import { PATHS } from '@routes';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import SidePanel from '@components/SidePanel';
import { FavoritesContent } from '@components/SidePanel/Content/Favorites/FavoritesContent';
import { Product, RECOMMENDATION_TYPE } from '@redux/caRecommendation/types';
import { useSidePanel } from '@components/panels/SidePanel/SidePanelContext';

interface Props {
  isProductDetailPage?: boolean;
}

export const SLPDesktopNavBar: React.FC<Props> = ({ isProductDetailPage }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  const [isFavoriteOpen, setIsFavoriteOpen] = useState(false);
  const [likedProducts, setLikedProducts] = useState(0);

  const { setContactSidePanelOpen } = useSidePanel();

  const { width: favoritesPanelWidth } = brandConfiguration.design.panel.favorites;

  useEffect(() => {
    if (caRecommendation?.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
      const products = caRecommendation.products as Product[];
      setLikedProducts(products.filter((p) => p.feedback && p.feedback >= 4).length);
    }
  }, [caRecommendation]);

  const handleOpenContactModal = () => {
    setIsFavoriteOpen(false);
    setContactSidePanelOpen(true);
  };

  return (
    <>
      <StyledNavBar id="header-desktop" theme={brandConfiguration}>
        <SLPContainer justifyContent="flex-start">
          {isProductDetailPage && (
            <StyledLink
              onClick={() =>
                navigate(generatePath(PATHS.SUGGESTION, { caRecommendationId: caRecommendation?.recommendationId }))
              }
            >
              <StyledText theme={brandConfiguration}>
                <FormattedMessage id="slp.nav-bar.back" />
              </StyledText>
            </StyledLink>
          )}
        </SLPContainer>

        <HeaderLogo />

        <SLPContainer justifyContent="flex-end">
          <StyledLink onClick={() => setIsFavoriteOpen(true)}>
            <StyledText theme={brandConfiguration}>
              <FormattedMessage id="slp.nav-bar.favorites" values={{ nb: likedProducts }} />
            </StyledText>
          </StyledLink>

          <StyledLink onClick={() => setContactSidePanelOpen(true)}>
            <StyledText theme={brandConfiguration}>
              <FormattedMessage id="slp.nav-bar.contact-advisor" />
            </StyledText>
          </StyledLink>
        </SLPContainer>
      </StyledNavBar>

      <SidePanel
        action={() => setIsFavoriteOpen(false)}
        isOpen={isFavoriteOpen}
        width={favoritesPanelWidth}
        className="panel-favorites"
        title={intl.formatMessage({ id: 'slp.nav-bar.favorites' }, { nb: likedProducts })}
      >
        {caRecommendation?.id && (
          <FavoritesContent caRecommendationId={caRecommendation?.id} setOpenContactModal={handleOpenContactModal} />
        )}
      </SidePanel>
    </>
  );
};
