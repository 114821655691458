import React from 'react';
import styled, { css } from 'styled-components';
import { onLargeScreen, onSmallScreen } from '@stylesheet';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const CommonIntroContainer = styled.div`
  ${onLargeScreen(css`
    min-height: ${(props) => props.theme.design.pages.productsList.introduction.container.minHeight};
  `)}
  width: 100%;
  display: grid;
  box-sizing: border-box;
  overflow: hidden;
  font-family: ${(props) => props.theme.design.fonts.introduction};
  ${onSmallScreen(css`
    grid-template-columns: auto;
  `)}
`;

const BVIntroContainer = styled.div``;

type Props = {
  children: React.ReactNode;
};

export const IntroContainer: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const IntroContainer = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return BVIntroContainer;
      default:
        return CommonIntroContainer;
    }
  })();

  return <IntroContainer theme={brandConfiguration}>{children}</IntroContainer>;
};
