export enum Currency {
  AED = 'AED',
  AUD = 'AUD',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  INR = 'INR',
  JPY = 'JPY',
  KRW = 'KRW',
  KWD = 'KWD',
  MOP = 'MOP',
  MXN = 'MXN',
  MYR = 'MYR',
  NZD = 'NZD',
  SAR = 'SAR',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  CNY = 'CNY',
  CZK = 'CZK',
  HUF = 'HUF',
  NOK = 'NOK',
  ZAR = 'ZAR',
  TRY = 'TRY',
  QAR = 'QAR',
  TWD = 'TWD',
  USD = 'USD',
}
