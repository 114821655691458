import React, { useEffect, useRef, useState } from 'react';
import { getPriceWithCurrency } from '@services/product/productService';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { CARecommendation, Product } from '@redux/caRecommendation/types';
import { FormattedMessage } from 'react-intl';
import { useSidePanel } from '@components/panels/SidePanel/SidePanelContext';
import LikeButton from '@components/LikeButton';
import Functions from '@utils/Functions';
import {
  AdvisorButton,
  BackButton,
  BackIcon,
  BackText,
  ButtonsContainer,
  BuyLink,
  Container,
  Description,
  DesktopLeftContainer,
  DotPoint,
  DotPointContainer,
  Dots,
  FirstLineHeaderContainer,
  HeaderContainer,
  ImageContainer,
  InfoContainer,
  MobileLeftContainer,
  ProductDetailIcon,
  ProductDetailImage,
  ProductDetailName,
  ProductDetailPrice,
  RightContainer,
  MainInfoContainer,
  ContentInfoContainer,
} from '@pages/ProductDetailPage/components/BvProductDetailPage.style';
import { useNavigate } from 'react-router-dom';
import MobileCarousel from '@components/MobileCarousel';
import { loadImage } from '@pages/ProductDetailPage/components/DesktopProductDetailPage/GucciDesktopProductDetailPage';
import Loader from '@components/Loader';

interface Props {
  recommendation: CARecommendation;
  product: Product;
}

export const BvProductDetailPage: React.FC<Props> = ({ recommendation, product }) => {
  const navigate = useNavigate();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const { setContactSidePanelOpen } = useSidePanel();
  const imagesContainerRef = useRef<HTMLDivElement>(null);
  const [loadedImageURLs, setLoadedImageURLs] = useState<{ isLoaded: boolean; urls: string[] }>({
    isLoaded: false,
    urls: [],
  });

  const isLiked = Functions.isLikedProduct(product);

  const handleDotClick = (index: number): void => {
    if (imagesContainerRef.current) {
      const targetImage = imagesContainerRef.current.children[index];

      if (targetImage) {
        const targetImageTop = targetImage.getBoundingClientRect().top;
        const headerHeight = document.getElementById('header-desktop')?.getBoundingClientRect().height || 0;

        const top = targetImageTop + window.scrollY - headerHeight;

        window.scrollTo({
          top,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    Promise.allSettled(product.externalMediaUrls.map((url) => loadImage(url))).then((results) => {
      setLoadedImageURLs({
        isLoaded: true,
        urls: results.filter((result) => result.status === 'fulfilled').map((result) => result.value),
      });
    });
  }, [product]);

  if (!loadedImageURLs.isLoaded) {
    return <Loader />;
  }

  return (
    <Container>
      <DesktopLeftContainer ref={imagesContainerRef}>
        {loadedImageURLs.urls.map((url, imageIndex) => (
          <ImageContainer>
            <ProductDetailImage src={url} />

            <Dots>
              {loadedImageURLs.urls.map((_, dotIndex) => {
                const isActive = imageIndex === dotIndex;

                return (
                  <DotPointContainer
                    onClick={!isActive ? () => handleDotClick(dotIndex) : undefined}
                    className={isActive ? 'active' : undefined}
                  >
                    <DotPoint />
                  </DotPointContainer>
                );
              })}
            </Dots>
          </ImageContainer>
        ))}
      </DesktopLeftContainer>

      <MobileLeftContainer>
        <MobileCarousel dots infinite>
          {loadedImageURLs.urls.map((url) => (
            <ProductDetailImage src={url} />
          ))}
        </MobileCarousel>
      </MobileLeftContainer>

      <RightContainer>
        <InfoContainer>
          <MainInfoContainer>
            <HeaderContainer>
              <FirstLineHeaderContainer>
                <ProductDetailName>{product.name}</ProductDetailName>

                <ProductDetailIcon>
                  <LikeButton
                    caRecommendationId={recommendation.id}
                    styleColorCode={product.styleColorCode}
                    initialIsLiked={isLiked}
                  />
                </ProductDetailIcon>
              </FirstLineHeaderContainer>

              <ProductDetailPrice>
                {getPriceWithCurrency(
                  product.priceAmount,
                  product.priceCurrency,
                  brandConfiguration.constants.name,
                  recommendation.country,
                )}
              </ProductDetailPrice>
            </HeaderContainer>

            <ButtonsContainer>
              <BuyLink href={product.url} target="_blank" rel="noreferrer">
                <FormattedMessage id="bv.product-details.visit-website" />
              </BuyLink>

              <AdvisorButton onClick={() => setContactSidePanelOpen(true)}>
                <FormattedMessage id="bv.nav-bar.contact-advisor" />
              </AdvisorButton>
            </ButtonsContainer>
          </MainInfoContainer>

          <ContentInfoContainer>
            {product.shortDescription && <Description>{product.shortDescription}</Description>}

            <Description>
              {product.description}

              {product.madeIn && (
                <>
                  <br />

                  {'• '}
                  <FormattedMessage
                    id="bv.product-details.made-in"
                    values={{
                      country: <FormattedMessage id={`bv.country.${product.madeIn}`} />,
                    }}
                  />
                </>
              )}

              <br />

              {'• '}
              <FormattedMessage
                id="bv.product-details.style-code"
                values={{
                  styleCode: product.styleColorCode,
                }}
              />
            </Description>
          </ContentInfoContainer>
        </InfoContainer>
      </RightContainer>

      <BackButton onClick={() => navigate(`/suggestion/${recommendation.id}`)}>
        <BackIcon />

        <BackText>
          <FormattedMessage id="bv.nav-bar.back" />
        </BackText>
      </BackButton>
    </Container>
  );
};
