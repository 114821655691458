import React from 'react';
import { BVFooterContainer, BVFooterLinkSection } from '@components/Footer/style';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { links } from '@constants/links';
import { FormattedMessage } from 'react-intl';

export const BVFooter: React.FC = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const { bvSite, bvClientService, bvBespokeService, bvLegal } = links;

  return (
    <BVFooterContainer theme={brandConfiguration}>
      <BVFooterLinkSection>
        <a aria-label="bottega veneta website navigation" target="_blank" href={bvSite} rel="noreferrer">
          <FormattedMessage id="bv.footer.website" />
        </a>
      </BVFooterLinkSection>

      <BVFooterLinkSection>
        <a aria-label="saint laurent paris client service" target="_blank" href={bvClientService} rel="noreferrer">
          <FormattedMessage id="bv.footer.client-service" />
        </a>
      </BVFooterLinkSection>

      <BVFooterLinkSection>
        <a aria-label="saint laurent paris bespoke service" target="_blank" href={bvBespokeService} rel="noreferrer">
          <FormattedMessage id="bv.footer.bespoke-service" />
        </a>
      </BVFooterLinkSection>

      <BVFooterLinkSection>
        <a aria-label="saint laurent paris legal" target="_blank" href={bvLegal} rel="noreferrer">
          <FormattedMessage id="bv.footer.legal-notices" />
        </a>
      </BVFooterLinkSection>
    </BVFooterContainer>
  );
};
