import React from 'react';
import {
  FooterDisclaimerSection,
  FooterDownloadAppSection,
  FooterLinksContainer,
  FooterLinksSection,
  FooterSocialsContainer,
  FooterSocialsItem,
  FooterSocialsSection,
  SaintLaurentFooterContainer,
} from '@components/Footer/style';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { FormattedMessage } from 'react-intl';
import { links } from '@constants/links';

export const SLPFooter: React.FC = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const {
    facebook: FacebookLogo,
    instagram: InstagramLogo,
    x: XLogo,
    tiktok: TiktokLogo,
    youtube: YoutubeLogo,
  } = brandConfiguration.design.logos;

  const { slpSite, slpAccessibility, slpAndroidApp, slpIosApp, slpClientService, slpLegalNotice, slpNewsletter } =
    links;

  return (
    <SaintLaurentFooterContainer theme={brandConfiguration}>
      <FooterLinksContainer theme={brandConfiguration}>
        <FooterLinksSection theme={brandConfiguration}>
          <a aria-label="saint laurent paris website navigation" target="_blank" href={slpSite} rel="noreferrer">
            <FormattedMessage id="slp.footer.website" />
          </a>
          <a aria-label="saint laurent paris client service" target="_blank" href={slpClientService} rel="noreferrer">
            <FormattedMessage id="slp.footer.client-service" />
          </a>
        </FooterLinksSection>

        <FooterLinksSection theme={brandConfiguration}>
          <a aria-label="saint laurent paris legal notice" target="_blank" href={slpLegalNotice} rel="noreferrer">
            <FormattedMessage id="slp.footer.legal-notices" />
          </a>
          <a aria-label="saint laurent paris accessibility" target="_blank" href={slpAccessibility} rel="noreferrer">
            <FormattedMessage id="slp.footer.accessibility" />
          </a>
        </FooterLinksSection>

        <FooterLinksSection theme={brandConfiguration}>
          <a
            aria-label="saint laurent paris newsletter subscribtion"
            target="_blank"
            href={slpNewsletter}
            rel="noreferrer"
          >
            <FormattedMessage id="slp.footer.subscribe" />
          </a>
        </FooterLinksSection>

        <FooterDownloadAppSection theme={brandConfiguration}>
          <a aria-label="saint laurent paris dowload ios app" target="_blank" href={slpIosApp} rel="noreferrer">
            <FormattedMessage id="slp.footer.download-ios" />
          </a>
          <a aria-label="saint laurent paris dowload android app" target="_blank" href={slpAndroidApp} rel="noreferrer">
            <FormattedMessage id="slp.footer.download-android" />
          </a>
        </FooterDownloadAppSection>
      </FooterLinksContainer>

      <FooterSocialsSection>
        <FooterSocialsContainer>
          {FacebookLogo && (
            <FooterSocialsItem href="https://www.facebook.com/ysl " target="_blank">
              <FacebookLogo />
            </FooterSocialsItem>
          )}
          {XLogo && (
            <FooterSocialsItem href="https://www.twitter.com/ysl" target="_blank">
              <XLogo />
            </FooterSocialsItem>
          )}
          {InstagramLogo && (
            <FooterSocialsItem href="https://www.instagram.com/ysl/" target="_blank">
              <InstagramLogo />
            </FooterSocialsItem>
          )}
          {YoutubeLogo && (
            <FooterSocialsItem href="https://www.youtube.com/ysl" target="_blank">
              <YoutubeLogo />
            </FooterSocialsItem>
          )}
          {TiktokLogo && (
            <FooterSocialsItem href="https://www.tiktok.com/@ysl" target="_blank">
              <TiktokLogo />
            </FooterSocialsItem>
          )}
        </FooterSocialsContainer>
      </FooterSocialsSection>

      <FooterDisclaimerSection theme={brandConfiguration}>
        <FormattedMessage id="slp.footer.disclaimer" values={{ date: new Date().toDateString() }} />
      </FooterDisclaimerSection>
    </SaintLaurentFooterContainer>
  );
};
