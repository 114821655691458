import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import styled, { css } from 'styled-components';
import { onLargeScreen } from '@stylesheet';
import { ReactComponent as DefaultCloseIcon } from '@assets/close.svg';
import { ReactComponent as BVCloseIcon } from '@assets/icons/close/close-bv.svg';

interface Props {
  size?: string;
  mobileSize?: string;
  onClick?: () => void;
  className?: string;
}

const CloseButton: React.FunctionComponent<Props> = ({ size = '24px', mobileSize = '16px', onClick, className }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  let CloseIconSource;
  switch (brandConfiguration.constants.name) {
    case BRAND.BV:
      CloseIconSource = BVCloseIcon;
      break;
    default:
      CloseIconSource = DefaultCloseIcon;
  }

  const CloseIcon = styled(CloseIconSource)`
    cursor: pointer;
    width: ${mobileSize};
    height: ${mobileSize};

    ${onLargeScreen(css`
      width: ${size};
      height: ${size};
    `)}
  `;

  return <CloseIcon className={className} onClick={onClick} />;
};

export default CloseButton;
