import styled, { css } from 'styled-components';
import { onLargeScreen } from '@stylesheet';

const HeaderLinksGroup = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 20px;

  ${onLargeScreen(css`
    padding-right: 70px;
  `)}
`;

export default HeaderLinksGroup;
