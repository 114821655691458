import styled, { css } from 'styled-components';
import { colorUsage, font, fontSize, fontWeight, getSpacing, lineHeight, onSmallScreen } from '@stylesheet';

import { ReactComponent as LeftChevronIcon } from '@assets/chevron_left.svg';
import theme from 'styled-theming';
import BaseButton from '@components/Button/Button';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.design.fonts.main};
  height: auto;

  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const CarouselAndImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ImageAndSliderContainer = styled.div`
  margin: 0;
  width: 100%;
  max-width: 100vw;
  background-color: ${colorUsage.background};
  padding-top: 10vw;
  padding-bottom: 8vw;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const PriceContainer = styled.div`
  background-color: ${colorUsage.white};
  width: 20vw;
  height: auto;
  position: absolute;
  padding: ${getSpacing(3)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PriceContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProductInfoContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: ${getSpacing(6)};
  margin-right: ${getSpacing(6)};
`;

export const ProductName = styled.h1`
  font-weight: ${fontWeight.bold};
  ${font.Large};
  text-align: center;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductTitle = styled.h1`
  font-weight: ${fontWeight.bold};
  ${font.Large};
  text-align: left;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductPrice = styled.h2`
  font-weight: ${fontWeight.bold};
  ${font.Large};
  text-align: center;
  margin-top: ${getSpacing(2)}
    ${theme('language', {
      ar: css`
        text-align: right;
        direction: rtl;
      `,
    })};
`;

export const Button = styled(BaseButton)`
  margin-top: ${getSpacing(2)};
  max-width: none;
`;

const sharedCss = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.mediumLarge};
`;

export const ProductDescriptionContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ProductDescription = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.mediumLarge};
  width: 50%;
  margin: ${getSpacing(3)} 0 ${getSpacing(5)} 0;
  text-align: justify;
  white-space: break-spaces;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductDescriptionLeft = styled(ProductDescription)`
  padding-right: ${getSpacing(4)};
`;

export const ProductDescriptionRight = styled(ProductDescription)`
  padding-left: ${getSpacing(4)};
`;

export const Text = styled.div`
  ${sharedCss};
  margin-top: ${getSpacing(1)};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductHeaderContainer = styled.div`
  padding-top: ${getSpacing(2)};
  background-color: ${colorUsage.background};
  width: 100%;
`;

export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${getSpacing(4)};
  padding-right: ${getSpacing(4)};
`;

export const ProductHeaderItem = styled.div`
  text-decoration: underline;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
`;

export const GoBackContainer = styled.div`
  display: flex;
  color: ${colorUsage.black};

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
  &:hover {
    cursor: pointer;
  }
`;

export const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  margin-top: auto;
  margin-bottom: auto;
  fill: ${colorUsage.black};
`;

export const GoBackText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  padding-left: 8px;
  padding-top: 4px;
`;

export const DesktopGoBackContainer = styled.div`
  position: fixed;
  top: 100px;
  left: 32px;
  z-index: 10;
  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const ParallaxContainer = styled.div`
  background-color: ${colorUsage.slpProductBackground};
  font-family: ${(props) => props.theme.design.fonts.main};
  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const ParallaxImageContainer = styled.div`
  display: flex;
  height: 100vh;
  max-height: 100vh;
  width: 50%;
`;

export const ParallaxImageItem = styled.img`
  width: auto;
  height: 90%;
  margin: auto;
`;

export const ParallaxImagePlaceholderItem = styled.img`
  width: 10%;
  height: auto;
  margin: auto;
`;

export const ParallaxOverlayContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ParallaxOverlayContent = styled.div`
  width: 50%;
  height: 100vh;
  display: flex;
`;

export const ParallaxCountContainer = styled.div`
  margin: auto;
  margin-left: 32px;

  div + div {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px solid ${colorUsage.black};
  }
`;

export const ParallaxInformationsContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const ParallaxInformationsContent = styled.div`
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ParallaxInformationsTitleAndPrice = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParallaxInformationsTitle = styled.div`
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  font-weight: bold;
  text-transform: uppercase;
`;

export const ParallaxInformationsPrice = styled.div`
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  text-transform: uppercase;
`;

export const ParallaxInformationsText = styled.div`
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  text-transform: uppercase;
  white-space: pre-wrap;
`;

export const ParallaxInformationsLink = styled.div`
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  text-transform: uppercase;
  display: flex;

  p {
    margin-top: auto;
    margin-bottom: auto;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  svg {
    scale: 0.5;
  }
`;

export const ParallaxButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ParallaxAddToFavoritesButton = styled(BaseButton)`
  width: 100%;
  height: 36px;
  max-width: 100%;
  text-transform: uppercase;
  font-size: ${fontSize.XSmall};
  font-weight: ${fontWeight.normal};
`;

export const ParallaxVisitWebsiteButton = styled(BaseButton)`
  width: 100%;
  height: 36px;
  max-width: 100%;
  text-transform: uppercase;
  font-size: ${fontSize.XSmall};
  font-weight: ${fontWeight.normal};
  background-color: transparent;
  border: solid 1px ${colorUsage.black};
  color: ${colorUsage.black};
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
`;

export const MoreDetailsPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
`;

export const MoreDetailsPanelContent = styled.div<{ width: string }>`
  margin: auto;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  text-transform: uppercase;
  font-size: ${fontSize.XSmall};
  font-weight: ${fontWeight.normal};
  line-height: ${fontSize.medium};
  font-family: ${(props) => props.theme.design.fonts.main};
  gap: 32px;
  white-space: pre-wrap;

  ul {
    padding-left: ${fontSize.XSmall};
    width: 100%;

    li {
      white-space: pre-wrap;
    }
  }
`;

export const MonoImageProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100vh;
`;

export const MonoImageContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
