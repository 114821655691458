import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
/* Language polyfills needed for IE11, Edge, Safari 12 & 13 support
https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis
*/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
/* End of language polyfills */
import { useSelector } from 'react-redux';
import { getLanguage } from '@redux/language/selectors';
import { SidePanelProvider } from '@components/panels/SidePanel/SidePanelContext';
import StyledMessages from '@components/StyledMessages';

interface Props {
  children: ReactNode;
}

const Root: React.FunctionComponent<Props> = ({ children }) => {
  const currentLanguage = useSelector(getLanguage).language;

  return (
    <IntlProvider locale={currentLanguage.locale} messages={currentLanguage.message}>
      <StyledMessages>
        <SidePanelProvider>{children}</SidePanelProvider>
      </StyledMessages>
    </IntlProvider>
  );
};

export default Root;
