import React from 'react';
import styled, { css } from 'styled-components';
import { onSmallScreen } from '@stylesheet';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { getLanguage } from '@redux/language/selectors';

const DefaultHello = styled.p<{ language: string }>`
  direction: ${(props) => (props.language === 'ar' ? 'rtl' : 'ltr')};
`;

const CommonHello = styled(DefaultHello)`
  font-size: ${(props) => props.theme.design.pages.productsList.introduction.hello.fontSize};
  font-weight: ${(props) => props.theme.design.pages.productsList.introduction.hello.fontWeight};
  line-height: ${(props) => props.theme.design.pages.productsList.introduction.hello.lineHeight};
  font-style: ${(props) => props.theme.design.pages.productsList.introduction.hello.fontStyle};
  color: ${(props) => props.theme.design.pages.productsList.introduction.hello.color};
  text-transform: ${(props) => props.theme.design.pages.productsList.introduction.hello.textTransform};
  ${onSmallScreen(css`
    line-height: ${(props) => props.theme.design.pages.productsList.introduction.hello.mobile.lineHeight};
    font-size: ${(props) => props.theme.design.pages.productsList.introduction.selection.mobile.fontSize};
  `)}
`;

type Props = {
  children: React.ReactNode;
};

export const Hello: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const Hello = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return DefaultHello;
      default:
        return CommonHello;
    }
  })();

  return (
    <Hello theme={brandConfiguration} language={language}>
      {children}
    </Hello>
  );
};
