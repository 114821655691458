import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { colorUsage, getSpacing, onLargeScreen } from '@stylesheet';
import theme from 'styled-theming';

const DefaultGoBackContainer = styled.div`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const GGGoBackContainer = styled(DefaultGoBackContainer)`
  color: ${colorUsage.black};
  padding: ${getSpacing(10)} ${getSpacing(6)} ${getSpacing(4)} ${getSpacing(1)};
  gap: 16px;

  ${onLargeScreen(css`
    padding: ${getSpacing(10)} ${getSpacing(2)} ${getSpacing(4)} ${getSpacing(5)};
  `)};
`;

const BVGoBackContainer = styled(DefaultGoBackContainer)`
  gap: 16px;
  height: 30px;
  align-items: center;
`;

interface Props {
  children: ReactNode;
  onClick: () => void;
}

export const GoBackContainer: React.FC<Props> = ({ children, onClick }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const GoBackContainer = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return GGGoBackContainer;
      case BRAND.BV:
        return BVGoBackContainer;
      default:
        return null;
    }
  })();

  return GoBackContainer && <GoBackContainer onClick={onClick}>{children}</GoBackContainer>;
};
