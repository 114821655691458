import styled from 'styled-components';
import { fontSize, fontWeight } from '@stylesheet';

export const SidePanelHeaderContainer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.XSmall};
`;

export const SidePanelHeaderElement = styled.div`
  width: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
