import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colorUsage, font, fontSize, fontWeight, getSpacing, onLargeScreen } from '@stylesheet';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import theme from 'styled-theming';
import { FormattedMessage } from 'react-intl';

const GGTitle = styled.h2`
  font-weight: ${fontWeight.bold};
  ${font.XLarge}

  padding: 0 ${getSpacing(6)} ${getSpacing(4)} ${getSpacing(1)};

  ${onLargeScreen(css`
    padding: 0 ${getSpacing(2)} ${getSpacing(4)} ${getSpacing(5)};
  `)}
`;

const BVTitle = styled.h2`
  font-size: ${fontSize.mediumLarge};
  line-height: 27px;
  font-weight: ${fontWeight.normal};
`;

interface Props {
  nbProducts: number;
}

export const Title: React.FC<Props> = ({ nbProducts }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  switch (brandConfiguration.constants.name) {
    case BRAND.GUCCI:
      return (
        <GGTitle>
          <FormattedMessage
            id={
              nbProducts
                ? `${brandConfiguration.constants.name.toLowerCase()}.liked-products.title`
                : `${brandConfiguration.constants.name.toLowerCase()}.liked-products.no-products`
            }
            values={{ linebreak: <br /> }}
          />
        </GGTitle>
      );
    case BRAND.BV:
      return (
        <BVTitle>
          <FormattedMessage id={`${brandConfiguration.constants.name.toLowerCase()}.liked-products.title`} />
        </BVTitle>
      );
    default:
      return null;
  }
};
