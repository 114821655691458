import arMessagesGg from '@translations/gg/ar.json';
import deMessagesGg from '@translations/gg/de.json';
import enMessagesGg from '@translations/gg/en.json';
import esMessagesGg from '@translations/gg/es.json';
import esmxMessagesGg from '@translations/gg/esmx.json';
import frMessagesGg from '@translations/gg/fr.json';
import itMessagesGg from '@translations/gg/it.json';
import cnMessagesGg from '@translations/gg/cn.json';
import jpMessagesGg from '@translations/gg/jp.json';
import krMessagesGg from '@translations/gg/kr.json';
import thMessagesGg from '@translations/gg/th.json';
import arMessagesSlp from '@translations/slp/ar.json';
import deMessagesSlp from '@translations/slp/de.json';
import enMessagesSlp from '@translations/slp/en.json';
import esMessagesSlp from '@translations/slp/es.json';
import esmxMessagesSlp from '@translations/slp/esmx.json';
import frMessagesSlp from '@translations/slp/fr.json';
import itMessagesSlp from '@translations/slp/it.json';
import cnMessagesSlp from '@translations/slp/cn.json';
import jpMessagesSlp from '@translations/slp/jp.json';
import krMessagesSlp from '@translations/slp/kr.json';
import thMessagesSlp from '@translations/slp/th.json';
import arMessagesBv from '@translations/bv/ar.json';
import deMessagesBv from '@translations/bv/de.json';
import enMessagesBv from '@translations/bv/en.json';
import esMessagesBv from '@translations/bv/es.json';
import esmxMessagesBv from '@translations/bv/esmx.json';
import frMessagesBv from '@translations/bv/fr.json';
import itMessagesBv from '@translations/bv/it.json';
import cnMessagesBv from '@translations/bv/cn.json';
import jpMessagesBv from '@translations/bv/jp.json';
import krMessagesBv from '@translations/bv/kr.json';
import thMessagesBv from '@translations/bv/th.json';

const enMessages = { ...enMessagesGg, ...enMessagesSlp, ...enMessagesBv };

export const locales = {
  ar: { ...enMessages, ...arMessagesGg, ...arMessagesSlp, ...arMessagesBv },
  de: { ...enMessages, ...deMessagesGg, ...deMessagesSlp, ...deMessagesBv },
  en: enMessages,
  es: { ...enMessages, ...esMessagesGg, ...esMessagesSlp, ...esMessagesBv },
  esmx: { ...enMessages, ...esmxMessagesGg, ...esmxMessagesSlp, ...esmxMessagesBv },
  fr: { ...enMessages, ...frMessagesGg, ...frMessagesSlp, ...frMessagesBv },
  it: { ...enMessages, ...itMessagesGg, ...itMessagesSlp, ...itMessagesBv },
  cn: { ...enMessages, ...cnMessagesGg, ...cnMessagesSlp, ...cnMessagesBv },
  jp: { ...enMessages, ...jpMessagesGg, ...jpMessagesSlp, ...jpMessagesBv },
  kr: { ...enMessages, ...krMessagesGg, ...krMessagesSlp, ...krMessagesBv },
  th: { ...enMessages, ...thMessagesGg, ...thMessagesSlp, ...thMessagesBv },
};
