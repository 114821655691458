import React from 'react';
import { FooterContainer } from '@components/Footer/style';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { GGFooter } from '@components/Footer/GGFooter';
import { SLPFooter } from '@components/Footer/SLPFooter';
import { BVFooter } from '@components/Footer/BVFooter';
import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';

export const Footer: React.FunctionComponent = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  return (
    <ThemeProvider theme={{ language }}>
      <FooterContainer theme={brandConfiguration}>
        {(() => {
          switch (brandConfiguration.constants.name) {
            case BRAND.GUCCI:
              return <GGFooter />;
            case BRAND.SLP:
              return <SLPFooter />;
            case BRAND.BV:
              return <BVFooter />;
          }
        })()}
      </FooterContainer>
    </ThemeProvider>
  );
};
