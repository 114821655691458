import MobileCarousel from '@components/MobileCarousel';
import SidePanel from '@components/SidePanel';
import {
  MoreDetailsPanelContainer,
  MoreDetailsPanelContent,
} from '@pages/ProductDetailPage/components/DesktopProductDetailPage/DesktopProductDetailPage.style';

import {
  CarouselContainer,
  Container,
  MobileCarouselImg,
  ProductDescription,
  ProductInfoContainer,
  Slide,
  SlpAddToFavoritesButton,
  SlpButtons,
  SlpCategoryAndPrice,
  SlpLink,
  SlpText,
  SlpTitle,
  SlpVisitWebsiteButton,
} from '@pages/ProductDetailPage/components/MobileProductDetailPage/MobileProductDetailPage.style';
import { BrandConfiguration } from '@redux/brandConfiguration/types';
import { useUpdateFeedback } from '@redux/caRecommendation/hooks';
import { CARecommendation, Product } from '@redux/caRecommendation/types';
import { getPriceWithCurrency } from '@services/product/productService';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  product: Product;
  recommendation: CARecommendation;
  brandConfiguration: BrandConfiguration;
  initialLiked: boolean;
  part1: string;
  // eslint-disable-next-line no-undef
  bulletPoints: JSX.Element[];
}

const SlpMobileContent: React.FunctionComponent<Props> = ({
  product,
  recommendation,
  brandConfiguration,
  initialLiked,
  part1,
  bulletPoints,
}) => {
  const [isLiked, setIsLiked] = useState<boolean>(initialLiked);
  const [openMoreDetails, setOpenMoreDetails] = useState(false);

  const { arrow: ArrowIcon } = brandConfiguration.design.logos;

  const [, updateFeedback] = useUpdateFeedback();

  const giveFeedback = async (feedback: number | null) => {
    await updateFeedback(recommendation.id, product.styleColorCode, feedback);
  };

  const like = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(true);
    await giveFeedback(4);
  };
  const unLike = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLiked(false);
    await giveFeedback(null);
  };

  return (
    <Container theme={brandConfiguration}>
      <CarouselContainer marginTop="32px" paddingBottom="64px">
        <MobileCarousel dots>
          {product.externalMediaUrls.map((url) => (
            <Slide key={url}>
              <MobileCarouselImg src={url} alt="product" />
            </Slide>
          ))}
        </MobileCarousel>
      </CarouselContainer>

      <ProductInfoContainer theme={brandConfiguration}>
        <SlpTitle>{product.name}</SlpTitle>
        <SlpCategoryAndPrice>
          <SlpText>{product.color}</SlpText>
          <SlpText>
            {getPriceWithCurrency(
              product.priceAmount,
              product.priceCurrency,
              brandConfiguration.constants.name,
              recommendation.country,
            )}
          </SlpText>
        </SlpCategoryAndPrice>

        <ProductDescription>
          <SlpText>{part1}</SlpText>
          <SlpButtons>
            <SlpAddToFavoritesButton theme={brandConfiguration} onClick={isLiked ? unLike : like}>
              <FormattedMessage
                id={isLiked ? 'slp.product-details.remove-from-favorites' : 'slp.product-details.add-to-favorites'}
              />
            </SlpAddToFavoritesButton>
            <a href={product.url} target="_blank" rel="noreferrer">
              <SlpVisitWebsiteButton theme={brandConfiguration}>
                <FormattedMessage id="slp.product-details.visit-website" />
              </SlpVisitWebsiteButton>
            </a>
          </SlpButtons>
          <SlpLink onClick={() => setOpenMoreDetails(true)}>
            <p>
              <FormattedMessage id="slp.product-details.more-details" />
            </p>
            {ArrowIcon && <ArrowIcon />}
          </SlpLink>
        </ProductDescription>
      </ProductInfoContainer>

      <SidePanel
        action={() => setOpenMoreDetails(false)}
        isOpen={openMoreDetails}
        width="100%"
        className="panel-favorites"
        title=""
      >
        <MoreDetailsPanelContainer>
          <MoreDetailsPanelContent width="90%" theme={brandConfiguration}>
            {part1}
            <ul>
              {bulletPoints}
              {product.styleColorCode && (
                <li>
                  <FormattedMessage id="slp.product-details.style-code" values={{ value: product.styleColorCode }} />
                </li>
              )}
              {product.skus && product.skus.length > 0 && (
                <li>
                  <FormattedMessage id="slp.product-details.sku" values={{ value: product.skus[0].sku }} />
                </li>
              )}
            </ul>
          </MoreDetailsPanelContent>
        </MoreDetailsPanelContainer>
      </SidePanel>
    </Container>
  );
};

export default SlpMobileContent;
