import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { onLargeScreen, onSmallScreen } from '@stylesheet';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const GGProductsContainer = styled.div`
  width: 100%;
  display: grid;
  box-sizing: border-box;
  color: white;

  ${onSmallScreen(css`
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
  `)}

  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr;
  `)}
`;

const BVProductsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;

  ${onLargeScreen(css`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `)}
`;

interface Props {
  children: ReactNode;
}

export const ProductsContainer: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const ProductsContainer = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return GGProductsContainer;
      case BRAND.BV:
        return BVProductsContainer;
      default:
        return null;
    }
  })();

  return ProductsContainer && <ProductsContainer>{children}</ProductsContainer>;
};
