import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colorUsage, onLargeScreen } from '@stylesheet';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const GGLikedProductsContainer = styled.div`
  background-color: ${colorUsage.white};
  height: 100%;
  width: 100%;

  ${onLargeScreen(css`
    padding-top: 20px;
  `)}
`;

const BVLikedProductsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 40px 16px 64px 16px;
  box-sizing: border-box;

  ${onLargeScreen(css`
    padding: 32px 24px 80px 24px;
  `)}
`;

interface Props {
  children: ReactNode;
}

export const LikedProductsContainer: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const LikedProductsContainer = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return GGLikedProductsContainer;
      case BRAND.BV:
        return BVLikedProductsContainer;
      default:
        return null;
    }
  })();

  return LikedProductsContainer && <LikedProductsContainer>{children}</LikedProductsContainer>;
};
