import styled from 'styled-components';
import { colorUsage, getSpacing } from '@stylesheet';

export const StyledCarousel = styled.div<{
  hasToSlideToShowProducts: boolean;
}>`
  margin-right: ${getSpacing(1)};
  .slick-slider {
    width: ${getSpacing(70)};
    min-width: ${getSpacing(70)};
    .slick-slide {
      width: ${getSpacing(17.5)} !important;
      box-sizing: border-box;
      border: none;
    }
  }

  .slick-next {
    top: ${getSpacing(-40)};
    right: ${getSpacing(2)};
  }

  .slick-prev {
    top: ${getSpacing(-40)};
    left: ${getSpacing(2)};
  }
`;

export const DoubleCarouselContainer = styled.div`
  width: 100%;
  max-width: 100vw;
  margin: auto;
  background-color: ${colorUsage.background};

  .slick-track {
    display: flex;
  }

  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slick-next {
    right: 20px;
    z-index: 1;
  }

  .slick-prev {
    left: 20px;
    z-index: 1;
  }
`;
export const DoubleCarouselSlide = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${colorUsage.background};
  display: flex;
`;

export const DoubleCarouselSlideImg = styled.img`
  margin: auto;
  display: flex;
  width: 50%;
  object-fit: cover;
`;
