import styled, { css } from 'styled-components';
import { fontWeight, onLargeScreen } from '@stylesheet';
import LikeButton from '@components/LikeButton';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr 64px;
  height: 280px;
  cursor: pointer;
  position: relative;

  ${onLargeScreen(css`
    height: 420px;

    &:not(:hover) .product-favorite-empty {
      display: none;
    }
  `)}
`;

export const ImageContainer = styled.div`
  position: relative;
  background-color: #f1f3f2;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;

  &:not([src]) {
    display: none;
  }
`;

export const ProductInfoContainer = styled.div`
  padding: 6px;
  box-sizing: border-box;
  background-color: #f1f3f2;
  align-content: flex-end;

  ${onLargeScreen(css`
    height: 67px;
  `)}
`;

export const ProductInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 34px;
  text-transform: uppercase;
  color: #767676;
`;

export const ProductNameContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ProductName = styled.p`
  font-weight: ${fontWeight.normal};
  font-size: 13px;
  line-height: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const ProductPrice = styled.p`
  font-weight: ${fontWeight.normal};
  font-size: 13px;
  line-height: 17px;
`;

export const Favorite = styled(LikeButton)`
  position: absolute;
  top: 6px;
  right: 6px;

  svg {
    --fill: #767676;
    --stroke: #767676;
  }
`;
