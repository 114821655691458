import React from 'react';
import { CARecommendation } from '@redux/caRecommendation/types';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { getLanguage } from '@redux/language/selectors';
import { ThemeProvider } from 'styled-components/macro';
import { IntroContainer, StyledMessages } from '@pages/Suggestion/components/ProductList/Introduction/components';
import styled from 'styled-components';
import { Greetings } from '@pages/Suggestion/components/ProductList/Introduction/Greetings/Greetings';
import SocialContactGroup from '@components/SocialContactGroup';

const BVIntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface Props {
  isDesktopVersion: boolean;
}

const Introduction: React.FC<Props> = ({ isDesktopVersion }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const IntroductionContent: React.FC = () => (
    <>
      <Greetings />

      <SocialContactGroup isDesktopVersion={isDesktopVersion} />
    </>
  );

  return (
    <ThemeProvider theme={{ language }}>
      <IntroContainer>
        <StyledMessages>
          {(() => {
            switch (brandConfiguration.constants.name) {
              case BRAND.BV:
                return (
                  <BVIntroductionContainer>
                    <IntroductionContent />
                  </BVIntroductionContainer>
                );
              default:
                return <IntroductionContent />;
            }
          })()}
        </StyledMessages>
      </IntroContainer>
    </ThemeProvider>
  );
};

export default Introduction;
