import React from 'react';
import HeaderLogoStyled from '@components/HeaderLogo/HeaderLogo';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';

const HeaderLogo: React.FunctionComponent = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const { brand: Logo } = brandConfiguration.design.logos;

  return <HeaderLogoStyled theme={brandConfiguration}>{Logo && <Logo />}</HeaderLogoStyled>;
};

export default HeaderLogo;
