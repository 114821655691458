import React from 'react';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { colorUsage, fontSize, fontWeight } from '@stylesheet';
import { useNavigate } from 'react-router-dom';

const NoProductsMessage = styled.p`
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
`;

const NoProductsButton = styled.button`
  background-color: ${colorUsage.black};
  color: ${colorUsage.white};
  border: 2px ${colorUsage.black};
  height: 40px;
  width: fit-content;
  padding: 8px 30px 5px 30px;
  box-sizing: border-box;
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  cursor: pointer;
`;

interface Props {
  caRecommendationId: string;
}

export const NoProducts: React.FC<Props> = ({ caRecommendationId }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const navigate = useNavigate();

  switch (brandConfiguration.constants.name) {
    case BRAND.BV:
      return (
        <>
          <NoProductsMessage>
            <FormattedMessage id={`${brandConfiguration.constants.name.toLowerCase()}.liked-products.no-products`} />
          </NoProductsMessage>

          <NoProductsButton onClick={() => navigate(`/suggestion/${caRecommendationId}`)}>
            <FormattedMessage id={`${brandConfiguration.constants.name.toLowerCase()}.liked-products.my-selection`} />
          </NoProductsButton>
        </>
      );
    default:
      return null;
  }
};
