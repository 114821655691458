import React, { useState } from 'react';

import MobileCarousel from '@components/MobileCarousel';
import { CARecommendation, Product } from '@redux/caRecommendation/types';

import {
  BulletPoints,
  BulletPointsContainer,
  Button,
  CarouselContainer,
  CarouselPagination,
  CarouselPaginationContainer,
  Container,
  GoBackContainer,
  GoBackText,
  MobileCarouselImg,
  PriceContainer,
  PriceContent,
  ProductDescription,
  ProductHeader,
  ProductHeaderItem,
  ProductInfoContainer,
  ProductName,
  ProductPrice,
  ProductTitle,
  Slide,
  StyledLeftChevronIcon,
} from '@pages/ProductDetailPage/components/MobileProductDetailPage/MobileProductDetailPage.style';
import { getPriceWithCurrency } from '@services/product/productService';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { BrandConfiguration } from '@redux/brandConfiguration/types';
import { getSpacing } from '@stylesheet';
import LikeButton from '@components/LikeButton';

interface Props {
  product: Product;
  recommendation: CARecommendation;
  brandConfiguration: BrandConfiguration;
  initialLiked: boolean;
  part1: string;
  // eslint-disable-next-line no-undef
  bulletPoints: JSX.Element[];
}

const GucciMobileContent: React.FunctionComponent<Props> = ({
  product,
  recommendation,
  brandConfiguration,
  initialLiked,
  part1,
  bulletPoints,
}) => {
  const navigate = useNavigate();

  const [isLiked, setIsLiked] = useState<boolean>(initialLiked);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Container theme={brandConfiguration}>
      <ProductHeader paddingTop={getSpacing(1)} theme={brandConfiguration}>
        <GoBackContainer onClick={() => navigate(`/suggestion/${recommendation.id}`)}>
          <StyledLeftChevronIcon />
          <GoBackText theme={brandConfiguration}>
            <FormattedMessage id="gg.product-details.back-products-selection" />
          </GoBackText>
        </GoBackContainer>
        <ProductHeaderItem>
          <LikeButton
            caRecommendationId={recommendation.id}
            styleColorCode={product.styleColorCode}
            initialIsLiked={initialLiked}
            setLiked={setIsLiked}
            dynamicIsLiked={isLiked}
          />
        </ProductHeaderItem>
      </ProductHeader>

      <CarouselContainer marginTop="0" paddingBottom="0">
        <MobileCarousel setActiveIndex={setActiveIndex} arrows>
          {product.externalMediaUrls.map((url) => (
            <Slide key={url}>
              <MobileCarouselImg src={url} alt="product" />
            </Slide>
          ))}
        </MobileCarousel>
        <CarouselPaginationContainer>
          <CarouselPagination>{`${activeIndex + 1}/${product.externalMediaUrls.length ?? 0}`}</CarouselPagination>
        </CarouselPaginationContainer>
      </CarouselContainer>

      <PriceContainer>
        <PriceContent>
          <ProductName>{product.name}</ProductName>
          <ProductPrice>
            {getPriceWithCurrency(
              product.priceAmount,
              product.priceCurrency,
              brandConfiguration.constants.name,
              recommendation.country,
            )}
          </ProductPrice>
        </PriceContent>
      </PriceContainer>
      <ProductInfoContainer theme={brandConfiguration}>
        <ProductTitle>
          <FormattedMessage id="gg.product-details.product-details" />
        </ProductTitle>
        <ProductDescription>
          {part1}
          <BulletPointsContainer>
            <BulletPoints>
              {bulletPoints}
              {product.styleColorCode && (
                <li>
                  <FormattedMessage id="gg.product-details.style-code" values={{ value: product.styleColorCode }} />
                </li>
              )}
              {product.skus && product.skus.length > 0 && (
                <li>
                  <FormattedMessage id="gg.product-details.sku" values={{ value: product.skus[0].sku }} />
                </li>
              )}
            </BulletPoints>
          </BulletPointsContainer>
          <a href={product.url} target="_blank" rel="noreferrer">
            <Button theme={brandConfiguration}>
              <FormattedMessage id="gg.product-details.buy" />
            </Button>
          </a>
        </ProductDescription>
      </ProductInfoContainer>
    </Container>
  );
};

export default GucciMobileContent;
