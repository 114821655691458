import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import AnimatedRoute from '@components/AnimatedRoute';

import Loader from '@components/Loader';
import LikedProducts from '@pages/LikedProducts';
import ProductDetailPage from '@pages/ProductDetailPage';
import Suggestion from '@pages/Suggestion';
import { StyledTransitionGroup } from '@/routes.style';

const Home = lazy(() => import('@pages/Home'));
const ErrorPage = lazy(() => import('@pages/ErrorPage'));
const SuggestionActivation = lazy(() => import('@pages/SuggestionActivation'));

export const PATHS = {
  HOME: '/',
  ERROR: '/404',
  PRODUCT_DETAIL: '/suggestion/:recommendationId/products/:productStyleColorCode',
  SUGGESTION_ACTIVATION: '/:linkId',
  SUGGESTION: '/suggestion/:caRecommendationId',
  LIKED_PRODUCTS: '/suggestion/:caRecommendationId/products/liked',
};

const ChildrenRoutes = () => (
  <Suspense fallback={<Loader />}>
    <StyledTransitionGroup>
      <CSSTransition
        // key removed
        timeout={600}
        classNames="animation"
        mountOnEnter
        unmountOnExit
      >
        <Routes>
          <Route path={PATHS.HOME} element={<Home />} />
          <Route path={PATHS.ERROR} element={<ErrorPage />} />
          <Route
            path={PATHS.LIKED_PRODUCTS}
            element={
              <AnimatedRoute>
                <LikedProducts />
              </AnimatedRoute>
            }
          />
          <Route
            path={PATHS.PRODUCT_DETAIL}
            element={
              <AnimatedRoute>
                <ProductDetailPage />
              </AnimatedRoute>
            }
          />
          <Route
            path={PATHS.SUGGESTION_ACTIVATION}
            element={
              <AnimatedRoute>
                <SuggestionActivation />
              </AnimatedRoute>
            }
          />
          <Route
            path={PATHS.SUGGESTION}
            element={
              <AnimatedRoute>
                <Suggestion />
              </AnimatedRoute>
            }
          />
        </Routes>
      </CSSTransition>
    </StyledTransitionGroup>
  </Suspense>
);

export default ChildrenRoutes;
