import styled, { css } from 'styled-components';
import { colorUsage, fontSize, fontWeight, getSpacing } from '@stylesheet';
import theme from 'styled-theming';

const Button = styled.button`
  font-family: ${(props) => props.theme.design.fonts.main};
  font-size: ${fontSize.medium};
  line-height: ${fontSize.medium};
  font-weight: ${fontWeight.bold};
  white-space: nowrap;

  box-sizing: border-box;
  height: ${getSpacing(6)};
  max-width: ${getSpacing(30)};
  padding: ${getSpacing(2)} ${getSpacing(2)};

  background: ${colorUsage.black};

  /* Fixes a font-color bug on Safari */
  -webkit-text-fill-color: rgba(255, 255, 255, 1);
  -webkit-opacity: 1;
  color: rgba(255, 255, 255, 1);

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${(props) => props.disabled && 'none'};

  ${theme('language', {
    ar: css`
      direction: rtl;
    `,
  })}
`;

export default Button;
