import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { fontSize, fontWeight } from '@stylesheet';
import { FormattedMessage } from 'react-intl';

const GGGoBackText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  padding-left: 8px;
  padding-top: 4px;
`;

const BVGoBackText = styled.p`
  font-size: ${fontSize.medium};
  font-weight: ${fontWeight.normal};
  line-height: ${fontSize.large};
`;

export const GoBackText: React.FC = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  switch (brandConfiguration.constants.name) {
    case BRAND.GUCCI:
      return (
        <GGGoBackText>
          <FormattedMessage id={`${brandConfiguration.constants.name.toLowerCase()}.liked-products.back`} />
        </GGGoBackText>
      );
    case BRAND.BV:
      return (
        <BVGoBackText>
          <FormattedMessage id={`${brandConfiguration.constants.name.toLowerCase()}.nav-bar.back`} />
        </BVGoBackText>
      );
    default:
      return null;
  }
};
