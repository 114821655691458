import React, { useEffect, useState } from 'react';

import { CARecommendation, Product as ProductType } from '@redux/caRecommendation/types';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import useSelector from '@redux/useSelector';

import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';
import {
  Container,
  Favorite,
  Image,
  ImageContainer,
  ImagePlaceholder,
  Name,
} from '@components/SlpProduct/SlpProduct.style';
import { PRODUCT_LIST_SIZE } from '@redux/brandConfiguration/types';
import Functions from '@utils/Functions';
import placeholder from '@assets/placeholder.png';

interface Props {
  caRecommendation: CARecommendation;
  product: ProductType;
  goToProductDetail: () => void;
  isDesktopVersion: boolean;
  size: PRODUCT_LIST_SIZE;
  nbProducts?: number;
  index: number;
}

const SlpProduct: React.FC<Props> = ({
  caRecommendation,
  product,
  goToProductDetail,
  isDesktopVersion,
  size,
  index,
  nbProducts = 1,
}) => {
  const [isLiked, setIsLiked] = useState(Functions.isLikedProduct(product));
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const sizes = isDesktopVersion
    ? Functions.getProductPatterSizes(size, index, nbProducts)
    : Functions.getProductPatterSizesMobile(size, index, nbProducts);

  useEffect(() => {
    setIsLiked(Functions.isLikedProduct(product));
  }, [product]);

  return (
    <ThemeProvider theme={{ language }}>
      <Container
        theme={brandConfiguration}
        onClick={goToProductDetail}
        width={sizes.container.width}
        paddingBottom={sizes.container.paddingBottom}
      >
        <ImageContainer
          width={sizes.image.aspectRatio ? 'auto' : sizes.image.width}
          height={sizes.image.aspectRatio ? 'auto' : sizes.image.height}
          marginLeft={sizes.container.marginLeft}
          marginRight={sizes.container.marginRight}
          aspectRatio={sizes.image.aspectRatio ?? 'auto'}
        >
          {product.thumbnail ? (
            <Image src={product.thumbnail} alt="" className="product-img" />
          ) : (
            <ImagePlaceholder src={placeholder} alt="" className="product-img" />
          )}
          {brandConfiguration.features.favorite && (
            <Favorite
              caRecommendationId={caRecommendation.id}
              styleColorCode={product.styleColorCode}
              initialIsLiked={!!isLiked}
              dynamicIsLiked={!!isLiked}
            />
          )}
        </ImageContainer>
        <Name
          className="product-title"
          width={sizes.image.aspectRatio ? '100%' : sizes.image.width}
          marginLeft={sizes.image.aspectRatio ? 'auto' : sizes.container.marginLeft}
          marginRight={sizes.image.aspectRatio ? 'auto' : sizes.container.marginRight}
          theme={brandConfiguration}
        >
          {product.name}
        </Name>
      </Container>
    </ThemeProvider>
  );
};

export default SlpProduct;
