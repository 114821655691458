import styled, { css } from 'styled-components';
import { onSmallScreen } from '@stylesheet';

const HeaderLogoStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  fill: ${(props) => props.theme.design.colors.brandLogo};
  width: ${(props) => props.theme.design.navbar.logo.width};
  min-height: ${(props) => props.theme.design.navbar.logo.minHeight};
  padding-left: ${(props) => props.theme.design.navbar.logo.paddingLeft};
  ${onSmallScreen(css`
    width: ${(props) => props.theme.design.navbar.logo.mobile.width};
    padding-left: ${(props) => props.theme.design.navbar.logo.mobile.paddingLeft};
    margin: ${(props) => props.theme.design.navbar.logo.mobile.margin};
  `)}
`;

export default HeaderLogoStyled;
