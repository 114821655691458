import styled, { css } from 'styled-components';
import { onLargeScreen } from '@stylesheet';

export const Favorite = styled.div`
  svg {
    height: ${(props) => props.theme.design.pages.productsList.list.mobile.favorite.height};
    width: ${(props) => props.theme.design.pages.productsList.list.mobile.favorite.width};
    ${onLargeScreen(css`
      height: ${(props) => props.theme.design.pages.productsList.list.desktop.favorite.height};
      width: ${(props) => props.theme.design.pages.productsList.list.desktop.favorite.width};
    `)}
  }

  cursor: pointer;
`;
