import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import theme from 'styled-theming';

const DefaultLikedProductsMessage = styled.div`
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

const BVLikedProductsMessage = styled(DefaultLikedProductsMessage)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

interface Props {
  children: ReactNode;
}

export const LikedProductsMessage: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const LikedProductsMessage = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return BVLikedProductsMessage;
      default:
        return DefaultLikedProductsMessage;
    }
  })();

  return <LikedProductsMessage>{children}</LikedProductsMessage>;
};
