import styled, { css } from 'styled-components';
import { onSmallScreen } from '@stylesheet';
import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const DefaultBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  ${onSmallScreen(css`
    display: none;
  `)}

  &.panel-contact-enter {
    opacity: 0;
  }

  &.panel-contact-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &.panel-contact-exit {
    opacity: 1;
  }

  &.panel-contact-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }

  &.panel-favorites-enter {
    opacity: 0;
  }

  &.panel-favorites-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &.panel-favorites-exit {
    opacity: 1;
  }

  &.panel-favorites-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
`;

const SlpBackground = styled(DefaultBackground)`
  background-color: rgba(0, 0, 0, 0.8);
`;

const BvBackground = styled(DefaultBackground)`
  background-color: rgba(0, 0, 0, 0.2);
`;

type Props = {
  onClick?: () => void;
};

export const Background: React.FC<Props> = ({ onClick }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const Background = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.SLP:
        return SlpBackground;
      case BRAND.BV:
        return BvBackground;
      default:
        return null;
    }
  })();

  return Background && <Background onClick={onClick} />;
};
