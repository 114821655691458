import { ProductPatternVariants } from '@redux/brandConfiguration/types';

export const productListSizes: ProductPatternVariants = {
  desktop: {
    bigCenter: {
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '100px',
      },
      image: {
        width: '372px',
        height: '465px',
      },
    },
    bigLeft: {
      container: {
        width: '50%',
        marginLeft: '0px',
        marginRight: 'auto',
        paddingBottom: '100px',
      },
      image: {
        width: '372px',
        height: '465px',
      },
    },
    bigRight: {
      container: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: '0px',
        paddingBottom: '100px',
      },
      image: {
        width: '372px',
        height: '465px',
      },
    },
    mediumLeft: {
      container: {
        width: '50%',
        marginLeft: '0px',
        marginRight: 'auto',
        paddingBottom: '100px',
      },
      image: {
        width: '304px',
        height: '409px',
      },
    },
    mediumRight: {
      container: {
        width: '50%',
        marginLeft: 'auto',
        marginRight: '0px',
        paddingBottom: '100px',
      },
      image: {
        width: '304px',
        height: '409px',
      },
    },
    small: {
      container: {
        width: '236px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: '100px',
      },
      image: {
        width: '236px',
        height: '295px',
      },
    },
  },
  mobile: {
    bigCenter: {
      container: {
        width: '100%',
        marginLeft: '33px',
        marginRight: '33px',
        paddingBottom: '50px',
      },
      image: {
        width: '100%',
        height: '348px',
        aspectRatio: '0.8',
      },
    },
    bigLeft: {
      container: {
        width: '100%',
        marginLeft: '16px',
        marginRight: 'auto',
        paddingBottom: '30px',
      },
      image: {
        width: '278px',
        height: '348px',
      },
    },
    bigRight: {
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: '16px',
        paddingBottom: '30px',
      },
      image: {
        width: '278px',
        height: '348px',
      },
    },
    mediumLeft: {
      container: {
        width: '100%',
        marginLeft: '16px',
        marginRight: 'auto',
        paddingBottom: '100px',
      },
      image: {
        width: '225px',
        height: '307px',
      },
    },
    mediumRight: {
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: '16px',
        paddingBottom: '100px',
      },
      image: {
        width: '225px',
        height: '307px',
      },
    },
    small: {
      container: {
        width: '50%',
        marginLeft: '3px',
        marginRight: '3px',
        paddingBottom: '30px',
      },
      image: {
        width: '100%',
        height: '216px',
        aspectRatio: '0.8',
      },
    },
  },
};
