import React from 'react';
import { PublicAdvisor } from '@redux/advisors/types';
import { AdvisorContainer, SlpModalContent, SlpModalHeader, SlpModalTitle } from '@components/ContactModal/style';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import CloseButton from '@components/CloseButton';
import { FormattedMessage } from 'react-intl';
import SlpContactContent from '@components/SidePanel/Content/Contact/SlpContactContent';

type Props = {
  advisor: PublicAdvisor;
  action: () => void;
};

const SlpContact: React.FC<Props> = ({ advisor, action }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  return (
    <SlpModalContent theme={brandConfiguration}>
      <AdvisorContainer>
        <SlpModalHeader>
          <CloseButton onClick={action} />
        </SlpModalHeader>
        <SlpModalTitle>
          <FormattedMessage id="slp.socials.contact-your-advisor" />
        </SlpModalTitle>
        <SlpContactContent advisor={advisor} />
      </AdvisorContainer>
    </SlpModalContent>
  );
};

export default SlpContact;
