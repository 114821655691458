import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Panel } from '@components/SidePanel/components/Panel';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader/SidePanelHeader';
import { Background } from '@components/SidePanel/components/Background';

type Props = {
  action: () => void;
  isOpen: boolean;
  width: string;
  className: string;
  children: ReactNode;
  title?: string;
};

const SidePanel: React.FC<Props> = ({ action, isOpen, width, className, children, title }) => (
  <>
    <CSSTransition in={isOpen} timeout={600} classNames={className} unmountOnExit>
      <Background onClick={action} />
    </CSSTransition>

    <CSSTransition in={isOpen} timeout={600} classNames={className} unmountOnExit>
      <Panel width={width}>
        <SidePanelHeader title={title} onClose={action} />

        {children}
      </Panel>
    </CSSTransition>
  </>
);
export default SidePanel;
