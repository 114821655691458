import styled, { css } from 'styled-components';
import { onLargeScreen, onSmallScreen } from '@stylesheet';
import { BRAND } from '@redux/brandConfiguration/types';

export const Container = styled.div<{ brand: string }>`
  position: absolute;
  font-family: ${(props) => props.theme.design.fonts.introduction};
  height: 100%;
  width: 100%;
  overflow-y: ${(props) => {
    switch (props.brand) {
      case BRAND.GUCCI:
        return 'auto';
      case BRAND.SLP:
        return 'hidden';
      default:
        return 'initial';
    }
  }};

  ${onSmallScreen(css<{ brand: string }>`
    overflow-y: ${(props) => {
      switch (props.brand) {
        case BRAND.GUCCI:
        case BRAND.SLP:
          return 'auto';
        default:
          return 'initial';
      }
    }};
  `)};
`;

export const Content = styled.div`
  background-color: ${(props) => props.theme.design.pages.productDetails.backgroundColor};
  margin-top: ${(props) => props.theme.design.navbar.mobile.height};
  min-height: 0;
  height: auto;
  width: 100%;

  ${onLargeScreen(css`
    margin-top: ${(props) => props.theme.design.navbar.height};
    min-height: 100%;
  `)}
`;
