import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import gucciLogo from '@assets/bubble_icon.png';

import { PublicAdvisor } from '@redux/advisors/types';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import {
  AdvisorContainer,
  AdvisorImageMobile,
  ChevronIcon,
  ExpandIconContainer,
  PhoneNumber,
  SocialContactContainer,
  SocialContactContainerMobile,
  SocialContactContent,
  SocialContactContentMobile,
  SocialContactIconsContainer,
  SocialContactRow,
  StyledDesktopLineIcon,
  StyledDesktopMailIcon,
  StyledDesktopNumberIcon,
  StyledDesktopSmsIcon,
  StyledDesktopWhatsAppIcon,
  StyledLink,
  TextMobile,
} from './GGSocialContactGroup.style';
import { AdvisorImage, AdvisorName } from '@components/SocialContactGroup/components';

interface Props {
  isDesktopVersion: boolean;
}

const contactMethodsValues: {
  name: keyof PublicAdvisor;
  icon: JSX.Element;
  linkPrefix?: string;
}[] = [
  {
    name: 'workPhoneNumber',
    icon: <StyledDesktopNumberIcon />,
    linkPrefix: 'tel:',
  },
  {
    name: 'sms',
    icon: <StyledDesktopSmsIcon />,
    linkPrefix: 'sms:',
  },
  {
    name: 'email',
    icon: <StyledDesktopMailIcon />,
    linkPrefix: 'mailto:',
  },
  {
    name: 'whatsApp',
    icon: <StyledDesktopWhatsAppIcon />,
  },
  {
    name: 'line',
    icon: <StyledDesktopLineIcon />,
  },
];

const MAX_ICONS_TO_DISPLAY_MOBILE: number = 3;

const displaySocialContactLinks = (nbContactMethods: number, displayAll: boolean, advisor?: PublicAdvisor) => {
  let count = 0;

  return contactMethodsValues
    .filter((method) => advisor?.[method.name])
    .map((method) => {
      if (nbContactMethods <= MAX_ICONS_TO_DISPLAY_MOBILE || count < MAX_ICONS_TO_DISPLAY_MOBILE || displayAll) {
        const link = method.linkPrefix ? `${method.linkPrefix}${advisor?.[method.name]}` : advisor?.[method.name];
        count += 1;
        return (
          <StyledLink
            key={`icon_${method.name}_${count}`}
            href={link || undefined}
            rel="noopener noreferrer"
            target="_blank"
          >
            {method.icon}
          </StyledLink>
        );
      }
      return null;
    });
};

const GGSocialContactGroup: React.FC<Props> = ({ isDesktopVersion }) => {
  const { advisor } = useSelector(getCaRecommendation) || {};
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const [displayAllIcons, setDisplayAllIcons] = useState(false);

  const nbContactMethods = advisor ? contactMethodsValues.filter((method) => advisor[method.name]).length : 0;

  return (
    <SocialContactRow>
      {isDesktopVersion ? (
        <SocialContactContainer>
          <SocialContactContent>
            <AdvisorContainer>
              <AdvisorImage src={gucciLogo} />

              <AdvisorName>{advisor?.displayName}</AdvisorName>
            </AdvisorContainer>

            <SocialContactIconsContainer>
              {advisor?.workPhoneNumber && (
                <StyledLink href={`tel:${advisor.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">
                  <StyledDesktopNumberIcon className="phone-icon" />

                  <PhoneNumber className="phone-number">{advisor.workPhoneNumber}</PhoneNumber>
                </StyledLink>
              )}

              {advisor?.sms && (
                <StyledLink href={`sms:${advisor.sms}`} rel="noopener noreferrer" target="_blank">
                  <StyledDesktopSmsIcon className="sms-icon" />

                  <PhoneNumber className="phone-number">{advisor.sms}</PhoneNumber>
                </StyledLink>
              )}

              {advisor?.email && (
                <StyledLink href={`mailto:${advisor.email}`} rel="noopener noreferrer" target="_blank">
                  <StyledDesktopMailIcon />

                  <div>{advisor.email}</div>
                </StyledLink>
              )}

              {advisor?.whatsApp && advisor?.whatsAppNumber && (
                <StyledLink href={advisor.whatsApp} rel="noopener noreferrer" target="_blank">
                  <StyledDesktopWhatsAppIcon />

                  <PhoneNumber className="phone-number">{advisor.whatsAppNumber}</PhoneNumber>
                </StyledLink>
              )}

              {advisor?.line && advisor?.linePseudo && (
                <StyledLink href={advisor.line} rel="noopener noreferrer" target="_blank">
                  <StyledDesktopLineIcon />

                  <div>{advisor.linePseudo}</div>
                </StyledLink>
              )}
            </SocialContactIconsContainer>
          </SocialContactContent>
        </SocialContactContainer>
      ) : (
        <SocialContactContainerMobile>
          <SocialContactContentMobile>
            <AdvisorImageMobile src={gucciLogo} />

            {!displayAllIcons && <TextMobile theme={brandConfiguration}>{advisor?.displayName}</TextMobile>}

            <SocialContactIconsContainer>
              {displaySocialContactLinks(nbContactMethods, displayAllIcons, advisor)}

              <ExpandIconContainer onClick={() => setDisplayAllIcons(!displayAllIcons)}>
                <ChevronIcon displayAllIcons={displayAllIcons} />
              </ExpandIconContainer>
            </SocialContactIconsContainer>
          </SocialContactContentMobile>
        </SocialContactContainerMobile>
      )}
    </SocialContactRow>
  );
};

export default GGSocialContactGroup;
