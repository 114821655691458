import React from 'react';

import { CARecommendation, Product as ProductType } from '@redux/caRecommendation/types';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import useSelector from '@redux/useSelector';

import { ThemeProvider } from 'styled-components/macro';
import { getLanguage } from '@redux/language/selectors';
import {
  Container,
  Favorite,
  Image,
  ImageContainer,
  ProductInfo,
  ProductInfoContainer,
  ProductName,
  ProductNameContainer,
  ProductPrice,
  ProductPriceContainer,
} from '@components/BvProduct/Product.style';

import { getPriceWithCurrency } from '@services/product/productService';
import { Currency } from '@constants/currency';
import Functions from '@utils/Functions';

interface Props {
  caRecommendation: CARecommendation;
  product: ProductType;
  goToProductDetail: () => void;
  isLookbook: boolean;
  index?: number;
}

const BvProduct: React.FC<Props> = ({ caRecommendation, product, goToProductDetail, isLookbook, index }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const language = useSelector(getLanguage)?.language.locale;

  const isLiked = Functions.isLikedProduct(product);
  return (
    <ThemeProvider theme={{ language }}>
      <Container theme={brandConfiguration} onClick={goToProductDetail} className="product-bv">
        <ImageContainer>
          <Image src={product.thumbnail} onError={(event) => (event.currentTarget.style.display = 'none')} />
        </ImageContainer>

        <ProductInfoContainer>
          <ProductInfo>
            <ProductNameContainer>
              <ProductName>{product.name}</ProductName>
            </ProductNameContainer>

            <ProductPriceContainer>
              <ProductPrice>
                {getPriceWithCurrency(
                  product.priceAmount,
                  product.priceCurrency,
                  brandConfiguration.constants.name,
                  caRecommendation.country,
                )}
              </ProductPrice>
            </ProductPriceContainer>
          </ProductInfo>
        </ProductInfoContainer>

        {brandConfiguration.features.favorite && (
          <Favorite
            caRecommendationId={caRecommendation.id}
            styleColorCode={product.styleColorCode}
            initialIsLiked={isLiked}
          />
        )}
      </Container>
    </ThemeProvider>
  );
};

export default BvProduct;
