import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as NextArrow } from '@components/MobileCarousel/nextMobile.svg';
import { ReactComponent as PrevArrow } from '@components/MobileCarousel/previousMobile.svg';
import { StyledCarousel } from './MobileCarousel.style';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const SampleNextArrow = (props: any) => <NextArrow onClick={props.onClick} className={props.className} />;

const SamplePrevArrow = (props: any) => <PrevArrow onClick={props.onClick} className={props.className} />;

type Props = {
  children: React.ReactNode;
  setActiveIndex?: React.Dispatch<React.SetStateAction<number>>;
  dots?: boolean;
  arrows?: boolean;
  infinite?: boolean;
};

const MobileCarousel: React.FC<Props> = ({
  children,
  setActiveIndex,
  dots = false,
  arrows = false,
  infinite = false,
}) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const settings = {
    accessibility: true,
    dots,
    arrows,
    slidesToScroll: 1,
    infinite,
    slidesToShow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    afterChange: (index: number) => setActiveIndex && setActiveIndex(index),
    customPaging: () => (
      <div>
        <div></div>
      </div>
    ),
  };

  return (
    <StyledCarousel>
      <Slider
        {...settings}
        className={(() => {
          switch (brandConfiguration.constants.name) {
            case BRAND.GUCCI:
              return 'slick-slider-gg';
            case BRAND.SLP:
              return 'slick-slider-slp';
            case BRAND.BV:
              return 'slick-slider-bv';
          }
        })()}
      >
        {children}
      </Slider>
    </StyledCarousel>
  );
};

export default MobileCarousel;
