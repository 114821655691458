import React, { createContext, useState, useContext } from 'react';

interface SidePanelContextProps {
  isContactSidePanelOpen: boolean;
  setContactSidePanelOpen: (isOpen: boolean) => void;
}

const SidePanelContext = createContext<SidePanelContextProps>({
  isContactSidePanelOpen: false,
  setContactSidePanelOpen: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const SidePanelProvider: React.FC<Props> = ({ children }) => {
  const [isContactSidePanelOpen, setContactSidePanelOpen] = useState(false);

  return (
    <SidePanelContext.Provider value={{ isContactSidePanelOpen, setContactSidePanelOpen }}>
      {children}
    </SidePanelContext.Provider>
  );
};

export const useSidePanel = () => useContext(SidePanelContext);
