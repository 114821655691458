import React from 'react';

export interface BrandConfiguration {
  constants: {
    name: string;
    url: string;
  };
  features: {
    favorite: boolean;
    productDetail: boolean;
    showSocialsMediaInBusinessCard: boolean;
  };
  design: {
    colors: {
      background: string;
      brandLogo: string;
      text: string;
      footerText: string;
      homePageProductText: string;
      productDetailsText: string;
      brandLogoFullBackgroundPage: string;
      textFullBackgroundPage: string;
      lightGrey: string;
      footerBackground: string;
      productCarousselBackground: string;
    };
    fonts: {
      main: string;
      introduction: string;
    };
    logos: {
      brand?: React.FunctionComponent;
      facebook?: React.FunctionComponent;
      instagram?: React.FunctionComponent;
      tiktok?: React.FunctionComponent;
      x?: React.FunctionComponent;
      youtube?: React.FunctionComponent;
      emptyHeart?: React.FunctionComponent;
      fullHeart?: React.FunctionComponent;
      arrow?: React.FunctionComponent;
      contactAdvisor?: React.FunctionComponent;
      minus?: React.FunctionComponent;
      plus?: React.FunctionComponent;
    };
    background: {
      small: string;
      large: string;
    };
    pages: {
      productsList: {
        introduction: {
          container: {
            minHeight: string;
          };
          message: {
            mobile: {
              minHeight: string;
              padding: string;
            };
            backgroundColor: string;
            padding: string;
            width: string;
            display: string;
            flexDirection: string;
            justifyContent: string;
            textAlign: string;
          };
          hello: {
            fontSize: string;
            fontWeight: string;
            lineHeight: string;
            fontStyle: string;
            color: string;
            textTransform: string;
            mobile: {
              fontSize: string;
              lineHeight: string;
            };
          };
          selection: {
            fontSize: string;
            fontWeight: string;
            lineHeight: string;
            color: string;
            textTransform: string;
            mobile: {
              fontSize: string;
              lineHeight: string;
            };
          };
          advisor: {
            fontSize: string;
            fontWeight: string;
            lineHeight: string;
            marginTop: string;
            fontStyle: string;
            color: string;
            textTransform: string;
            mobile: {
              fontSize: string;
              lineHeight: string;
            };
          };
        };
        list: {
          desktop: {
            favorite: {
              height: string;
              width: string;
            };
            pattern?: PRODUCT_LIST_SIZE[];
          };
          mobile: {
            favorite: {
              height: string;
              width: string;
            };
            pattern?: PRODUCT_LIST_SIZE[];
          };
        };
      };
      productDetails: {
        backgroundColor: string;
        mobile: {
          description: {
            padding: string;
          };
        };
      };
      errorPage: {
        logo: {
          marginTop: string;
          marginBottom: string;
        };
        textContainer: {
          maxWidth: string;
          desktop: {
            marginTop: string;
            marginBottom: string;
          };
          mobile: {
            marginTop: string;
            marginBottom: string;
          };
        };
        container: {
          desktop: {
            paddingTop: string;
            paddingX: string;
            paddingBottom: string;
          };
          mobile: {
            paddingTop: string;
            paddingX: string;
            paddingBottom: string;
          };
        };
        text: {
          part1: {
            fontSize: string;
            fontWeight: string;
            lineHeight: string;
            marginTop: string;
            color: string;
            fontStyle: string;
            textTransform: string;
          };
          part2: {
            fontSize: string;
            fontWeight: string;
            lineHeight: string;
            marginTop: string;
            color: string;
            fontStyle: string;
            textTransform: string;
          };
        };
      };
    };
    navbar: {
      height: string;
      borderBottom: string;
      textTransform: string;
      backgroundColor: string;
      padding: string;
      mobile: {
        height: string;
        padding: string;
      };
      logo: {
        minHeight: string;
        width: string;
        paddingLeft: string;
        mobile: {
          width: string;
          paddingLeft: string;
          margin: string;
        };
      };
      link: {
        color: string;
        fontSize: string;
        marginRight: string;
        fontWeight: string;
        mobile: {
          fontSize: string;
        };
      };
    };
    businessCard: {
      icons?: {
        facebook?: React.FunctionComponent;
        kakao?: React.FunctionComponent;
        line?: React.FunctionComponent;
        mail?: React.FunctionComponent;
        phone?: React.FunctionComponent;
        sms?: React.FunctionComponent;
        wechat?: React.FunctionComponent;
        weibo?: React.FunctionComponent;
        whatsapp?: React.FunctionComponent;
      };
      mobile: {
        width: string;
        position: string;
        top: string;
        left: string;
        bottom: string;
        right: string;
        minWidth: string;
        height: string;
        background: string;
        borderRadius: string;
        zIndex: string;
        opacity: string;
        padding: string;
        boxShadow: string;
      };
      desktop: {
        width: string;
        position: string;
        bottom: string;
        right: string;
        minWidth: string;
        height: string;
        background: string;
        borderRadius: string;
        zIndex: string;
        opacity: string;
        padding: string;
        boxShadow: string;
      };
    };
    panel: {
      favorites: {
        width: string;
      };
      businessCard: {
        width: string;
      };
      productMoreDetails: {
        width: string;
      };
    };
    columns: {
      small: string;
      large: string;
    };
    rows: {
      small: string;
      large: string;
    };
  };
}

export enum PRODUCT_LIST_SIZE {
  SMALL = 'SMALL',
  MEDIUM_LEFT = 'MEDIUM_LEFT',
  MEDIUM_RIGHT = 'MEDIUM_RIGHT',
  BIG_CENTER = 'BIG_CENTER',
  BIG_LEFT = 'BIG_LEFT',
  BIG_RIGHT = 'BIG_RIGHT',
}

export type ProductPatternSizes = {
  container: {
    width: string;
    marginLeft: string;
    marginRight: string;
    paddingBottom: string;
  };
  image: {
    width: string;
    height: string;
    aspectRatio?: string;
  };
};

export type ProductPatternVariants = {
  desktop: {
    bigCenter: ProductPatternSizes;
    bigLeft: ProductPatternSizes;
    bigRight: ProductPatternSizes;
    mediumLeft: ProductPatternSizes;
    mediumRight: ProductPatternSizes;
    small: ProductPatternSizes;
  };
  mobile: {
    bigCenter: ProductPatternSizes;
    bigLeft: ProductPatternSizes;
    bigRight: ProductPatternSizes;
    mediumLeft: ProductPatternSizes;
    mediumRight: ProductPatternSizes;
    small: ProductPatternSizes;
  };
};

export enum BRAND {
  GUCCI = 'GG',
  SLP = 'SLP',
  BV = 'BV',
}
