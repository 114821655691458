import React from 'react';

import gucciLogo from '@assets/bubble_icon.png';

import { BRAND } from '@redux/brandConfiguration/types';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import {
  ContactButton,
  ContactImage,
  SlpButton,
  SlpButtonContainer,
  StyledArrowDownIcon,
  StyledBubbleContainer,
} from './style';

type Props = {
  openContact: () => void;
  isContactOpen: boolean;
  openFavorites?: () => void;
  brand: string;
};

const ContactBubble: React.FC<Props> = ({ openContact, openFavorites, isContactOpen, brand }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const { emptyHeart: EmptyHeart, contactAdvisor: ContactAdvisor } = brandConfiguration.design.logos;

  switch (brand) {
    case BRAND.GUCCI:
      return (
        <ContactButton onClick={openContact}>
          <StyledBubbleContainer />

          {!isContactOpen ? <ContactImage src={gucciLogo} alt="sales advisor picture" /> : <StyledArrowDownIcon />}
        </ContactButton>
      );

    case BRAND.SLP:
      return (
        <SlpButtonContainer>
          <SlpButton onClick={openFavorites}>{EmptyHeart && <EmptyHeart />}</SlpButton>

          <SlpButton onClick={openContact}>{ContactAdvisor && <ContactAdvisor />}</SlpButton>
        </SlpButtonContainer>
      );

    default:
      return null;
  }
};
export default ContactBubble;
