import React from 'react';
import { FooterBrandLogo } from '@components/Footer/style';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';

export const GGFooter: React.FC = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const { brand: Logo } = brandConfiguration.design.logos;

  return <FooterBrandLogo theme={brandConfiguration}>{Logo && <Logo />}</FooterBrandLogo>;
};
