import styled, { css } from 'styled-components';
import { getSpacing, onLargeScreen, onSmallScreen } from '@stylesheet';

export const StyledNavBar = styled.div<{
  zIndex?: number;
}>`
  position: fixed;
  top: 0;
  z-index: ${(props) => props.zIndex ?? 1};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: ${(props) => props.theme.design.navbar.mobile.padding};
  height: ${(props) => props.theme.design.navbar.mobile.height};
  background-color: ${(props) => props.theme.design.navbar.backgroundColor};
  text-transform: ${(props) => props.theme.design.navbar.textTransform};
  border-bottom: ${(props) => props.theme.design.navbar.borderBottom};

  ${onLargeScreen(css`
    padding: ${(props) => props.theme.design.navbar.padding};
    height: ${(props) => props.theme.design.navbar.height};
  `)}
`;

export const DesktopContainer = styled.div`
  overflow: hidden;
  z-index: 1;

  ${onSmallScreen(css`
    display: none;
  `)}
`;

export const MobileContainer = styled.div`
  ${onLargeScreen(css`
    display: none;
  `)}
`;

export const StyledText = styled.p`
  margin-right: ${(props) => props.theme.design.navbar.link.marginRight};
  color: ${(props) => props.theme.design.navbar.link.color};
  font-weight: ${(props) => props.theme.design.navbar.link.fontWeight};
  font-size: ${(props) => props.theme.design.navbar.link.fontSize};
  ${onSmallScreen(css`
    font-size: ${(props) => props.theme.design.navbar.link.mobile.fontSize};
  `)}
`;

export const StyledLink = styled.a`
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const HeaderFavoriteContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 50px;

  ${onLargeScreen(css`
    height: 70px;
  `)}
`;

export const StyledMyFavorites = styled.img`
  margin: auto;

  ${onLargeScreen(css`
    width: ${getSpacing(3)};
  `)}
`;

export const SLPContainer = styled.div<{
  justifyContent: string;
}>`
  display: flex;
  padding-left: 32px;
  padding-right: 32px;
  gap: 20px;
  width: calc(100% / 3);
  justify-content: ${(props) => props.justifyContent};

  ${onSmallScreen(css`
    padding-left: 16px;
    padding-right: 16px;
  `)}
`;

export const BVHeaderLinks = styled.div`
  display: flex;
  gap: 24px;
`;

export const BVHeaderMobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  padding: 21px 16px;
  height: 100%;
  gap: 24px;
`;

export const ArrowContainer = styled.div`
  rotate: 180deg;
`;
