import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locales } from '@constants/languageConfiguration';
import { Language } from '@redux/language/types';

export type LanguageState = Readonly<{ language: Language }>;

const getLocales = (language: string) => {
  switch (language) {
    case 'aear': {
      return {
        locale: 'ar',
        message: locales.ar,
      };
    }
    case 'dede': {
      return {
        locale: 'de',
        message: locales.de,
      };
    }
    case 'eses': {
      return {
        locale: 'es',
        message: locales.es,
      };
    }
    case 'esmx': {
      return {
        locale: 'es-mx',
        message: locales.esmx,
      };
    }
    case 'frfr': {
      return {
        locale: 'fr',
        message: locales.fr,
      };
    }
    case 'itit': {
      return {
        locale: 'it',
        message: locales.it,
      };
    }
    case 'cncn': {
      return {
        locale: 'cn',
        message: locales.cn,
      };
    }
    case 'jpjp': {
      return {
        locale: 'jp',
        message: locales.jp,
      };
    }
    case 'krkr': {
      return {
        locale: 'kr',
        message: locales.kr,
      };
    }
    case 'thth': {
      return {
        locale: 'th',
        message: locales.th,
      };
    }
    default: {
      return {
        locale: 'en',
        message: locales.en,
      };
    }
  }
};

const initialState: LanguageState = {
  language: {
    locale: 'en',
    message: locales.en,
  },
};

const languageSlice = createSlice({
  name: 'Language',
  initialState,
  reducers: {
    updateLanguage: (state, action: PayloadAction<string>) => {
      const languageLocales = getLocales(action.payload);
      state.language.locale = languageLocales.locale;
      state.language.message = languageLocales.message;
    },
  },
});

export const { updateLanguage } = languageSlice.actions;
export default languageSlice.reducer;
