import styled, { css } from 'styled-components';
import { fontSize, fontWeight, onLargeScreen } from '@stylesheet';

export const SidePanelHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  box-sizing: border-box;
  align-items: center;

  ${onLargeScreen(css`
    padding: 32px 60px;
  `)}
`;

export const SidePanelHeaderTitle = styled.h2`
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  text-transform: uppercase;
`;
