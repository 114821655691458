import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import GGSocialContactGroup from '@components/SocialContactGroup/GGSocialContactGroup';
import { BVSocialContactGroup } from '@components/SocialContactGroup/BVSocialContactGroup';

type Props = {
  isDesktopVersion?: boolean;
};

export const SocialContactGroup: React.FC<Props> = ({ isDesktopVersion = false }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  if (!brandConfiguration.features.showSocialsMediaInBusinessCard) {
    return null;
  }

  const SocialContactGroup = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.GUCCI:
        return GGSocialContactGroup;
      case BRAND.BV:
        return BVSocialContactGroup;
      default:
        return null;
    }
  })();

  return SocialContactGroup && <SocialContactGroup isDesktopVersion={isDesktopVersion} />;
};

export default SocialContactGroup;
