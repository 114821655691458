import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';

const BVStyledMessages = styled.div`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

interface Props {
  children: React.ReactNode;
}

const StyledMessages: React.FC<Props> = ({ children }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const StyledMessages = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.BV:
        return BVStyledMessages;
      default:
        return null;
    }
  })();

  return StyledMessages ? <StyledMessages>{children}</StyledMessages> : <>{children}</>;
};

export default StyledMessages;
