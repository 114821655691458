import styled, { css } from 'styled-components';
import { fontSize, fontWeight, onLargeScreen } from '@stylesheet';

export const ContactContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 16px;

  ${onLargeScreen(css`
    padding: 0 60px;
  `)}
`;

export const ContactContentItem = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const ContactContentIcon = styled.div`
  width: 17px;
  height: 17px;
`;

export const ContactContentLink = styled.a`
  font-size: ${fontSize.medium};
  line-height: ${fontSize.large};
  font-weight: ${fontWeight.normal};
  text-decoration: underline;
  cursor: pointer;
`;
