import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import { SlpSidePanelHeader } from '@components/SidePanel/SidePanelHeader/SlpSideHeaderPanel';
import { BvSidePanelHeader } from '@components/SidePanel/SidePanelHeader/BvSideHeaderPanel';

interface Props {
  title?: string;
  onClose: () => void;
}

export const SidePanelHeader: React.FC<Props> = ({ title, onClose }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const SidePanelHeader = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.SLP:
        return SlpSidePanelHeader;
      case BRAND.BV:
        return BvSidePanelHeader;
      default:
        return null;
    }
  })();

  return SidePanelHeader && <SidePanelHeader title={title} onClose={onClose} />;
};
