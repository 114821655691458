import React from 'react';
import { BurgerMenuIcon } from '@components/icons/BurgerMenu/BurgerMenu.style';

interface Props {
  onClick?: () => void;
  className?: string;
}

const BurgerMenu: React.FunctionComponent<Props> = ({ onClick, className }) => (
  <BurgerMenuIcon className={className} onClick={onClick} />
);

export default BurgerMenu;
