import React, { useEffect, useState } from 'react';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { SlpModalContainer } from '@components/ContactModal/style';
import { FormattedMessage } from 'react-intl';
import {
  Image,
  PanelBottomBlur,
  PanelFavoritesButton,
  PanelFavoritesContent,
  PanelFavoritesEmpty,
  PanelFavoritesProduct,
  PanelFavoritesProductContent,
  PanelFavoritesProductImage,
  PanelFavoritesProductRemove,
  PanelFavoritesProducts,
  PanelFavoritesProductTitle,
} from '@components/SidePanel/Content/Favorites/SlpFavoritesContent.style';
import { generatePath, useNavigate } from 'react-router-dom';
import { useFetchCaRecommendation, useUpdateFeedback } from '@redux/caRecommendation/hooks';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { Product as ProductType } from '@redux/caRecommendation/types';
import Loader from '@components/Loader';
import { PATHS } from '@routes';
import { updateCaRecommendation } from '@redux/caRecommendation';
import { useDispatch } from 'react-redux';
import Functions from '@utils/Functions';

type Wishlist = {
  product: ProductType;
  quantity: number;
};

type Props = {
  caRecommendationId: string;
  setOpenContactModal: () => void;
};

const SlpFavoritesContent: React.FC<Props> = ({ caRecommendationId, setOpenContactModal }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [, fetchCaRecommendation] = useFetchCaRecommendation();
  const caRecommendation = useSelector(getCaRecommendation);
  const [likedProducts, setLikedProducts] = useState<Wishlist[]>([]);

  useEffect(() => {
    if (caRecommendation) {
      let likeProducts: ProductType[] = [];
      const products = caRecommendation.products as ProductType[];
      likeProducts = products.filter((product) => Functions.isLikedProduct(product));
      setLikedProducts(
        likeProducts.map((p) => ({
          product: p,
          quantity: 1,
        })),
      );
    }
  }, [caRecommendation]);

  useEffect(() => {
    (async function fetch() {
      if (caRecommendationId) {
        await fetchCaRecommendation(caRecommendationId);
      }
    })();
  }, [caRecommendationId, fetchCaRecommendation]);

  if (!caRecommendation) {
    return <Loader />;
  }

  /* todo refacto to use only recommendationId or caRecommendationId everywhere */
  const goToProductDetail = (product: ProductType) => {
    navigate(
      generatePath(PATHS.PRODUCT_DETAIL, {
        caRecommendationId: caRecommendation.id,
        recommendationId: caRecommendation.id,
        productStyleColorCode: product.styleColorCode,
      }),
    );
  };

  // eslint-disable-next-line
  const [, updateFeedback] = useUpdateFeedback();

  const giveFeedback = async (feedback: number | null, styleColorCode: string) => {
    await updateFeedback(caRecommendationId, styleColorCode, feedback);
    setLikedProducts(likedProducts.filter((p) => p.product.styleColorCode !== styleColorCode));
    const products = caRecommendation.products as ProductType[];
    const productToRemove = products.find((p) => p.styleColorCode === styleColorCode);

    if (productToRemove) {
      dispatch(
        updateCaRecommendation({
          ...caRecommendation,
          products: products.map((p) =>
            p.styleColorCode === styleColorCode
              ? {
                  ...p,
                  feedback: null,
                }
              : p,
          ),
        }),
      );
    }
  };

  return (
    <SlpModalContainer>
      <PanelFavoritesContent>
        <PanelFavoritesProducts>
          {likedProducts.length === 0 && (
            <PanelFavoritesEmpty>
              <FormattedMessage id="slp.liked-products.no-products" />
            </PanelFavoritesEmpty>
          )}
          {likedProducts.map((p) => (
            <PanelFavoritesProduct>
              <PanelFavoritesProductImage onClick={() => goToProductDetail(p.product)}>
                <Image src={p.product.thumbnail} alt="" className="product-img" />
              </PanelFavoritesProductImage>
              <PanelFavoritesProductContent theme={brandConfiguration}>
                <div>
                  <PanelFavoritesProductTitle onClick={() => goToProductDetail(p.product)}>
                    {p.product.name}
                  </PanelFavoritesProductTitle>
                  <div>{p.product.color}</div>
                </div>
                <PanelFavoritesProductRemove onClick={() => giveFeedback(null, p.product.styleColorCode)}>
                  <FormattedMessage id="slp.liked-products.remove" />
                </PanelFavoritesProductRemove>
              </PanelFavoritesProductContent>
            </PanelFavoritesProduct>
          ))}
        </PanelFavoritesProducts>
        <PanelBottomBlur>
          <PanelFavoritesButton theme={brandConfiguration} onClick={setOpenContactModal}>
            <FormattedMessage id="slp.introduction.contact-advisor" />
          </PanelFavoritesButton>
        </PanelBottomBlur>
      </PanelFavoritesContent>
    </SlpModalContainer>
  );
};

export default SlpFavoritesContent;
