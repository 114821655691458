import styled, { css } from 'styled-components';
import { colorUsage, fontSize, getSpacing, onLargeScreen } from '@stylesheet';
import theme from 'styled-theming';
import LikeButton from '@components/LikeButton';

export const Container = styled.div<{ width: string; paddingBottom: string }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  padding-bottom: ${(props) => props.paddingBottom};
`;

export const ImageContainer = styled.div<{
  width: string;
  height: string;
  marginLeft: string;
  marginRight: string;
  aspectRatio: string;
}>`
  margin-top: 0;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  background-color: ${colorUsage.slpProductBackground};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  aspect-ratio: ${(props) => props.aspectRatio};
  overflow: ${(props) => (props.aspectRatio ? 'hidden' : 'none')};
  display: flex;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

export const Image = styled.img`
  margin: auto;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImagePlaceholder = styled.img`
  width: 10%;
  height: auto;
  margin: auto;
`;

export const Name = styled.p<{ width: string; marginLeft: string; marginRight: string }>`
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  padding-top: 12px;
  width: ${(props) => props.width};
  max-width: 250px;
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  color: ${colorUsage.black};
  text-align: center;
  font-family: ${(props) => props.theme.design.fonts.main};
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
  }

  ${onLargeScreen(css`
    padding-top: 16px;
  `)}

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const Favorite = styled(LikeButton)`
  position: absolute;
  top: ${getSpacing(1)};
  right: ${getSpacing(1)};
  ${onLargeScreen(css`
    top: ${getSpacing(2)};
    right: ${getSpacing(2)};
  `)}
`;
