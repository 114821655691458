import placeholder from '@assets/placeholder.png';
import { Footer } from '@components/Footer';
import Loader from '@components/Loader';
import SidePanel from '@components/SidePanel';
import {
  MonoImageContainer,
  MonoImageProductContainer,
  MoreDetailsPanelContainer,
  MoreDetailsPanelContent,
  ParallaxContainer,
  ParallaxCountContainer,
  ParallaxImageContainer,
  ParallaxImageItem,
  ParallaxImagePlaceholderItem,
  ParallaxOverlayContainer,
  ParallaxOverlayContent,
} from '@pages/ProductDetailPage/components/DesktopProductDetailPage/DesktopProductDetailPage.style';
import { loadImage } from '@pages/ProductDetailPage/components/DesktopProductDetailPage/GucciDesktopProductDetailPage';
import SlpDesktopProductInformations from '@pages/ProductDetailPage/components/DesktopProductDetailPage/SlpDesktopProductInformations';
import { IParallax, Parallax, ParallaxLayer } from '@react-spring/parallax';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { CARecommendation, Product } from '@redux/caRecommendation/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'react-redux';

interface Props {
  product: Product;
  recommendation: CARecommendation;
}

const SlpDesktopProductDetailPage: React.FunctionComponent<Props> = ({ product, recommendation }) => {
  const parallaxRef = useRef<IParallax>(null!);
  const [activePage, setActivePage] = useState(1); // Page active
  const [areImagesLoaded, setAreImagesLoaded] = useState(false);
  const [openMoreDetailsModal, setOpenMoreDetailsModal] = useState(false);
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  const { width: productMoreDetailsPanelWidth } = brandConfiguration.design.panel.productMoreDetails;

  const productImages = product.externalMediaUrls.filter((url) => url !== '');

  const checkImages = async () => {
    for (let i = 0; i < productImages.length; i += 1) {
      const url = productImages[i];
      await loadImage(url);
    }
    setAreImagesLoaded(true);
  };

  useEffect(() => {
    if (productImages.length !== 0) {
      checkImages();
    } else {
      setAreImagesLoaded(true);
    }
  }, []);

  const totalPages = productImages.length;

  const handleScroll = useCallback(() => {
    if (parallaxRef.current) {
      const container = parallaxRef.current.container.current;
      const scrollYProgress = container.scrollTop / (container.scrollHeight - container.clientHeight);
      const pageOffset = scrollYProgress * totalPages;
      if (Math.floor(pageOffset) + 1 <= totalPages) {
        setActivePage(Math.floor(pageOffset) + 1);
      }
    }
  }, [totalPages]);

  useEffect(() => {
    if (parallaxRef.current) {
      const container = parallaxRef.current.container.current;
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
    return undefined;
  }, [handleScroll]);

  const text: string = product.description ?? '';

  const indexFirstPart: number = text.indexOf(';');

  const part1: string = text.substring(0, indexFirstPart);
  const part2: string = text.substring(indexFirstPart + 1);

  const bulletPoints = part2.split(';').map((point, index) => point !== '' && <li key={index}>{point.trim()}</li>);

  const alignCenter = {
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <>
      <ParallaxContainer theme={brandConfiguration}>
        {totalPages > 1 ? (
          <Parallax pages={totalPages <= 1 ? 1.5 : totalPages + 0.5} ref={parallaxRef}>
            {!areImagesLoaded ? (
              <ParallaxLayer
                sticky={{
                  end: totalPages - 2,
                  start: 0,
                }}
              >
                <Loader />
              </ParallaxLayer>
            ) : (
              <>
                <ParallaxLayer
                  sticky={{
                    end: totalPages - 1,
                    start: 0,
                  }}
                  style={{
                    ...alignCenter,
                    justifyContent: 'flex-start',
                  }}
                >
                  <ParallaxOverlayContainer>
                    <ParallaxOverlayContent>
                      <ParallaxCountContainer>
                        <div>{activePage}</div>
                        <div>{totalPages !== 0 ? totalPages : 1}</div>
                      </ParallaxCountContainer>
                    </ParallaxOverlayContent>
                    <ParallaxOverlayContent>
                      <SlpDesktopProductInformations
                        product={product}
                        recommendation={recommendation}
                        part1={part1}
                        setOpenMoreDetailsModal={setOpenMoreDetailsModal}
                      />
                    </ParallaxOverlayContent>
                  </ParallaxOverlayContainer>
                </ParallaxLayer>
                {productImages.map((url, index) => (
                  <ParallaxLayer offset={index}>
                    <ParallaxImageContainer className="product-container">
                      <ParallaxImageItem key={`${url}_${index}`} src={url} />
                    </ParallaxImageContainer>
                  </ParallaxLayer>
                ))}
                <ParallaxLayer offset={totalPages <= 1 ? 1 : totalPages}>
                  <Footer />
                </ParallaxLayer>
              </>
            )}
          </Parallax>
        ) : (
          <MonoImageProductContainer>
            <MonoImageContainer>
              <ParallaxImageContainer className="product-container">
                {totalPages === 0 ? (
                  <ParallaxImagePlaceholderItem key="empty_photo" src={placeholder} />
                ) : (
                  <ParallaxImageItem key={`${productImages[0]}_${1}`} src={productImages[0]} />
                )}
              </ParallaxImageContainer>
              <SlpDesktopProductInformations
                product={product}
                recommendation={recommendation}
                part1={part1}
                setOpenMoreDetailsModal={setOpenMoreDetailsModal}
              />
            </MonoImageContainer>
            <Footer />
          </MonoImageProductContainer>
        )}
      </ParallaxContainer>
      <SidePanel
        action={() => setOpenMoreDetailsModal(false)}
        isOpen={openMoreDetailsModal}
        width={productMoreDetailsPanelWidth}
        className="panel-favorites"
        title=""
      >
        <MoreDetailsPanelContainer>
          <MoreDetailsPanelContent width="50%" theme={brandConfiguration}>
            {part1}
            <ul>
              {bulletPoints}
              {product.styleColorCode && (
                <li>
                  <FormattedMessage id="slp.product-details.style-code" values={{ value: product.styleColorCode }} />
                </li>
              )}
              {product.skus && product.skus.length > 0 && (
                <li>
                  <FormattedMessage id="slp.product-details.sku" values={{ value: product.skus[0].sku }} />
                </li>
              )}
            </ul>
          </MoreDetailsPanelContent>
        </MoreDetailsPanelContainer>
      </SidePanel>
    </>
  );
};
export default SlpDesktopProductDetailPage;
