import React from 'react';
import HeaderLogo from '@components/HeaderLogo';
import HeaderLinksGroup from '@components/HeaderLinksGroup';
import {
  HeaderFavoriteContainer,
  StyledLink,
  StyledMyFavorites,
  StyledNavBar,
  StyledText,
} from '@components/NavBar/style';
import { generatePath, useNavigate } from 'react-router-dom';
import { PATHS } from '@routes';
import { FormattedMessage } from 'react-intl';
import WhiteHeart from '@assets/like.svg';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';

export const GGDesktopNavBar: React.FC = () => {
  const navigate = useNavigate();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  return (
    <StyledNavBar id="header-desktop" theme={brandConfiguration}>
      <HeaderLogo />

      <HeaderLinksGroup>
        <HeaderFavoriteContainer>
          <StyledLink
            onClick={() =>
              navigate(generatePath(PATHS.LIKED_PRODUCTS, { caRecommendationId: caRecommendation?.recommendationId }))
            }
          >
            <StyledText theme={brandConfiguration}>
              <FormattedMessage id="gg.nav-bar.favorites" />
            </StyledText>

            <StyledMyFavorites src={WhiteHeart} alt="My Favorites" />
          </StyledLink>
        </HeaderFavoriteContainer>
      </HeaderLinksGroup>
    </StyledNavBar>
  );
};
