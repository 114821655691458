import { DesktopContainer, MobileContainer } from '@components/NavBar/style';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { GGDesktopNavBar } from '@components/NavBar/Desktop/GGDesktopNavBar';
import { SLPDesktopNavBar } from '@components/NavBar/Desktop/SLPDesktopNavBar';
import { BVDesktopNavBar } from '@components/NavBar/Desktop/BVDesktopNavBar';
import { GGMobileNavBar } from '@components/NavBar/Mobile/GGMobileNavBar';
import { SLPMobileNavBar } from '@components/NavBar/Mobile/SLPMobileNavBar';
import { BVMobileNavBar } from '@components/NavBar/Mobile/BVMobileNavBar';

interface Props {
  isProductDetailPage?: boolean;
}

export const DesktopNavBar: React.FunctionComponent<Props> = ({ isProductDetailPage }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  return (
    <DesktopContainer>
      {(() => {
        switch (brandConfiguration.constants.name) {
          case BRAND.GUCCI:
            return <GGDesktopNavBar />;
          case BRAND.SLP:
            return <SLPDesktopNavBar isProductDetailPage={isProductDetailPage} />;
          case BRAND.BV:
            return <BVDesktopNavBar />;
        }
      })()}
    </DesktopContainer>
  );
};

export const MobileNavBar: React.FunctionComponent<Props> = ({ isProductDetailPage }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  return (
    <MobileContainer>
      {(() => {
        switch (brandConfiguration.constants.name) {
          case BRAND.GUCCI:
            return <GGMobileNavBar />;
          case BRAND.SLP:
            return <SLPMobileNavBar isProductDetailPage={isProductDetailPage} />;
          case BRAND.BV:
            return <BVMobileNavBar />;
        }
      })()}
    </MobileContainer>
  );
};
