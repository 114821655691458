import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import SlpFavoritesContent from '@components/SidePanel/Content/Favorites/SlpFavoritesContent';

type Props = {
  caRecommendationId: string;
  setOpenContactModal: () => void;
};

export const FavoritesContent: React.FC<Props> = ({ caRecommendationId, setOpenContactModal }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);

  switch (brandConfiguration.constants.name) {
    case BRAND.SLP:
      return <SlpFavoritesContent caRecommendationId={caRecommendationId} setOpenContactModal={setOpenContactModal} />;
    default:
      return null;
  }
};
