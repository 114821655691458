import styled, { css } from 'styled-components';

import { onLargeScreen } from '@stylesheet';

export const Container = styled.div`
  position: absolute;
  font-family: ${(props) => props.theme.design.fonts.introduction};
  min-height: 100%;
  height: auto;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  ${onLargeScreen(css`
    overflow: overlay;
    overflow: -moz-scrollbars-vertical;
  `)}
`;
