import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { colorUsage, fontSize, fontWeight, getSpacing, lineHeight, onLargeScreen } from '@stylesheet';
import BaseButton from '@components/Button/Button';
import { ReactComponent as LeftChevronIcon } from '@assets/chevron_left.svg';

export const Container = styled.div`
  background-color: ${(props) => props.theme.design.colors.productCarousselBackground};
  font-family: ${(props) => props.theme.design.fonts.main};
  overflow-x: hidden;
  height: auto;

  ${onLargeScreen(css`
    display: none;
  `)}
`;

export const ProductInfoContainer = styled.div`
  padding: ${(props) => props.theme.design.pages.productDetails.mobile.description.padding};
  background-color: ${colorUsage.white};
`;

export const ProductHeader = styled.div<{ paddingTop: string }>`
  padding-top: ${(props) => props.paddingTop};
  background-color: ${(props) => props.theme.design.colors.productCarousselBackground};
  margin-left: 2.5%;
  margin-right: 2.5%;
  width: 95%;
  display: flex;
  justify-content: space-between;
`;

export const CarouselContainer = styled.div<{ marginTop: string; paddingBottom: string }>`
  width: 100%;
  height: auto;
  overflow: hidden;
  margin-top: ${(props) => props.marginTop};
  padding-bottom: ${(props) => props.paddingBottom};
`;

export const CarouselPaginationContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const CarouselPagination = styled.p`
  margin: auto;
`;

export const MobileCarouselImg = styled.img`
  width: 70%;
  height: auto;
  margin: auto;
`;

export const ProductHeaderItem = styled.div`
  text-decoration: underline;
  justify-content: left;
  margin-top: auto;
  margin-bottom: auto;
`;

export const ProductTitle = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.large};
  text-align: left;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const ProductName = styled.h1`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.large};
  text-align: center;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

const sharedCss = css`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.large};
`;

export const ProductDescription = styled.div`
  font-size: ${fontSize.medium};
  line-height: ${lineHeight.medium};
  margin: ${getSpacing(3)} 0 ${getSpacing(1)} 0;
  text-align: left;
  white-space: break-spaces;

  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}
`;

export const BulletPointsContainer = styled.div`
  margin-top: ${getSpacing(3)};
  display: flex;
`;

export const BulletPoints = styled.ul`
  width: 90%;
  margin: auto;
`;

export const Slide = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  &:focus {
    outline: none;
  }
`;

export const PriceContainer = styled.div`
  background-color: ${colorUsage.background};
  width: 100vw;
  height: auto;
`;

export const PriceContent = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(4)};
`;

export const ProductPrice = styled.h2`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.medium};
  text-align: center;
  margin-top: ${getSpacing(2)}
    ${theme('language', {
      ar: css`
        text-align: right;
        direction: rtl;
      `,
    })};
`;

export const Button = styled(BaseButton)`
  width: 100%;
  margin: auto;
  margin-top: ${getSpacing(2)};
  max-width: none;
  font-size: ${fontSize.XSmall};
`;

export const GoBackContainer = styled.div`
  display: flex;
  color: ${colorUsage.black};
  ${theme('language', {
    ar: css`
      text-align: right;
      direction: rtl;
    `,
  })}

  &:hover {
    cursor: pointer;
  }
`;

export const StyledLeftChevronIcon = styled(LeftChevronIcon)`
  margin-top: auto;
  margin-bottom: auto;
  fill: ${colorUsage.black};
`;

export const GoBackText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.design.fonts.main};
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  padding-left: 8px;
  padding-top: 1.5px;
`;

export const SlpTitle = styled.div`
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  text-transform: uppercase;
`;

export const SlpCategoryAndPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SlpText = styled.div`
  font-size: ${fontSize.XSmall};
  line-height: ${fontSize.medium};
  text-transform: uppercase;
`;

export const SlpLink = styled(SlpText)`
  display: flex;

  p {
    margin-top: auto;
    margin-bottom: auto;
  }

  &:hover {
    text-decoration: underline;
  }

  svg {
    scale: 0.5;
  }
`;

export const SlpButtons = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SlpAddToFavoritesButton = styled(BaseButton)`
  width: 100%;
  height: 36px;
  max-width: 100%;
  text-transform: uppercase;
  font-size: ${fontSize.XSmall};
  font-weight: ${fontWeight.normal};
`;

export const SlpVisitWebsiteButton = styled(BaseButton)`
  width: 100%;
  height: 36px;
  max-width: 100%;
  text-transform: uppercase;
  font-size: ${fontSize.XSmall};
  font-weight: ${fontWeight.normal};
  background-color: transparent;
  border: solid 1px ${colorUsage.black};
  color: ${colorUsage.black};
  -webkit-text-fill-color: rgba(0, 0, 0, 1);
`;
