import React, { useEffect } from 'react';
import { Store } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from '@components/ErrorBoundary';
import { Provider } from 'react-redux';
import ScrollToTop from '@components/ScrollToTop';
import Root from '@components/Root';
import AppCrashFallback from '@components/AppCrashFallback';
import ChildrenRoutes from '@routes';
import { BRAND } from '@redux/brandConfiguration/types';
import Functions from '@utils/Functions';

interface Props {
  store: Store;
}

const App: React.FunctionComponent<Props> = ({ store }) => {
  useEffect(() => {
    const brand = Functions.getBrandFromHostname(window.location.hostname);

    const favicon = document.querySelector("link[rel='shortcut icon']") as HTMLLinkElement;
    if (favicon) {
      const faviconUrl = (() => {
        switch (brand) {
          case BRAND.GUCCI:
            return `/favicon-gg.png?v=${new Date().getTime()}`;

          case BRAND.SLP:
            return `/favicon-slp.png?v=${new Date().getTime()}`;

          case BRAND.BV:
            return `/favicon-bv.png?v=${new Date().getTime()}`;
        }
      })();

      if (faviconUrl) {
        favicon.href = faviconUrl;
      }
    }

    document.title = (() => {
      switch (brand) {
        case BRAND.GUCCI:
          return 'Your Gucci Selection';

        case BRAND.SLP:
          return 'Your Saint Laurent Selection';

        case BRAND.BV:
          return 'Your Bottega Veneta Selection';

        default:
          return 'Your Curated Selection';
      }
    })();

    const manifest = document.querySelector("link[rel='manifest']") as HTMLLinkElement;
    if (manifest) {
      const manifestUrl = (() => {
        switch (brand) {
          case BRAND.GUCCI:
            return '/manifest.json';

          case BRAND.SLP:
            return '/manifest-slp.json';

          case BRAND.BV:
            return '/manifest-bv.json';
        }
      })();

      if (manifestUrl) {
        manifest.href = manifestUrl;
      }
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={AppCrashFallback}>
      <Provider store={store}>
        <BrowserRouter>
          <Root>
            <ScrollToTop />
            <ChildrenRoutes />
          </Root>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
