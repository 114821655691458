import React from 'react';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { BRAND } from '@redux/brandConfiguration/types';
import SlpContactContent from '@components/SidePanel/Content/Contact/SlpContactContent';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import BvContactContent from '@components/SidePanel/Content/Contact/BvContactContent';

export const ContactContent: React.FC = () => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const caRecommendation = useSelector(getCaRecommendation);

  if (!caRecommendation) {
    return null;
  }

  const ContactContent = (() => {
    switch (brandConfiguration.constants.name) {
      case BRAND.SLP:
        return SlpContactContent;
      case BRAND.BV:
        return BvContactContent;
      default:
        return null;
    }
  })();

  return ContactContent && <ContactContent advisor={caRecommendation.advisor} />;
};
