import React from 'react';
import {
  SidePanelHeaderContainer,
  SidePanelHeaderElement,
} from '@components/SidePanel/SidePanelHeader/SlpSideHeader.style';
import CloseButton from '@components/CloseButton';

interface Props {
  title?: string;
  onClose: () => void;
}

export const SlpSidePanelHeader: React.FC<Props> = ({ title, onClose }) => {
  return (
    <SidePanelHeaderContainer>
      <SidePanelHeaderElement />

      {title}

      <SidePanelHeaderElement>
        <CloseButton onClick={onClose} />
      </SidePanelHeaderElement>
    </SidePanelHeaderContainer>
  );
};
