export const links = {
  // GG
  gucciSite: 'https://www.gucci.com/',

  // SLP
  slpSite: 'https://www.ysl.com',
  slpClientService: 'https://www.ysl.com/cp/client-service',
  slpLegalNotice: 'https://www.ysl.com/legal/privacy-policy',
  slpAccessibility: 'https://www.ysl.com/legal/web-accessibility',
  slpNewsletter: 'https://www.ysl.com/#openDrawerNewsletter',
  slpIosApp: 'https://apps.apple.com/us/app/saint-laurent/id1623343549',
  slpAndroidApp: 'https://play.google.com/store/apps/details?id=com.kering.slp.saintlaurent',

  // BV
  bvSite: 'https://www.bottegaveneta.com',
  bvClientService: 'https://www.bottegaveneta.com/customer-care',
  bvBespokeService: 'https://www.bottegaveneta.com/our-services.html',
  bvLegal: 'https://www.bottegaveneta.com/legal-notices/cookie-policy.html',
};
