import { BRAND } from '@redux/brandConfiguration/types';
import { ContactContent } from '@components/SidePanel/Content/Contact/ContactContent';
import SidePanel from '@components/SidePanel';
import React from 'react';
import { useSidePanel } from '@components/panels/SidePanel/SidePanelContext';
import { useSelector } from 'react-redux';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { useIntl } from 'react-intl';

export const SidePanelContact = () => {
  const { isContactSidePanelOpen, setContactSidePanelOpen } = useSidePanel();
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const intl = useIntl();

  return (
    <SidePanel
      action={() => setContactSidePanelOpen(false)}
      isOpen={isContactSidePanelOpen}
      width={brandConfiguration.design.panel.businessCard.width}
      className="panel-contact"
      title={(() => {
        switch (brandConfiguration.constants.name) {
          case BRAND.BV:
            return intl.formatMessage({ id: 'bv.nav-bar.contact-advisor' });
          default:
            return intl.formatMessage({ id: 'slp.socials.contact-your-advisor' });
        }
      })()}
    >
      <ContactContent />
    </SidePanel>
  );
};
