import React from 'react';
import { PublicAdvisor } from '@redux/advisors/types';
import {
  AdvisorContact,
  AdvisorContactLink,
  AdvisorContactsContainer,
  AdvisorContactText,
  AdvisorContainer,
  AdvisorFunction,
  AdvisorImage,
  AdvisorImageContainer,
  AdvisorInformations,
  AdvisorName,
  ContactIcon,
  ModalContent,
  StyledDesktopLineIcon,
  StyledDesktopMailIcon,
  StyledDesktopNumberIcon,
  StyledDesktopSmsIcon,
  StyledDesktopWhatsAppIcon,
} from '@components/ContactModal/style';
import gucciLogo from '@assets/bubble_icon.png';

type Props = {
  advisor: PublicAdvisor;
};

const GucciContact: React.FC<Props> = ({ advisor }) => (
  <ModalContent>
    <AdvisorContainer>
      <AdvisorInformations>
        <AdvisorImageContainer>
          {/* TODO Replace image */}
          <AdvisorImage src={gucciLogo} alt="" />
        </AdvisorImageContainer>
        <AdvisorName>{advisor.displayName}</AdvisorName>
        <AdvisorFunction>{advisor.businessCardRole}</AdvisorFunction>
      </AdvisorInformations>
      <AdvisorContactsContainer>
        {advisor.workPhoneNumber && (
          <AdvisorContact>
            <ContactIcon>
              <StyledDesktopNumberIcon />
            </ContactIcon>
            <AdvisorContactText>
              <AdvisorContactLink href={`tel:${advisor.workPhoneNumber}`} rel="noopener noreferrer" target="_blank">
                {advisor?.workPhoneNumber}
              </AdvisorContactLink>
            </AdvisorContactText>
          </AdvisorContact>
        )}
        {advisor.sms && (
          <AdvisorContact>
            <ContactIcon>
              <StyledDesktopSmsIcon />
            </ContactIcon>
            <AdvisorContactText>
              <AdvisorContactLink href={`sms:${advisor.sms}`} rel="noopener noreferrer" target="_blank">
                {advisor.sms}
              </AdvisorContactLink>
            </AdvisorContactText>
          </AdvisorContact>
        )}
        {advisor.email && (
          <AdvisorContact>
            <ContactIcon>
              <StyledDesktopMailIcon />
            </ContactIcon>
            <AdvisorContactText>
              <AdvisorContactLink href={`mailto:${advisor.email}`} rel="noopener noreferrer" target="_blank">
                {advisor.email}
              </AdvisorContactLink>
            </AdvisorContactText>
          </AdvisorContact>
        )}
        {advisor.whatsApp && advisor.whatsAppNumber && (
          <AdvisorContact>
            <ContactIcon>
              <StyledDesktopWhatsAppIcon />
            </ContactIcon>
            <AdvisorContactText>
              <AdvisorContactLink href={advisor.whatsApp} rel="noopener noreferrer" target="_blank">
                {advisor.whatsAppNumber}
              </AdvisorContactLink>
            </AdvisorContactText>
          </AdvisorContact>
        )}
        {advisor.line && advisor.linePseudo && (
          <AdvisorContact>
            <ContactIcon>
              <StyledDesktopLineIcon />
            </ContactIcon>
            <AdvisorContactText>
              <AdvisorContactLink href={advisor.line} rel="noopener noreferrer" target="_blank">
                {advisor.linePseudo}
              </AdvisorContactLink>
            </AdvisorContactText>
          </AdvisorContact>
        )}
      </AdvisorContactsContainer>
    </AdvisorContainer>
  </ModalContent>
);

export default GucciContact;
