import React from 'react';
import CloseButton from '@components/CloseButton';
import {
  SidePanelHeaderContainer,
  SidePanelHeaderTitle,
} from '@components/SidePanel/SidePanelHeader/BvSideHeaderPanel.style';

interface Props {
  title?: string;
  onClose: () => void;
}

export const BvSidePanelHeader: React.FC<Props> = ({ title, onClose }) => {
  return (
    <SidePanelHeaderContainer>
      <SidePanelHeaderTitle>{title}</SidePanelHeaderTitle>

      <CloseButton size="22px" mobileSize="22px" onClick={onClose} />
    </SidePanelHeaderContainer>
  );
};
