import React, { useEffect, useState } from 'react';

import {
  CARecommendation,
  LookbookProduct,
  Product,
  Product as ProductType,
  RECOMMENDATION_TYPE,
} from '@redux/caRecommendation/types';

import { PATHS } from '@routes';
import useSelector from '@redux/useSelector';
import { getBrandConfiguration } from '@redux/brandConfiguration/selectors';
import { generatePath, useNavigate } from 'react-router-dom';
import ProductDisplay from '@pages/Suggestion/components/ProductList/components/ProductDisplay/ProductDisplay';
import Introduction from '@pages/Suggestion/components/ProductList/Introduction';
import {
  Background,
  BVProductsContainer,
  ProductsContainer,
  ProductsLookbookChapter,
  ProductsSLPContainer,
} from '@pages/Suggestion/components/ProductList/ProductList.style';
import { BRAND } from '@redux/brandConfiguration/types';
import Functions from '@utils/Functions';
import { ProductListContainer } from '@pages/Suggestion/components/ProductList/components';

interface Props {
  caRecommendation: CARecommendation;
}

const ProductList: React.FC<Props> = ({ caRecommendation }) => {
  const { brandConfiguration } = useSelector(getBrandConfiguration);
  const navigate = useNavigate();
  const [curatedListProducts, setCuratedListProducts] = useState<Product[] | undefined>();
  const [lookbookProducts, setLookbookProducts] = useState<LookbookProduct[] | undefined>();
  const [isDesktopVersion, setIsDesktopVersion] = useState<boolean>(Functions.isDesktopVersion());
  const { recommendationType } = caRecommendation;
  const { name: brand } = brandConfiguration.constants;
  const productListPattern = isDesktopVersion
    ? (brandConfiguration.design.pages.productsList.list?.desktop.pattern ?? [])
    : (brandConfiguration.design.pages.productsList.list?.mobile.pattern ?? []);
  const goToProductDetail = (product?: ProductType) => {
    if (product) {
      if (brandConfiguration.features.productDetail) {
        navigate(
          generatePath(PATHS.PRODUCT_DETAIL, {
            recommendationId: caRecommendation.id,
            productStyleColorCode: product.styleColorCode,
          }),
        );
      } else {
        window.location.href = brandConfiguration.constants.url;
      }
    }
  };

  useEffect(() => {
    if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.CURATED_LIST) {
      setCuratedListProducts(caRecommendation.products as Product[]);
    }
    if (caRecommendation.recommendationType === RECOMMENDATION_TYPE.LOOKBOOK) {
      setLookbookProducts(caRecommendation.products as LookbookProduct[]);
    }
  }, [caRecommendation]);

  useEffect(() => {
    const handleSetIsDesktopVersion = () => setIsDesktopVersion(Functions.isDesktopVersion());
    window.addEventListener('resize', handleSetIsDesktopVersion);
    return () => {
      window.removeEventListener('resize', handleSetIsDesktopVersion);
    };
  });

  return (
    <Background theme={brandConfiguration}>
      <ProductListContainer>
        {recommendationType === RECOMMENDATION_TYPE.CURATED_LIST && (
          <Introduction isDesktopVersion={isDesktopVersion} />
        )}

        {recommendationType === RECOMMENDATION_TYPE.LOOKBOOK ? (
          lookbookProducts?.map((lookbookProduct, indexRow) => (
            <>
              {!(
                (lookbookProduct?.chapterName === undefined || lookbookProduct?.chapterName === '') &&
                indexRow === 0
              ) && (
                <ProductsLookbookChapter theme={brandConfiguration}>
                  {lookbookProduct.chapterName}
                </ProductsLookbookChapter>
              )}
              <ProductsContainer theme={brandConfiguration}>
                {lookbookProduct.products.map((product, index) => (
                  <ProductDisplay
                    key={product.id}
                    caRecommendation={caRecommendation}
                    product={product}
                    index={index}
                    goToProductDetail={() => goToProductDetail(product)}
                    isLookbook
                  />
                ))}
              </ProductsContainer>
            </>
          ))
        ) : (
          <>
            {brand === BRAND.GUCCI && (
              <ProductsContainer theme={brandConfiguration}>
                {curatedListProducts &&
                  curatedListProducts.map((product, index) => (
                    <ProductDisplay
                      key={product.id}
                      caRecommendation={caRecommendation}
                      product={product}
                      index={index}
                      goToProductDetail={() => goToProductDetail(product)}
                      brand={BRAND.GUCCI}
                    />
                  ))}
              </ProductsContainer>
            )}

            {brand === BRAND.SLP && (
              <ProductsSLPContainer>
                {curatedListProducts &&
                  curatedListProducts.map((product, index) => (
                    <ProductDisplay
                      key={product.id}
                      caRecommendation={caRecommendation}
                      product={product}
                      index={index}
                      goToProductDetail={() => goToProductDetail(product)}
                      brand={BRAND.SLP}
                      size={Functions.getProductListPatternSize(productListPattern, index)}
                      isDesktopVersion={isDesktopVersion}
                      nbProducts={curatedListProducts.length}
                    />
                  ))}
              </ProductsSLPContainer>
            )}

            {brand === BRAND.BV && (
              <BVProductsContainer>
                {curatedListProducts &&
                  curatedListProducts.map((product, index) => (
                    <ProductDisplay
                      key={product.id}
                      caRecommendation={caRecommendation}
                      product={product}
                      index={index}
                      goToProductDetail={() => goToProductDetail(product)}
                      brand={BRAND.BV}
                    />
                  ))}
              </BVProductsContainer>
            )}
          </>
        )}
      </ProductListContainer>
    </Background>
  );
};

export default ProductList;
