import { PublicAdvisor } from '@redux/advisors/types';
import { BRAND, PRODUCT_LIST_SIZE, ProductPatternSizes } from '@redux/brandConfiguration/types';
import { productListSizes } from '@constants/productListSizes';
import { Product } from '@redux/caRecommendation/types';

export default class Functions {
  static toTitleCase(text?: string): string {
    if (!text) return '';
    const compoundWords = text.split('-');
    const uppercaseWord = compoundWords.map((compoundWord) => {
      if (!compoundWord) return '';
      return compoundWord[0].toUpperCase() + compoundWord.substring(1).toLowerCase();
    });
    return uppercaseWord.join('-');
  }

  static isDesktopVersion = () => window.innerWidth > 1023;

  static displayAdvisorName = (advisor?: PublicAdvisor) => advisor?.displayName || advisor?.firstName;

  // todo remove OR condition in next MEP
  static getGenderTranslation(brand: string, gender?: string) {
    switch (gender) {
      case 'MR':
        return `${brand}.gender-mr`;
      case 'MS':
      case 'MS.':
        return `${brand}.gender-ms`;
      case 'MRS':
      case 'MRS.':
        return `${brand}.gender-mrs`;
      case 'MISS':
      case 'MISS.':
        return `${brand}.gender-miss`;
      default:
        return null;
    }
  }

  static getProductListPatternSize(pattern: PRODUCT_LIST_SIZE[], index: number) {
    const number = (index + 1) % pattern.length;
    return pattern[number === 0 ? pattern.length : number - 1];
  }

  static getProductPatterSizes(pattern: PRODUCT_LIST_SIZE, index: number, size: number): ProductPatternSizes {
    switch (pattern) {
      case PRODUCT_LIST_SIZE.BIG_CENTER:
        return productListSizes.desktop.bigCenter;
      case PRODUCT_LIST_SIZE.BIG_LEFT:
        if (index + 1 === size) {
          return productListSizes.desktop.bigCenter;
        }
        return productListSizes.desktop.bigLeft;
      case PRODUCT_LIST_SIZE.BIG_RIGHT:
        return productListSizes.desktop.bigRight;
      case PRODUCT_LIST_SIZE.MEDIUM_LEFT:
        if (index + 1 === size) {
          return productListSizes.desktop.bigCenter;
        }
        return productListSizes.desktop.mediumLeft;
      case PRODUCT_LIST_SIZE.MEDIUM_RIGHT:
        return productListSizes.desktop.mediumRight;
      case PRODUCT_LIST_SIZE.SMALL:
      default:
        return productListSizes.desktop.small;
    }
  }

  static getProductPatterSizesMobile(pattern: PRODUCT_LIST_SIZE, index: number, size: number): ProductPatternSizes {
    switch (pattern) {
      case PRODUCT_LIST_SIZE.BIG_CENTER:
        return productListSizes.mobile.bigCenter;
      case PRODUCT_LIST_SIZE.BIG_LEFT:
        if (index + 1 === size) {
          return productListSizes.mobile.bigCenter;
        }
        return productListSizes.mobile.bigLeft;
      case PRODUCT_LIST_SIZE.BIG_RIGHT:
        if (index + 1 === size) {
          return productListSizes.mobile.bigCenter;
        }
        return productListSizes.mobile.bigRight;
      case PRODUCT_LIST_SIZE.MEDIUM_LEFT:
        return productListSizes.mobile.mediumLeft;
      case PRODUCT_LIST_SIZE.MEDIUM_RIGHT:
        return productListSizes.mobile.mediumRight;
      case PRODUCT_LIST_SIZE.SMALL:
      default:
        return productListSizes.mobile.small;
    }
  }

  static isLikedProduct(product: Product): boolean {
    return product.feedback ? product.feedback >= 4 : false;
  }

  static getBrandFromHostname = (hostname: string): BRAND | null => {
    switch (hostname) {
      case 'yourgucciselection.xchat-qa.dev4apps.com':
      case 'yourgucciselection.xchat-preprod.dev4apps.com':
      case 'www.yourgucciselection.com':
        return BRAND.GUCCI;

      case 'curatedselection.xchat-qa.dev4apps.com':
      case 'curatedselection.xchat-preprod.dev4apps.com':
      case 'curatedselection.ysl.com':
        return BRAND.SLP;

      case 'yourbottegavenetaselection.xchat-qa.dev4apps.com':
      case 'yourbottegavenetaselection.xchat-preprod.dev4apps.com':
      case 'yourcuratedselection.bottegaveneta.com':
        return BRAND.BV;

      default:
        return null;
    }
  };
}
