import React from 'react';
import styled, { css } from 'styled-components';
import { AdvisorName } from '@components/SocialContactGroup/components';
import { useSelector } from 'react-redux';
import { getCaRecommendation } from '@redux/caRecommendation/selectors';
import { FormattedMessage } from 'react-intl';
import theme from 'styled-theming';
import { fontSize, fontWeight } from '@stylesheet';

const BVSocialContactGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${theme('language', {
    ar: css`
      justify-content: flex-end;
      direction: rtl;
    `,
  })}
`;

const BVSocialContactGroupMedia = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 13px;
  font-weight: ${fontWeight.normal};
  line-height: ${fontSize.mediumLarge};
`;

const BVSocialContactGroupLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

export const BVSocialContactGroup: React.FC = () => {
  const { advisor } = useSelector(getCaRecommendation) || {};

  if (!advisor) {
    return null;
  }

  return (
    <BVSocialContactGroupContainer>
      <AdvisorName>{advisor.displayName}</AdvisorName>

      <BVSocialContactGroupMedia>
        <p>
          <FormattedMessage
            id={`bv.product-list.social`}
            values={{
              mail: (
                <BVSocialContactGroupLink
                  href={advisor.email ? `mailto:${advisor.email}` : undefined}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage id={`bv.product-list.social.mail`} />
                </BVSocialContactGroupLink>
              ),
              whatsapp: (
                <BVSocialContactGroupLink
                  href={advisor.whatsApp || undefined}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage id={`bv.product-list.social.whatsapp`} />
                </BVSocialContactGroupLink>
              ),
            }}
          />
        </p>
      </BVSocialContactGroupMedia>
    </BVSocialContactGroupContainer>
  );
};
